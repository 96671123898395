import { TextInput } from 'react-native';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import ActionBar from '@/components/ActionBar';
import colors from '@/theme/colors';

const CustomerCountBar = ({ customerCount, onChangeCustomerCount, title, autoFocus = false }) => {
  const { t } = useTranslation();

  return (
    <ActionBar
      style={{ marginBottom: 16, backgroundColor: colors.secondary.light }}
      title={title}
    >
      <TextInput
        style={{
          backgroundColor: colors.secondary.dark,
          borderRadius: 5,
          paddingVertical: 5,
          paddingHorizontal: 16,
          textAlign: 'center',
          fontSize: 20,
        }}
        placeholder={t('app.dialogs.customerCount.count')}
        keyboardType='numeric'
        value={customerCount}
        autoFocus={autoFocus}
        onChangeText={onChangeCustomerCount}
      />
    </ActionBar>
  );
};

export default CustomerCountBar;
