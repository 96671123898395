import { FlatList, StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { useState } from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import Modal from '@/components/EnhancedModal';
import Text from '@/components/Text';
import colors from '@/theme/colors';

export default function OptionModal () {
  const dispatch = useDispatch();
  const [appear, setAppear] = useState(true);
  const {
    visible,
    onChangeAction,
    options,
    closeOnChange = false,
  } = useSelector(state => state.setting.optionConfig);

  const onClose = async () => {
    setAppear(false);
    await setTimeout(() => { dispatch(actions.setting.closeSettingOptions()); }, 250);
    setAppear(true);
  };

  const onChangeValue = (input) => {
    // 單選的
    dispatch(onChangeAction(input));
    if (closeOnChange) {
      onClose();
    }
  };

  const renderItem = ({ item: option, index }) => (
    <TouchableOpacity
      onPress={() => onChangeValue(option.key)}
    >
      <View style={[
        styles.optionContainer,
        {
          borderBottomWidth: index !== options?.length - 1 ? 1 : 0,
          borderColor: colors.black + '20',
        },
      ]}
      >
        <Text size={24}>{option.text}</Text>
      </View>
    </TouchableOpacity>
  );

  return (
    <Modal
      isVisible={visible && appear}
      style={styles.centeredView}
      animationIn='fadeIn'
      animationOut='fadeOut'
      onBackdropPress={onClose}
    >
      <View style={[
        styles.contentContainer,
        {
          display: options ? 'flex' : 'none',
          maxHeight: options?.length <= 3
            ? 85 * options?.length + 30
            : '50%',
        },
      ]}
      >
        <FlatList
          showsVerticalScrollIndicator={false}
          data={options}
          renderItem={renderItem}
          keyExtractor={(item) => String(item.key)}
          style={{ minWidth: '80%' }}
        />
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    ...StyleSheet.absoluteFill,
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    padding: 15,
    borderRadius: 19,
    backgroundColor: colors.white,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },
  optionContainer: {
    minHeight: 85,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
});
