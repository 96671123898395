import { FlatList, StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import moment from 'moment';

import { actions, selectors, useDispatch, useSelector } from '@/redux';
import { useHistory } from '@/libs/reactRouter';
import LinearGradient from '@/components/RNLinearGradient';
import NavBar from '@/components/NavBar';
import NavTab from '@/components/NavTab';
import colors from '@/theme/colors';

import Item from './Item';

const tabEnum = {
  UNREAD: 0,
  READ: 1,
};

const Notifications = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(tabEnum.UNREAD);
  const { read, unread } = useSelector(selectors.notifications.selectCompletedDishesNotifications);

  const tabs = [
    { title: t('app.page.notifications.pending'), key: tabEnum.UNREAD.toString() },
    { title: t('app.page.notifications.served'), key: tabEnum.READ.toString() },
  ];

  return (
    <View style={{ flex: 1, backgroundColor: colors.secondary.light }}>
      <NavBar
        title={t('app.page.notifications.title')}
        backButton
        onBack={() => history.goBack()}
      />

      <LinearGradient
        colors={[colors.primary.main, colors.primary.light]}
        start={[0, 1]}
        end={[1, 0]}
        style={styles.navBar}
      >
        {tabs.map((tab, idx) => {
          return (
            <NavTab
              key={tab.key}
              title={tab.title}
              isActive={idx === activeTab}
              onPress={() => setActiveTab(idx)}
            />
          );
        })}
      </LinearGradient>

      <FlatList
        data={activeTab === tabEnum.UNREAD ? unread : read}
        style={{ marginTop: 12, paddingHorizontal: 12 }}
        contentContainerStyle={{ paddingBottom: 40 }}
        keyExtractor={(item => item.item.id)}
        renderItem={({ item }) => {
          return (
            <Item
              isRead={activeTab === tabEnum.READ}
              table={item.tableName}
              title={item.item.name}
              desc={item.item.status}
              quantity={item.item.quantity}
              time={moment(item.item.kitchenCompletedAt).format('HH:mm')}
              onPress={() => {
                dispatch(actions.notifications.readKitchenCompletedMessages(item.item));
              }}
            />
          );
        }}
        ItemSeparatorComponent={Separator}
      />
    </View>
  );
};

const Separator = () => {
  return <View style={{ marginBottom: 4 }} />;
};

const styles = StyleSheet.create({
  navBar: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-end',
    height: 43,
  },
});

export default Notifications;
