import { StyleSheet, View } from 'react-native';
import React from 'react';

import BatchCart from '@/components/BatchCart';

const ListWithBatchCart = ({ children, showBatchCartOnly = false }) => {
  return (
    <View style={styles.container}>
      {!showBatchCartOnly && <View style={styles.major}>{children}</View>}
      <View style={styles.minor}>
        <BatchCart expanded={showBatchCartOnly} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  major: {
    flex: 2,
  },
  minor: {
    marginVertical: 12,
    flex: 1,
  },
});

export default ListWithBatchCart;
