import { FlatList } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { listNumColumns } from '@/constants/app';
import { padToMatchMultiple } from '@/libs/array';
import { selectors, useSelector } from '@/redux';

import SetMenuListItem from './SetMenuListItem';

const SetMenuList = () => {
  const { t } = useTranslation();

  const listData = useSelector(state => {
    const nextStep = selectors.order.selectNextStep(state);
    const {
      subsetId: selectedSubsetId,
      subsetStepId: selectedSubsetStepId,
    } = selectors.order.selectOrderSelections(state);
    const subset = state.menu.sets.find(c => c.id === selectedSubsetId);
    const setMenus = subset.setMenus.filter((setMenu) => setMenu.step === selectedSubsetStepId);

    return nextStep
      ? [
          { name: t('app.page.orderSets.nextStep'), isNextStep: true, id: 'nextStep' },
          ...setMenus,
        ]
      : setMenus;
  });

  return (
    <FlatList
      testID='order-set-menus-list'
      showsVerticalScrollIndicator={false}
      data={padToMatchMultiple(listData, listNumColumns)}
      numColumns={listNumColumns}
      initialNumToRender={4}
      maxToRenderPerBatch={1}
      renderItem={({ item }) => {
        return <SetMenuListItem item={item} />;
      }}
    />
  );
};

export default React.memo(SetMenuList);
