import Constants from 'expo-constants';

import { storageKeys } from '@/constants/app';

import AsyncStorage from './storage';

const getEnv = async () => {
  const RN_APP_ENV = Constants.manifest?.extra?.RN_APP_ENV;

  // project convention for 'development' env is called 'test' at here
  const NODE_ENV = process.env.NODE_ENV === 'development' ? 'test' : process.env.NODE_ENV;
  const OVERWRITE_ENV = await AsyncStorage.getItem(storageKeys.OVERWRITE_ENV);
  const env = OVERWRITE_ENV || RN_APP_ENV || NODE_ENV || 'test';

  console.log({
    OVERWRITE_ENV,
    RN_APP_ENV,
    NODE_ENV,
    env,
  });

  return env;
};

export {
  getEnv,
};
