import React from 'react';
import SettingItem from '../SettingItem';
import SettingItemEnter from '../SettingItemEnter';
import SettingItemText from '../SettingItemText';

export default function EnterRow (props) {
  const {
    divider,
    selected,
    label,
    value,
    subValue,
    valueStyle,
    subValueStyle,
    onPress,
    iconSrc,
    iconStyle,
    touchableProps,
  } = props;

  return (
    <SettingItem
      divider={divider}
      selected={selected}
      onPress={onPress}
      touchableProps={touchableProps}
    >
      <SettingItemText text={label} />
      <SettingItemEnter
        text={value}
        subText={subValue}
        textStyle={valueStyle}
        subTextStyle={subValueStyle}
        iconSrc={iconSrc}
        iconStyle={iconStyle}
      />
    </SettingItem>
  );
}
