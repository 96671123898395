import { StyleSheet, View } from 'react-native';
import React from 'react';

import { selectors, useSelector } from '@/redux';
import NavBar from '@/components/NavBar';

import { useAllMenus } from '@/hooks';
import Loader from '@/components/Loader';

import TableList from './TableList';

const Tables = () => {
  const tableGroups = useSelector(selectors.merchant.selectTableGroups);
  const { selectedTableGroupIndex } = useSelector(state => state.merchant);
  const { isLoading } = useAllMenus();

  return (
    <View style={styles.container} testID='page-tables'>
      <NavBar settingButton tableGroupSelector orderHistoryButton />
      <View style={styles.contentContainer}>
        {isLoading
          ? <Loader />
          : <TableList tables={tableGroups[selectedTableGroupIndex]?.tables} />}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    padding: 10,
    flex: 1,
  },
});

export default Tables;
