import { FlatList } from 'react-native-gesture-handler';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { actions, useDispatch } from '@/redux';
import { useHistory } from '@/libs/reactRouter';
import { useTranslation } from 'react-i18next';
import React, { useRef, useState } from 'react';
import Text from '@/components/Text';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 12,
    marginVertical: 18,
    flexDirection: 'row',
    alignItems: 'center',
  },
  tagContainer: {
    width: '100%',
    backgroundColor: colors.customTag.main,
    borderColor: colors.customTag.border,
    borderWidth: 2.5,
    padding: 8,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 6,
  },
  tagDisabled: {
    width: 100,
    backgroundColor: colors.customTag.disabled.main,
    borderColor: colors.customTag.disabled.border,
    borderWidth: 2.5,
    padding: 8,
    borderRadius: 6,
  },
  text: {
    color: colors.customTag.border,
    fontWeight: 'bold',
    paddingRight: 10,
  },
  textDisabled: {
    color: colors.customTag.disabled.border,
    fontWeight: 'bold',
    paddingRight: 10,
  },
  icon: {
    height: 18,
    width: 18,
  },
  titleButton: {
    borderColor: colors.primary.dark,
    borderBottomWidth: 1.5,
    fontWeight: 'bold',
    color: colors.primary.dark,
    marginLeft: 20,
    justifyContent: 'flex-end',
  },
});

export default function Header (props) {
  const { tags } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const tagListRef = useRef();
  const [isDelete, setIsDelete] = useState(false);

  const renderItem = ({ item, index }) => {
    return (
      <TouchableOpacity
        onPress={() => {
          setIsDelete(true);
          dispatch(actions.order.deleteTag(item));
        }}
        style={{ marginRight: 10 }}
      >
        <View style={styles.tagContainer}>
          <Text size={16} style={styles.text}>{item.name}</Text>
          <Image style={styles.icon} source={require('@icons/order/cross.png')} />
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.container}>
      {tags.length > 0 && (
        <FlatList
          style={{ flex: 1 }}
          ref={tagListRef}
          data={tags}
          renderItem={renderItem}
          keyExtractor={(tag) => tag.id}
          showsHorizontalScrollIndicator={false}
          bounces={false}
          horizontal
          onContentSizeChange={() => {
            if (isDelete) {
              setIsDelete(false);
            } else {
              tagListRef.current.scrollToEnd();
            }
          }}
        />
      )}
      {tags.length === 0 && (
        <View style={{ flex: 1 }}>
          <View style={styles.tagDisabled}>
            <Text size={16} style={styles.textDisabled}>{t('app.page.customTag.custom')}</Text>
          </View>
        </View>
      )}
      <TouchableOpacity onPress={() => { history.push('/order/customtag'); }}>
        <Text style={styles.titleButton}>{t('app.common.more')}</Text>
      </TouchableOpacity>
    </View>
  );
}
