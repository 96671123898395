import { StyleSheet } from 'react-native';
import React from 'react';

import AllBatches from './Batches/All/AllBatches';
import Card from '../Card';
import Header from './Header';
import LatestBatch from './Batches/Latest/LatestBatch'; ;

const styles = StyleSheet.create({
  cardContainer: {
    flex: 1,
    width: '100%',
    flexDirection: 'column',
  },
});

export default function BatchCart({ expanded }) {
  return (
    <Card style={styles.cardContainer}>
      <Header />
      {expanded ? <AllBatches /> : <LatestBatch />}
    </Card>
  );
}
