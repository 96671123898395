import { StyleSheet, View } from 'react-native';
import React from 'react';

import colors from '@/theme/colors';
import shadows from '@/theme/shadows';

import { selectors, useSelector } from '@/redux';
import { variantKey } from '@/pages/OrderHistory/variantKey';
import Header from '@/pages/TableOrders/OrderList/OrderCard/Header';
import ItemList from './ItemList';
import SelectOrderButton from '@/pages/TableOrders/Buttons/SelectOrderButton';

const OrderCard = ({ order, variant }) => {
  const me = useSelector(selectors.auth.selectUserIdentifier);
  const myOrderBatches = order.batches.filter(batch => batch.identifier === me);

  if ((myOrderBatches.length === 0 && variant === variantKey.MYORDER) || order.batches.length === 0) {
    return null;
  }

  return (
    <View
      style={{
        marginBottom: 20,
        borderRadius: 8,
        backgroundColor: colors.white,
        paddingTop: 20,
        paddingBottom: 20,
        ...shadows.slight,
      }}
      testID='order-card'
    >
      <Header order={order} />
      <ItemList order={order} variant={variant} />
      <SelectOrderButton orderId={order.id} />
    </View>
  );
};

const styles = StyleSheet.create({});

export default OrderCard;
