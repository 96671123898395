import { View } from 'react-native';
import React from 'react';

import { useSelector } from '@/redux';
import colors from '@/theme/colors';

import Text from './Text';

const packageJson = require('@root/package.json');

const VersionEnvInfo = () => {
  const env = useSelector(state => state.app.env);

  return (
    <View style={{ position: 'absolute', bottom: 12, right: 12, alignItems: 'center' }}>
      <Text size={14} style={{ color: colors.white }}>{env}</Text>
      <Text size={14} style={{ color: colors.white }}>v{packageJson.version}</Text>
    </View>
  );
};

export default VersionEnvInfo;
