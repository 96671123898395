import produce from 'immer';

import ActionTypes from './ActionTypes';

/** @type {ICodePushState} */
export const initialState = {
  deployment: null,
  overwriteDeployment: null,
  packageMeta: null,
  isDialogOpen: false,
  status: null,
  progress: null,
  error: null,
  checkUpdateAt: null,
};

export default produce(
  /**
   * @param {ICodePushState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.UPDATE_DEPLOYMENT: {
        const { deployment } = action.payload;
        draft.deployment = deployment;
        break;
      }
      case ActionTypes.UPDATE_OVERWROTE_DEPLOYMENT: {
        const { deployment } = action.payload;
        draft.overwriteDeployment = deployment;
        break;
      }
      case ActionTypes.UPDATE_PACKAGE_META: {
        const { codePushPackageMeta } = action.payload;
        draft.packageMeta = codePushPackageMeta;
        break;
      }
      case ActionTypes.UPDATE_DIALOG_OPEN: {
        const { open } = action.payload;
        draft.isDialogOpen = open;
        draft.error = null;
        if (!open) {
          // 關閉時重置狀態和進度
          draft.status = null;
          draft.progress = null;
        }
        break;
      }
      case ActionTypes.UPDATE_STATUS: {
        const { status } = action.payload;
        draft.status = status;
        break;
      }
      case ActionTypes.UPDATE_PROGRESS: {
        const { progress } = action.payload;
        draft.progress = progress;
        break;
      }
      case ActionTypes.UPDATE_ERROR: {
        const { error } = action.payload;
        draft.error = error;
        break;
      }
      case ActionTypes.UPDATE_CHECK_UPDATE_AT: {
        const { checkUpdateAt } = action.payload;
        draft.checkUpdateAt = checkUpdateAt;
        break;
      }
      default: {
        return draft;
      }
    }
  },
  initialState,
);
