import _ from 'lodash';
import produce from 'immer';

import ActionTypes from './ActionTypes';
import ActionTypesAuth from '../auth/ActionTypes';

/** @type {IAuthState} */
export const initialState = {
  currentUser: null,
  scopeCredentials: [],
  merchantUserName: '',
  merchantId: '',
};

export default produce(
  /**
   * @param {IAuthState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypesAuth.USER_LOGOUT:
        return initialState;

      case ActionTypes.SCOPE_CREDENTIALS_UPDATED: {
        draft.scopeCredentials = action.payload;

        if (draft.currentUser) {
          const scopeCredential = draft.scopeCredentials.find(s => s.identifier === draft.currentUser.account);

          if (scopeCredential) {
            draft.currentUser.permissions = scopeCredential.scopes;
          }
        }

        break;
      }

      case ActionTypes.USER_LOGIN:
        draft.currentUser = _.pick(action.payload, [
          'createdAt',
          'version',
          'role',
          'disabled',
          'token',
          'refresh',
          'permissions',
          'scoped',
          'account',
        ]);
        draft.scopeCredentials = action.payload.scopeCredentials;
        draft.merchantId = action.payload.id;
        draft.merchantUserName = action.payload.merchantUserName;
        break;

      default: {
        return draft;
      }
    }
  },
  initialState,
);
