import { FlatList, StyleSheet } from 'react-native';
import React, { useEffect, useRef } from 'react';

import { useSelector } from '@/redux';
import Item from './Items/Item';
import Set from './Items/Set';

const Batch = ({ batchItems, editable, isItemInCurrentBatch, autoScroll, testID }) => {
  const cartInDeleteMode = useSelector(state => state.order.cartInDeleteMode);
  const isEditing = useSelector(state => Boolean(state.order.stash));
  const listRef = useRef();

  useEffect(() => {
    if (autoScroll) {
      listRef.current.scrollToOffset({ offset: 0, animated: false });
    }
  }, [batchItems.length, autoScroll]);

  console.log('-- render: Batch');

  return (
    <FlatList
      testID={testID}
      ref={listRef}
      data={batchItems}
      style={{ flexGrow: 0 }}
      inverted
      bounces={false}
      showsVerticalScrollIndicator={false}
      renderItem={({ item }) => {
        if (item.isSet) {
          return (
            <Set
              key={item.id}
              set={item}
              editable={editable && !cartInDeleteMode && !isEditing}
              isItemInCurrentBatch={isItemInCurrentBatch}
            />
          );
        }

        return (
          <Item
            key={item.id}
            item={item}
            editable={editable && !cartInDeleteMode && !isEditing}
            isItemInCurrentBatch={isItemInCurrentBatch}
          />
        );
      }}
    />
  );
};

const styles = StyleSheet.create({});

export default Batch;
