import { StyleSheet, TextInput, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import Button from '@/components/Button';
import CenterModal from '@/components/Modals/CenterModal';
import colors from '@/theme/colors';
import logger from '@/libs/logger';

import { dialogNames } from './constants';

const SystemAdminDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.app.dialogs.systemAdmin.open);
  const data = useSelector(state => state.app.dialogs.systemAdmin.data);

  const [text, setText] = useState('');

  const onChangeText = (value) => {
    setText(value);
  };

  const handleClose = () => {
    dispatch(actions.app.toggleDialog(dialogNames.systemAdmin, false));
    setText('');
  };

  const handleClear = () => {
    setText('');
  };

  return (
    <CenterModal
      visible={isOpen}
      title={t('app.dialogs.systemAdmin.title')}
      contentContainerStyle={styles.container}
      onClose={handleClose}
    >
      <TextInput
        style={styles.input}
        onChangeText={onChangeText}
        value={text}
        placeholder={t('app.dialogs.systemAdmin.enterPassword')}
        autoFocus
        autoCorrect={false}
        autoCompleteType='off'
        secureTextEntry
      />

      <View style={styles.buttonContainer}>
        {/* <Button
          title={t('app.dialogs.systemAdmin.clear')}
          touchableProps={{ style: styles.buttonLeft }}
          onPress={handleClear}
        /> */}
        <Button
          title={t('app.dialogs.systemAdmin.submit')}
          disabled={!text}
          touchableProps={{ style: styles.buttonRight }}
          onPress={() => {
            handleClose();

            if (text !== 'd1m0rd3r') {
              alert('wrong password');
              return;
            }

            if (!data.nextDialog) {
              logger.log('[dialog:systemAdmin] invalid nextDialog data');
              return;
            }

            dispatch(actions.app.toggleDialog(data.nextDialog, true));
          }}
        />
      </View>
    </CenterModal>
  );
};

const styles = StyleSheet.create({
  container: {
    minWidth: '80%',
    maxWidth: 450,
  },
  buttonContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  buttonLeft: {
    flex: 1,
    backgroundColor: colors.button.secondary,
    marginRight: 8,
    borderRadius: 6,
  },
  buttonRight: {
    flex: 1,
    backgroundColor: colors.button.main,
    marginLeft: 8,
    borderRadius: 6,
  },
  input: {
    borderWidth: 1,
    padding: 12,
    margin: 20,
    width: '100%',
  },
});

export default SystemAdminDialog;
