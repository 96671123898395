import { Platform } from 'react-native';

import { ENV } from '@/configs/env';
import { getEnv } from '@/libs/env';
import { storageKeys } from '@/constants/app';
import AsyncStorage from '@/libs/storage';
import codePush from '@/libs/codePush';

export const restart = () => {
  if (Platform.OS === 'web') {
    window.location.href = '/';
  } else {
    codePush.restartApp();
  }
};

export const overwriteEnv = async (overwriteEnv) => {
  const env = await getEnv();

  if (overwriteEnv === env) {
    console.log('[overwriteEnv] ignored due to same environments');
    return;
  }

  const environments = ENV.map(e => e.value);

  if (!environments.includes(overwriteEnv)) {
    console.log(`[overwriteEnv] ignored due to invalid environment provided, must be one of ${environments.join(', ')}`);
    return;
  }

  if (Platform.OS === 'web') {
    localStorage.clear();
  } else {
    await AsyncStorage.clear();
  }

  await AsyncStorage.setItem(storageKeys.OVERWRITE_ENV, overwriteEnv);
  restart();
};
