import { useEffect, useRef, useState } from 'react';
import Constants from 'expo-constants';
import _ from 'lodash';

import { actions, useDispatch, useSelector } from '@/redux';
import { dimorderLib } from '@/libs/api/dimorder';
import configs from '@/configs';
import logger from '@/libs/logger';

const WsEvent = {
  ORDER_CONFIRMED: 'domain.OrderConfirmedEvent',
  ORDER_SUBMITTED: 'domain.OrderSubmittedEvent',
  ORDER_PAID: 'domain.OrderPaidEvent',
  ORDER_CANCELLED: 'domain.OrderCancelledEvent',
  ORDER_CREATED: 'domain.OrderCreatedEvent', // 開檯入座
  ORDER_SURCHARGE_UPDATED: 'domain.OrderSurchargeUpdateEvent',
  ORDER_ITEM_TAG_UPDATED: 'domain.OrderItemTagUpdateEvent',
  ORDER_ITEM_EXCLUDED_ORDER_SURCHARGE_UPDATED: 'domain.OrderItemExcludedOrderSurchargeUpdateEvent',
  ORDER_ITEM_MODIFIER_UPDATED: 'domain.OrderItemModifierUpdateEvent',
  ORDER_MODIFIER_UPDATED: 'domain.OrderUpdateModifiersEvent',
  ORDER_BATCH_CANCELLED: 'domain.OrderBatchCancelledEvent',
  ORDER_DELETE_PAYMENT: 'domain.OrderDeletePaymentEvent',
  ORDER_ITEM_CANCELLED: 'domain.OrderItemCancelledEvent',
  ORDER_PAYMENT_PAID: 'domain.OrderPaymentPaidEvent',
  ORDER_SERVICE_REQUEST: 'domain.OrderServiceRequestedEvent',
  ORDER_PUT_EVENT: 'domain.OrderPutEvent',
  ORDER_TABLE_TRANSFER: 'domain.OrderTableTransferredEvent',
  ORDER_MERGED: 'domain.OrderMergedEvent',
  ORDER_REFERRED: 'domain.OrderReferredEvent',
};

const WsState = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

const heartbeatInterval = 10000;

export default function useWebSocket() {
  const dispatch = useDispatch();
  const ws = useRef(null);
  const userToken = useSelector(state => state.auth.currentUser?.token);
  const [reconnectCounter, setReconnectCounter] = useState(0);
  const env = useSelector(state => state.app.env);

  const reconnect = useRef(_.throttle(() => {
    if ([WsState.CLOSING, WsState.CLOSED].includes(ws.current.readyState)) {
      logger.log('[WebSocket] reconnect');
      setReconnectCounter(prev => prev + 1);
    }
  }, 5000)).current;

  const wssUrl = configs[env]?.wssUrl;
  logger.log('[WebSocket] env', { env });

  useEffect(() => {
    logger.log('[WebSocket] effect', { userToken, wssUrl });

    if (!userToken || !wssUrl) {
      return;
    }

    const deviceId = Constants.installationId;
    const webSocketUrl = `${wssUrl}/m/subscribe/orderchanged?device_id=${deviceId}&token=${userToken}`;
    let intervalId = 0;

    ws.current = new WebSocket(webSocketUrl);

    ws.current.onopen = () => {
      logger.log('[WebSocket] Reconnect Counter', { reconnectCounter });
      logger.log(`[WebSocket] Connected to server ${webSocketUrl}`);

      dispatch(actions.orderHistory.init());

      intervalId = setInterval(() => {
        ws.current.send(JSON.stringify({ action: 'ping' }));
        logger.log('[WebSocket] ping', { wsState: ws.current.readyState });
      }, heartbeatInterval);
    };

    ws.current.onmessage = (event) => {
      try {
        const msg = JSON.parse(event.data);

        // format order
        const order = dimorderLib.apiOrderToAppOrder(msg.orderHistory);

        logger.log('[WebSocket] Received order', { order, event: msg.event });

        switch (msg.event) {
          case WsEvent.ORDER_CREATED:
          case WsEvent.ORDER_CANCELLED:
          case WsEvent.ORDER_CONFIRMED:
          case WsEvent.ORDER_SUBMITTED:
          case WsEvent.ORDER_PAID:
          case WsEvent.ORDER_SURCHARGE_UPDATED:
          case WsEvent.ORDER_ITEM_TAG_UPDATED:
          case WsEvent.ORDER_ITEM_EXCLUDED_ORDER_SURCHARGE_UPDATED:
          case WsEvent.ORDER_ITEM_MODIFIER_UPDATED:
          case WsEvent.ORDER_MODIFIER_UPDATED:
          case WsEvent.ORDER_BATCH_CANCELLED:
          case WsEvent.ORDER_DELETE_PAYMENT:
          case WsEvent.ORDER_ITEM_CANCELLED:
          case WsEvent.ORDER_PAYMENT_PAID:
          case WsEvent.ORDER_SERVICE_REQUEST:
          case WsEvent.ORDER_PUT_EVENT:
          case WsEvent.ORDER_TABLE_TRANSFER:
          case WsEvent.ORDER_MERGED:
          case WsEvent.ORDER_REFERRED: {
            dispatch(actions.orderHistory.updateOrder(order));
            dispatch(actions.order.updateWorkingOrder(order, false));
            dispatch(actions.notifications.orderReceived(order));
            break;
          }

          default:
            logger.log(`[WebSocket] unknown event type: ${msg.event}`, { event });
            break;
        }

        // reply msg to server
        ws.current.send(JSON.stringify({ id: msg.id }));
      } catch (err) {
        logger.log('[WebSocket] on message error', { err, event });
      }
    };

    ws.current.onclose = (event) => {
      logger.log('[WebSocket] Disconnected', {
        event,
        targetURL: event.target.url,
        isTrusted: event.isTrusted,
        bubbles: event.bubbles,
        cancelBubble: event.cancelBubble,
        cancelable: event.cancelable,
        code: event.code,
        composed: event.composed,
        defaultPrevented: event.defaultPrevented,
        eventPhase: event.eventPhase,
        reason: event.reason,
        returnValue: event.returnValue,
        timeStamp: event.timeStamp,
        type: event.type,
        wasClean: event.wasClean,
      });
      clearInterval(intervalId);
      reconnect();
    };

    ws.current.onerror = (error) => {
      logger.log('[WebSocket] Error', {
        error,
        targetURL: error.target.url,
        isTrusted: error.isTrusted,
        bubbles: error.bubbles,
        cancelBubble: error.cancelBubble,
        cancelable: error.cancelable,
        composed: error.composed,
        defaultPrevented: error.defaultPrevented,
        eventPhase: error.eventPhase,
        returnValue: error.returnValue,
        timeStamp: error.timeStamp,
        type: error.type,
      });
      clearInterval(intervalId);
      reconnect();
    };

    return () => {
      clearInterval(intervalId);
      ws.current.close();
    };
  }, [dispatch, userToken, reconnectCounter, wssUrl, reconnect]);
}
