/* global __DEV__ */
import { AppState, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';

import codePush from '@/libs/codePush';

import { storageKeys } from '@/constants/app';
import codePushConfig from '@/configs/codePush';
import i18n from '@/i18n';
import logger, {
  logId,
  setCodePushDeployment,
  setCodePushLabel,
  setCodePushOverwriteDeployment,
} from '@/libs/logger';

import ActionTypes from './ActionTypes';

const OVERWRITE_DEPLOYMENT_KEY = 'OVERWRITE_DEPLOYMENT';

let checkUpdateTime; // 下次檢查更新時間
let appStateChangeListener;
let updateTimeout;

/**
 * @returns {ThunkFunction}
 */
export function initUpdateTrigger () {
  return async (dispatch, getState) => {
    if (Platform.OS === 'web') return;

    // 先清除之前的 listener 和 updateTimeout
    await dispatch(removeUpdateTrigger());

    // 檢查時間為下次 4AM
    const today4AM = moment().set('hour', 4).startOf('hours');
    if (moment().isAfter(today4AM)) {
      // 今天的 4AM 已經過了，加一天
      checkUpdateTime = today4AM.add(1, 'day');
    } else {
      checkUpdateTime = today4AM;
    }

    const checkUpdateTimeDuration = moment.duration(checkUpdateTime - moment());
    updateTimeout = setTimeout(async () => {
      logger.log('[CodePush] 每日檢查更新');
      // 時間到，檢查更新
      await dispatch(checkUpdate(true));
      // 重設 timeout
      dispatch(initUpdateTrigger());
    }, checkUpdateTimeDuration.asMilliseconds());

    // 在 background 時， timeout 不會被執行，resume 時再判斷是否過了檢查時間
    appStateChangeListener = async (state) => {
      if (state === 'active') {
        // 先停止原本的 timeout
        clearTimeout(updateTimeout);
        const checkUpdateAt = getState().codePush.checkUpdateAt;

        // 現在時間已超過檢查更新時間，且檢查更新時間已超過上次檢查更新
        if (moment().isAfter(checkUpdateTime) && moment(checkUpdateTime).isAfter(checkUpdateAt)) {
          logger.log('[CodePush] APP Resume 今天還沒檢查，檢查更新');
          await dispatch(checkUpdate(true));
        }
        // 重設 timeout
        dispatch(initUpdateTrigger());
      }
    };
    AppState.addEventListener('change', appStateChangeListener);
  };
}

/**
 * @returns {ThunkFunction}
 */
export function removeUpdateTrigger () {
  return () => {
    if (Platform.OS === 'web') return;

    if (appStateChangeListener) {
      AppState.removeEventListener('change', appStateChangeListener);
    }
    clearTimeout(updateTimeout);
  };
}

/**
 * 更改 deployment 並檢查更新
 * @param {TDeployment} deployment
 * @returns {ThunkFunction}
 */
export function overwriteDeployment (deployment, forceCheck = false) {
  return async (dispatch, getState) => {
    await AsyncStorage.removeItem(storageKeys.USER_LOGIN);

    if (deployment) {
      await AsyncStorage.setItem(OVERWRITE_DEPLOYMENT_KEY, deployment);
    } else {
      await AsyncStorage.removeItem(OVERWRITE_DEPLOYMENT_KEY);
    }
    dispatch(updateOverwirteDeployment(deployment));

    // deployment 改變或改為預設，檢查更新
    if (!deployment || forceCheck) {
      dispatch(checkUpdate(true));
    }
    setCodePushOverwriteDeployment(deployment);
  };
}

export function updateOverwirteDeployment (deployment) {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_OVERWROTE_DEPLOYMENT,
      payload: { deployment },
    });
  };
}

export function updateDialogOpen (open) {
  return {
    type: ActionTypes.UPDATE_DIALOG_OPEN,
    payload: { open },
  };
}

/**
 * @returns {ThunkFunction}
 */
export function checkUpdate () {
  return async (dispatch, getState) => {
    if (Platform.OS === 'web') {
      // web 不做 codePush 更新
      return;
    }
    logger.log('[CodePush] checkUpdate');

    try {
      const overwriteDeployment = await AsyncStorage.getItem(OVERWRITE_DEPLOYMENT_KEY);
      if (overwriteDeployment) {
        // 還原 overwriteDeployment
        dispatch(updateOverwirteDeployment(overwriteDeployment));
      }

      // 取得目前執行的版本
      const codePushPackageMeta = await codePush.getUpdateMetadata();
      logger.log('[CodePush] codePushPackageMeta', { codePushPackageMeta });
      if (codePushPackageMeta) {
        const deployment = codePushConfig.deploymentKeyMap[codePushPackageMeta.deploymentKey];
        setCodePushDeployment(deployment);
        setCodePushLabel(codePushPackageMeta.label);

        dispatch({
          type: ActionTypes.UPDATE_PACKAGE_META,
          payload: {
            codePushPackageMeta: {
              ...codePushPackageMeta,
              deployment: deployment,
            },
          },
        });
      }

      // 根據環境選擇預設的 deployment
      let deployment = getState().codePush.overwriteDeployment ?? codePushConfig.Deployments.Production; // 預設 stable

      // 如果有設定 overwriteDeployment，改用 overwriteDeployment
      if (overwriteDeployment) {
        deployment = overwriteDeployment;
        logger.log('[CodePush] overwrite deployment ' + deployment, { deployment });
      }

      logger.log('[CodePush] final deployment ' + deployment, { deployment });

      // 將最後選擇的 deployment 存起來
      dispatch({
        type: ActionTypes.UPDATE_DEPLOYMENT,
        payload: { codePushDeployment: deployment },
      });

      // 找出 deployment key
      const deploymentKey = codePushConfig.deploymentKeys[deployment];
      logger.log('[CodePush] deployment key ' + deploymentKey, { deployment, deploymentKey });

      // dev mode 時預設不更新，但如果有去改 overwriteDeployment 就會更新
      if (__DEV__ && !overwriteDeployment) {
        console.log('[CodePush] skip in dev mode');
        return;
      }

      logger.log('[CodePush] sync start');
      await codePush.sync({
        deploymentKey,
        installMode: codePush.InstallMode.IMMEDIATE,
        rollbackRetryOptions: {
          delayInHours: 24, // 更新失敗後多久 retry，預設 24
          maxRetryAttempts: 1, // 可以 retry 幾次，預設 1
        },
      }, (status) => {
        dispatch({
          type: ActionTypes.UPDATE_STATUS,
          payload: { status },
        });

        const statusI18nKey = codePushConfig.statusDisplayTextI18nKeys[status];
        logger.log(`[CodePush] status change: [${status}] ${i18n.t(statusI18nKey)}`, { status, message: i18n.t(statusI18nKey) });
      }, (progress) => {
        dispatch({
          type: ActionTypes.UPDATE_PROGRESS,
          payload: { progress },
        });
        console.log('[CodePush] download progress: ' + progress);
      }, (remotePackage) => {
        // Native 版本需更新
        // TODO: 這邊可以幫他開啟 app store 讓他去下載新版
      });
      dispatch({
        type: ActionTypes.UPDATE_CHECK_UPDATE_AT,
        payload: {
          checkUpdateAt: new Date(),
        },
      });
      logger.log('[CodePush] sync done');
    } catch (error) {
      logger.error('[CodePush] error' + error?.message || error, { error });
      dispatch({
        type: ActionTypes.UPDATE_ERROR,
        payload: { error: `更新時發生錯誤 (${logId})` },
      });
    }
  };
}
