import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { View } from 'react-native';
import React from 'react';

import { ReactRouter } from '@/libs/reactRouter';
import { history } from '@/libs/history';
import { persistor, store } from '@/redux/store';
import ConfigGate from '@/components/ConfigGate';
import ErrorBoundary from '@/components/ErrorBoundary';
import Main from '@/pages/Main';

export default function App() {
  return (
    <View
      style={{
        flex: 1,
        width: '100%',
        height: '100vh',
        maxHeight: '100vh',
        overflow: 'hidden',
        display: 'flex',
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ReactRouter history={history}>
            <SafeAreaProvider>
              <ErrorBoundary>
                <ConfigGate>
                  <Main />
                </ConfigGate>
              </ErrorBoundary>
            </SafeAreaProvider>
          </ReactRouter>
        </PersistGate>
      </Provider>
    </View>
  );
}
