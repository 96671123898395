import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { actions, selectors, useDispatch, useSelector } from '@/redux';
import { useHistory } from '@/libs/reactRouter';
import Text from '@/components/Text';
import colors from '@/theme/colors';

import EnterRow from './component/SettingRow/EnterRow';

const styles = StyleSheet.create({
  titleContainer: {
    flex: 1,
    minHeight: 30,
    justifyContent: 'center',
    paddingLeft: 12,
  },
  title: {
    fontWeight: 'bold',
    color: colors.black + '60',
  },
});

export default function UserInfo(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const merchantName = useSelector(state => state.merchant.data.name);
  const userAccount = useSelector(selectors.auth.selectUserIdentifier);

  return (
    <>
      <View style={styles.titleContainer}>
        <Text size={14} style={styles.title}>{t('app.page.setting.userInfo.title')}</Text>
      </View>

      <EnterRow
        divider
        label={t('app.page.setting.userInfo.merchant')}
        value={merchantName}
        onPress={() => {}}
      />

      <EnterRow
        divider
        label={t('app.page.setting.userInfo.staff')}
        value={userAccount}
        onPress={() => {}}
      />

      <View style={styles.titleContainer}>
        <Text size={14} style={styles.title}>{t('app.page.setting.userInfo.system')}</Text>
      </View>

      <EnterRow
        divider
        label={t('app.page.setting.userInfo.logout')}
        iconSrc={require('@icons/setting/logout.png')}
        iconStyle={{ opacity: 1 }}
        onPress={() => {
          dispatch(actions.auth.userLogout());
          history.replace('/login');
        }}
      />
    </>
  );
}
