import { Image, StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { actions, selectors, useDispatch, useSelector } from '@/redux';
import { useHistory } from '@/libs/reactRouter';
import colors from '@/theme/colors';

import Button from '../Button';
import Text from '../Text';

const styles = StyleSheet.create({
  contentContainer: {
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: colors.text.primary,
  },
  errorTitle: {
    color: 'red',
  },
  tagContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.tag.primary,
    borderRadius: 5,
    paddingHorizontal: 14,
    marginHorizontal: 8,
    height: 22,
  },
  tagText: {
    color: colors.white,
  },
  icon: {
    height: 22,
    width: 22,
  },
  titleContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonContainer: {
    alignItems: 'flex-end',
  },
});

export default function Header() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const expanded = useSelector(state => state.order.cartExpanded);
  const items = useSelector(state => state.order.workingBatch.items);
  const batches = useSelector(state => state.order.workingOrder.batches);
  const numInvalidItems = useSelector(selectors.order.selectNumberOfInvalidItems);

  const getTotalQuantity = () => {
    let totalQuantity = 0;

    if (expanded) {
      batches.forEach((batch) => {
        batch.items.forEach((item) => {
          totalQuantity += item.quantity;
        });
      });
    }

    items?.forEach((item) => {
      totalQuantity += item.quantity;
    });

    return totalQuantity;
  };

  const iconSrc = expanded
    ? require('@icons/order/shrink.png')
    : require('@icons/order/expand.png');

  return (
    <View style={styles.contentContainer}>
      <View style={styles.titleContainer}>
        <Text size={18} style={styles.title}>{`${t('app.page.order.cart')}（ ${getTotalQuantity()} ）`}</Text>
        {numInvalidItems
          ? (
            <>
              <Text size={18} style={styles.errorTitle}>{t('app.page.order.incomplete')}</Text>
              <View style={styles.tagContainer}>
                <Text size={14} style={styles.tagText}>{numInvalidItems}</Text>
              </View>
            </>
            )
          : <></>}
      </View>
      <View style={styles.buttonContainer}>
        <Button
          style={styles.expandButton}
          onPress={() => {
            dispatch(actions.order.setCartExpanded(!expanded));
            if (expanded) {
              history.goBack();
            } else {
              history.push('/order/ordercart');
            }
          }}
        >
          <Image style={styles.icon} source={iconSrc} />
        </Button>
      </View>
    </View>
  );
}
