import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { numberWithCommas } from '@/libs/numberWithCommas';
import Text from '@/components/Text';
import colors from '@/theme/colors';

const Price = ({ item }) => {
  const { t } = useTranslation();

  const cancelledStyle = { color: colors.grey['500'] };
  const textStyle = item.cancelled ? [styles.text, cancelledStyle] : styles.text;

  return (
    <View style={{ flex: 2, alignItems: 'flex-end', justifyContent: 'center' }}>
      <Text style={textStyle} size={16}>
        {item.priceUndetermined
          ? t('app.constant.menu.priceUndetermined')
          : `$${numberWithCommas(item.total)}`}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    fontWeight: 'bold',
  },
});

export default Price;
