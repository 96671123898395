export default {
  spacing: {
    app: {
      horizontal: 12,
      vertical: 8,
    },
  },
  border: {
    radius: {
      normal: 8,
    },
  },
  box: {
    height: {
      long: 169,
      normal: 110,
    },
  },
};
