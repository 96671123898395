import React from 'react';

import { actions, selectors, useDispatch, useSelector } from '@/redux';
import { getMenuPriceText, getSelectedItemCountInStep } from '@/libs/order';
import { orderModes } from '@/constants/app';
import { useHistory } from '@/libs/reactRouter';
import ItemBox from '@/components/ItemBox';
import colors from '@/theme/colors';
import themeStyle from '@/theme/themeStyle';

const SetMenuListItem = ({ item: setMenu }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const orderMode = useSelector(state => state.setting.orderMode);
  const nextStep = useSelector(selectors.order.selectNextStep);
  const step = useSelector(selectors.order.selectStep);
  const isMaxAmountReached = useSelector(state => {
    const selectedItemCountInStep = getSelectedItemCountInStep(state.order.workingItem, step);

    return step.max !== 0 &&
      selectedItemCountInStep >= step.max &&
      selectedItemCountInStep !== 0;
  });

  const reconcileOnBack = () => {
    dispatch(actions.order.removeSelection());
    dispatch(actions.order.reconcileStash());
    history.push('/order/sets/subsets/steps');
  };

  const handleLongPress = () => {
    // 「下一步」按鈕不支援長按
    if (setMenu.isNextStep) { return; }
    if (orderMode === orderModes.ITEM_FIRST) {
      handlePress(null, orderModes.OPTION_FIRST);
    } else if (orderMode === orderModes.OPTION_FIRST) {
      handlePress(null, orderModes.ITEM_FIRST);
    }
  };
  const handlePress = (e, mode) => {
    // 是「下一步」按鈕
    if (setMenu.isNextStep) {
      reconcileOnBack();
      dispatch(actions.order.addSelection({ subsetStepId: nextStep.key }));
      history.push('/order/sets/menus');
      return;
    }

    if (!mode) {
      mode = orderMode;
    }
    // 是點食物按鈕
    if (mode === orderModes.ITEM_FIRST) {
      dispatch(actions.order.createLocalSetItem(setMenu));
      dispatch(actions.order.addSetItemToWorkingItem());
    } else {
      dispatch(actions.order.addSelection({ setMenuId: setMenu.id }));
      dispatch(actions.order.createLocalSetItem(setMenu));
      history.push('/order/menus/options');
    }
  };

  const isDisabled = !setMenu.isNextStep && (isMaxAmountReached || setMenu.sold);

  return (
    <ItemBox
      key={setMenu.id}
      height={themeStyle.box.height.normal}
      item={setMenu}
      isSoldOut={setMenu.sold}
      disabled={isDisabled}
      caption={getMenuPriceText(setMenu)}
      onPress={handlePress}
      onLongPress={handleLongPress}
      style={setMenu.isNextStep && {
        backgroundColor: colors.primary.main,
      }}
    />
  );
};

export default SetMenuListItem;
