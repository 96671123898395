import { StyleSheet, View } from 'react-native';
import React from 'react';

import { useHistory } from '@/libs/reactRouter';
import { useSelector } from '@/redux';
import IconButton from '@/components/IconButton';
import colors from '@root/src/theme/colors';

export default function NotificationButton() {
  const history = useHistory();
  const hasUnreadMessages = useSelector(state => {
    const unread = state.notifications.kitchenCompleted.unread;

    return Object.values(unread).filter(Boolean).length > 0;
  });

  const onPress = () => {
    history.push('/notifications');
  };

  // TODO: update to notification icon
  return (
    <View>
      <IconButton
        iconSource={require('@icons/navbar/bell.png')}
        iconStyle={styles.icon}
        onPress={onPress}
      />

      {hasUnreadMessages && <View style={styles.dot} />}
    </View>
  );
}

const styles = StyleSheet.create({
  icon: {
    height: 32,
    width: 32,
    marginHorizontal: 12,
  },
  dot: {
    position: 'absolute',
    top: -4,
    right: 12,
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: colors.notification.unread,
  },
});
