import { StyleSheet } from 'react-native';
import { actions, useDispatch, useSelector } from '@/redux';
import { useHistory } from '@/libs/reactRouter';
import IconButton from '@/components/IconButton';
import React from 'react';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  icon: {
    height: 32,
    width: 32,
  },
});

export default function SearchButton (props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const numSelectionToRemove = useSelector(state => state.order.selectionStack.length - 1);

  const onPress = () => {
    dispatch(actions.order.removeSelection(numSelectionToRemove));
    history.push('/order/searchmenu');
  };

  return (
    <IconButton iconSource={require('@icons/navbar/search.png')} iconStyle={styles.icon} onPress={onPress} />
  );
}
