import { Image, StyleSheet, TouchableOpacity } from 'react-native';
import React from 'react';

export default function IconButton (props) {
  const { style, iconStyle, iconSource, onPress, disabled, ...rest } = props;
  return (
    <TouchableOpacity {...rest} style={style} disabled={disabled} onPress={onPress}>
      <Image style={[styles.icon, iconStyle]} source={iconSource} />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  icon: {
    width: 48,
    height: 48,
  },
});
