import { StyleSheet, View } from 'react-native';
import { selectors, useSelector } from '@/redux';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import NavBar from '@/components/NavBar';
import React from 'react';
import TableList from './TableList';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  contentContainer: {
    padding: 10,
    flex: 1,
  },
});

export default function TransferTable (props) {
  const { t } = useTranslation();
  const tableGroups = useSelector(selectors.merchant.selectTableGroups);
  const { selectedTransferTableGroupIndex } = useSelector(state => state.orderHistory);

  return (
    <View style={{ flex: 1 }}>
      <NavBar backButton title={t('app.page.transferTable.title')} />
      <Header />
      <View style={styles.contentContainer}>
        <TableList tables={tableGroups[selectedTransferTableGroupIndex]?.tables} />
      </View>
    </View>
  );
}
