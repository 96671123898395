import ActionTypes from './ActionTypes';
import * as codePushActions from '../codePush/actions';
import * as merchantActions from '../merchant/actions';
import * as orderHistoryActions from '../orderHistory/actions';
import * as settingActions from '../setting/actions';

/**
 *
 * @returns {ThunkFunction}
 */
export function init() {
  return async (dispatch, getState) => {
    updateCodePushStatus(true);
    await dispatch(codePushActions.checkUpdate());
    updateCodePushStatus(false);

    if (getState().auth.currentUser !== null) {
      await dispatch(settingActions.init());
      await dispatch(merchantActions.init());
    }

    dispatch({ type: ActionTypes.INIT });
  };
}

/**
 * @param {boolean?} isCodePushing
 * @return {ThunkFunction}
 */
export function updateCodePushStatus(isCodePushing) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_CODEPUSH_STATUS,
      payload: isCodePushing,
    });
  };
}

/**
 * @param {string} dialog
 * @param {boolean?} open
 * @param {any?} data
 * @return {ThunkFunction}
 */
export function toggleDialog(dialog, open, data) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_DIALOG,
      payload: { dialog, open, data },
    });
  };
}

/**
 * @param {string} drawer
 * @param {boolean?} open
 * @param {any?} data
 * @return {ThunkFunction}
 */
export function toggleDrawer(drawer, open, data) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_DRAWER,
      payload: { drawer, open, data },
    });
  };
}

/**
 * @returns {ThunkFunction}
 */
export function resetDialogs() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.RESET_DIALOGS,
    });
  };
}

/**
 * @returns {ThunkFunction}
 */
export function resetDrawers() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.RESET_DRAWERS,
    });
  };
}

/**
 * @returns {ThunkFunction}
 */
export const openLoading = (key) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_LOADING,
      payload: { key, remove: false },
    });
  };
};

/**
 * @returns {ThunkFunction}
 */
export const closeLoading = (key) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_LOADING,
      payload: { key, remove: true },
    });
  };
};

/**
 *
 * @param {string} env
 * @returns {ThunkFunction}
 */
export const updateEnv = env => {
  return {
    type: ActionTypes.ENV_UPDATED,
    payload: env,
  };
};
