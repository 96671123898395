import { StyleSheet, View } from 'react-native';
import React from 'react';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.white,
    borderRadius: 8,
  },
});

export default function Card (props) {
  const {
    style,
    children,
  } = props;

  return (
    <View style={[styles.container, style]}>
      {children}
    </View>
  );
}
