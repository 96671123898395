import { StyleSheet, TouchableOpacity } from 'react-native';
import React from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import { getItemAttributes } from '@/libs/order';
import { useHistory, useLocation } from '@/libs/reactRouter';
import colors from '@/theme/colors';

import Attributes from './Sections/Attributes';
import Body from './Sections/Body';
import Icon from './Sections/Icon';
import Price from './Sections/Price';
import Quantity from './Sections/Quantity';
import Row from './Layout/Row';

const SetItem = ({ set, item, editable, isItemInCurrentBatch }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const hasError = useSelector(state => Boolean(state.order.invalidItemIdMap[item.id]?.length));

  const onEdit = () => {
    dispatch(actions.order.stash());
    dispatch(actions.order.addSelection({
      setId: set.categoryId,
      subsetId: set.setId,
      setMenuId: item.menuItemId,
      subsetStepId: item.step,
    }));
    dispatch(actions.order.updateWorkingItem(set));
    dispatch(actions.order.updateWorkingSetItem(item));
    dispatch(actions.order.enterEditMode(location.pathname));
    history.push('/order/menus/options');
  };

  return (
    <TouchableOpacity
      disabled={!editable}
      style={{
        paddingBottom: 8,
        backgroundColor: hasError
          ? colors.batch.inComplete
          : colors.white,
      }}
      onPress={onEdit}
    >
      <Row
        icon={
          <Icon
            item={item}
            indent={1}
            isItemInCurrentBatch={isItemInCurrentBatch}
            onDelete={() => {
              dispatch(actions.order.deleteSetItemfromBatch(set.key, item.key, set.menuId));
            }}
          />
        }
        style={{ paddingVertical: 4 }}
      >
        <Body item={item} indent={1} />
        <Quantity item={item} />
        <Price item={item} />
      </Row>

      <Attributes
        compact
        indent={1}
        attributes={getItemAttributes(item)}
        muted={item.cancelled}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    flex: 1,
    paddingHorizontal: 12,
    paddingVertical: 11,
    flexDirection: 'row',
  },
});

export default React.memo(SetItem);
