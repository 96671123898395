import { ActivityIndicator, Pressable, StyleSheet, TouchableHighlight, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import React from 'react';
import Text from './Text';
import _ from 'lodash';
import colors from '@/theme/colors';

const TouchableComponents = {
  TouchableWithoutFeedback,
  TouchableOpacity,
  TouchableHighlight,
};

/**
 * @param {IButtonProps} props
 */
export default function Button (props) {
  const {
    style,
    textStyle,
    backgroundColor,
    textColor = colors.white,
    textBold = false,
    title,
    onPress,
    onPressStyle,
    disabled,
    touchableProps,
    children,
    loading,
    fontSize = 16,
    type = 'TouchableOpacity',
  } = props;

  const TouchableComponent = TouchableComponents[type];
  const diabledStyle = (disabled || loading) && (props.disabledStyle || styles.disabled);
  const action = _.throttle(onPress, 1500, { trailing: false });

  if (type === 'Pressable') {
    return (
      <Pressable
        {...touchableProps}
        onPress={action}
        disabled={disabled}
        style={({ pressed }) => [styles.container, style, diabledStyle, { backgroundColor: pressed ? onPressStyle.backgroundColor : backgroundColor }]}
      >
        {({ pressed }) =>
          <>
            {title &&
              <Text
                size={fontSize}
                style={[
                  textStyle,
                  { color: pressed ? onPressStyle.textColor : textColor },
                ]}
              >
                {title}
              </Text>}
            {!title && children}
          </>}
      </Pressable>
    );
  }

  return (
    <TouchableComponent
      {...touchableProps}
      onPress={action}
      disabled={disabled || loading}
    >
      <View style={[styles.container, { backgroundColor }, style, diabledStyle]}>
        {!loading && (
          <>
            {title &&
              <Text
                size={fontSize}
                style={[
                  { color: textColor, fontWeight: textBold ? 'bold' : '400' },
                  textStyle,
                ]}
              >
                {title}
              </Text>}
            {!title && children}
          </>
        )}
        {
          loading && (
            <ActivityIndicator size='small' color={colors.black} />
          )
        }
      </View>
    </TouchableComponent>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 53,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  disabled: {
    opacity: 0.4,
  },
});
