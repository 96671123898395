import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { selectors, useSelector } from '@/redux';
import Text from '@/components/Text';
import colors from '@/theme/colors';

export default function ItemBox({
  height,
  item,
  disabled,
  isSoldOut,
  onPress,
  onLongPress,
  style,
  caption,
}) {
  const { t } = useTranslation();
  const itemName = useSelector(state => {
    return selectors.menu.selectMenuNameByLocale(state, item);
  });

  if (item.isFiller) {
    return (
      <View style={[styles.container, styles.filler, { height }]} />
    );
  }

  return (
    <TouchableOpacity
      style={[
        styles.container,
        styles.touchable,
        {
          backgroundColor: disabled
            ? colors.grey[500]
            : colors.grey[800],
          height,
        },
        style,
      ]}
      activeOpacity={0.75}
      disabled={disabled}
      onPress={onPress}
      onLongPress={onLongPress}
    >
      <Text size={22} numberOfLines={3} style={styles.name}>
        {itemName}
      </Text>

      {disabled && (
        <View style={styles.overlay}>
          <Text style={styles.soldText} size={28}>
            {isSoldOut ? t('app.constant.menu.sold') : t('app.constant.menu.maxReached')}
          </Text>
        </View>
      )}

      {caption
        ? (
          <Text style={styles.caption}>
            {caption}
          </Text>
          )
        : null}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 4,
    padding: 6,
    alignItems: 'center',
    justifyContent: 'center',
  },
  touchable: {
    backgroundColor: colors.tableStatus.default,
  },
  filler: {
    backgroundColor: 'transparent',
  },
  name: {
    color: colors.white,
    fontWeight: 'bold',
    textAlign: 'center',
    ...(Platform.OS === 'web' ? { overflowWrap: 'anywhere' } : {}),
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: colors.white,
    opacity: 0.5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  soldText: {
    fontWeight: 'bold',
  },
  caption: {
    position: 'absolute',
    right: 4,
    bottom: 0,
    color: colors.grey[300],
  },
});
