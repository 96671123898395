import { StyleSheet, View } from 'react-native';
import React from 'react';

import { actions, selectors, useDispatch, useSelector } from '@/redux';
import { useHistory } from '@/libs/reactRouter';
import FooterOrderButtons from '@/components/FooterOrderButtons';
import ListWithBatchCart from '@/components/ListWithBatchCart';
import NavBar from '@/components/NavBar';
import themeStyle from '@/theme/themeStyle';

export default function OrderCart (props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    table: selectedTable,
    setId,
    subsetId,
  } = useSelector(selectors.order.selectOrderSelections);
  const workingItem = useSelector(state => state.order.workingItem);
  const selectionStack = useSelector(state => state.order.selectionStack);

  return (
    <View style={{ flex: 1 }}>
      <NavBar
        title={selectedTable}
        searchButton
        backButton
        onBack={() => {
          dispatch(actions.order.setCartExpanded(false));

          if (!workingItem && setId && subsetId) {
            dispatch(actions.order.removeSelection(selectionStack.length - 1));
            history.push('/order-categories');
          } else {
            history.goBack();
          }
        }}
      />

      <View style={styles.contentContainer}>
        <ListWithBatchCart showBatchCartOnly />

        <FooterOrderButtons />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    paddingHorizontal: themeStyle.spacing.app.horizontal,
    paddingVertical: themeStyle.spacing.app.vertical,
  },
});
