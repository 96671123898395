import { Image, KeyboardAvoidingView, StyleSheet, View } from 'react-native';

import { actions, useDispatch } from '@/redux';
import { dialogNames } from '@/components/Dialogs/constants';
import Background from '@/components/Background';
import NTapper from '@/components/NTapper';
import VersionEnvInfo from '@/components/VersionEnvInfo';
import colors from '@/theme/colors';

import LoginForm from './LoginForm';

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: colors.primary.main,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  imageContainer: {
    width: '50%',
    position: 'relative',
    left: 50,
    transform: [
      { translateX: 50 },
    ],
    height: '45%',
    justifyContent: 'center',
  },
});

export default function Login() {
  const dispatch = useDispatch();

  const handleEnvChangeTapping = () => {
    dispatch(actions.app.toggleDialog(
      dialogNames.systemAdmin,
      true,
      { nextDialog: dialogNames.env },
    ));
  };

  return (
    <View style={styles.container}>
      <Background />
      <KeyboardAvoidingView
        behavior='position'
        style={{ width: '100%' }}
        keyboardShouldPersistTaps='handled'
      >
        <NTapper
          resetInSeconds={1}
          targetTapCount={10}
          onTapCountReached={handleEnvChangeTapping}
        >
          <View style={styles.imageContainer}>
            <Image
              source={require('@images/logo/dimpos_logo.png')}
              style={{ width: '100%', height: 92 }}
              resizeMode='center'
            />
          </View>
        </NTapper>

        <LoginForm />
      </KeyboardAvoidingView>

      <VersionEnvInfo />
    </View>
  );
}
