import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import Text from '@/components/Text';
import TextInputBox from '@/components/TextInputBox';
import colors from '@/theme/colors';

const Remarks = ({ value, onChange }) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Text style={styles.title}>
        {t('app.page.order.options.remarks')}
      </Text>
      <TextInputBox
        keyboardType='default'
        placeholderTextColor={colors.text.placeholder}
        value={value}
        onChangeText={onChange}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  title: {
    fontWeight: 'bold',
    marginBottom: 8,
  },
});

export default Remarks;
