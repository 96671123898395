import { getTableName, status } from '@/libs/order';

/**
 * @param {IRootState} state
 * @returns {{
 *    read: { tableName: string, item: IAppBatchItem }[];
 *    unread: { tableName: string, item: IAppBatchItem }[];
 * }}
 */
export const selectCompletedDishesNotifications = (state) => {
  const kitchenCompleted = state.notifications.kitchenCompleted;
  const read = [];
  const unread = [];
  const currentUsername = state.auth.currentUser?.account;

  if (!currentUsername) {
    return { read, unread };
  }

  const updateReadAndUnread = (order, item) => {
    if (kitchenCompleted.read[item.id]) {
      read.push({
        tableName: getTableName(order),
        item,
      });
    } else if (kitchenCompleted.unread[item.id]) {
      unread.push({
        tableName: getTableName(order),
        item,
      });
    }
  };

  state.orderHistory.orders.forEach(o => {
    if (o.status !== status.PENDING) {
      return;
    }

    o.batches.forEach(b => {
      if (b.identifier !== currentUsername) {
        return;
      }

      b.items.forEach(item => {
        if (item.isSet) {
          item.setItems.forEach(setItem => {
            updateReadAndUnread(o, setItem);
          });

          return;
        }

        updateReadAndUnread(o, item);
      });
    });
  });

  return {
    read: read.sort((a, b) => new Date(b.item.kitchenCompletedAt) - new Date(a.item.kitchenCompletedAt)),
    unread: unread.sort((a, b) => new Date(b.item.kitchenCompletedAt) - new Date(a.item.kitchenCompletedAt)),
  };
};
