import { StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { selectors, useSelector } from '@/redux';
import Text from '@/components/Text';
import colors from '@/theme/colors';

import { INDENT_PADDING_TITLE } from './styles';

const ItemName = ({ item, muted, indent }) => {
  const { t } = useTranslation();
  const name = useSelector(state => {
    return selectors.menu.selectMenuNameByLocale(state, item);
  });

  const cancelledStyle = { color: colors.cancelled.text };
  const baseTextStyle = [
    styles.text,
    {
      color: muted ? colors.black + '60' : colors.black,
      paddingLeft: indent * INDENT_PADDING_TITLE,
    },
  ];
  const textStyle = item.cancelled ? [...baseTextStyle, cancelledStyle] : baseTextStyle;

  return (
    <Text style={textStyle} size={16}>
      <Text>{name}</Text>
      {item.cancelled && <Text style={{ color: colors.cancelled.tag }}> {t('app.constant.menu.cancelled')}</Text>}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    fontWeight: 'bold',
  },
});

export default ItemName;
