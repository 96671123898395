import { StyleSheet, View } from 'react-native';
import React from 'react';

import Text from '@/components/Text';
import colors from '@/theme/colors';

import { INDENT_PADDING_ATTR } from './styles';
import Row from '../Layout/Row';

const Attributes = ({ attributes, divider, style, compact, indent = 0, muted }) => {
  const cancelledStyle = { color: colors.cancelled.text };
  const attrTextStyle = muted ? [styles.attributeText, cancelledStyle] : styles.attributeText;

  if (!attributes) {
    return null;
  }

  return (
    <Row
      style={[
        { paddingVertical: compact ? 0 : 8 },
        style,
      ]}
    >
      <View
        style={{
          borderBottomWidth: divider ? 1 : 0,
          borderColor: colors.divider,
          paddingLeft: indent * INDENT_PADDING_ATTR,
        }}
      >
        <Text size={16} style={attrTextStyle}>
          {attributes}
        </Text>
      </View>
    </Row>
  );
};

const styles = StyleSheet.create({
  attributeText: {
    paddingRight: 12,
  },
});

export default Attributes;
