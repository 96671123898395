import { Text as NativeText } from 'react-native';
import React from 'react';

import { fontSizeKey } from '@/constants/app';
import { useSelector } from '@/redux';

const sizeAdjustment = 3;

export default function Text (props) {
  const { style, children, size = 16, ...rest } = props;
  const { fontSize } = useSelector(state => state.setting.systemSettings);

  return (
    <>
      {fontSize === fontSizeKey.SMALL && (
        <NativeText style={[style, { fontSize: size - sizeAdjustment }]} {...rest}>
          {children}
        </NativeText>
      )}
      {fontSize === fontSizeKey.MIDDLE && (
        <NativeText style={[style, { fontSize: size }]} {...rest}>
          {children}
        </NativeText>
      )}
      {fontSize === fontSizeKey.LARGE && (
        <NativeText style={[style, { fontSize: size + sizeAdjustment }]} {...rest}>
          {children}
        </NativeText>
      )}
    </>
  );
}
