import { useEffect, useState } from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import waiterApi from '@/libs/api/waiter';

export default function useAllMenus() {
  const dispatch = useDispatch();
  const isLogin = useSelector(state => state.auth.currentUser !== null);
  const menus = useSelector(state => state.menu.menus);
  const isMerchantInit = useSelector(state => state.merchant.isInit);
  const [isLoading, setIsLoading] = useState(!isMerchantInit);

  useEffect(() => {
    const getMenus = async () => {
      const data = await waiterApi.getAllMenus();

      dispatch(actions.menu.updateMenuList(data));
    };

    const init = async () => {
      if (!isMerchantInit) {
        await dispatch(actions.merchant.init());
      }

      if (!menus.length) {
        await getMenus();
      }

      setIsLoading(false);
    };

    init();
  }, [dispatch, menus, isLogin, isMerchantInit]);

  return { isLoading };
}
