export const listNumColumns = 3;

// navbar default height
export const navMinHeight = 55;

export const loadingKey = {
  OPEN_TABLE: 'openTable',
  CONFIRM_ORDER: 'confirmOrder',
  UPDATE_CUSTOMER_COUNT: 'updateCustomerCount',
  TRANSFER_ORDER: 'transferOrder',
  CONFIG: 'config',
};

export const fontSizeKey = {
  SMALL: 'small',
  MIDDLE: 'middle',
  LARGE: 'large',
};

export const orderModes = {
  ITEM_FIRST: 'item_first', // add item to batch on item click
  OPTION_FIRST: 'option_first', // go to option select on item click, then add to batch
};

export const settingKey = {
  MAIN: 'main',
  USER_INFO: 'userInfo',
  SYSTEM_INFO: 'systemInfo',
};

export const settingsDelayLongPress = 2000;

export const storageKeys = {
  OVERWRITE_ENV: 'OVERWRITE_ENV',
  USER_LOGIN: 'userLogin',
};
