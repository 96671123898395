import { isWithinSpecifiedTimeRange, isWithinSpecifiedWeekdays } from '../libs/order';
import { useSelector } from '@/redux';
import _ from 'lodash';

function toUpperCase (string) {
  if (typeof string === 'string') {
    return string.toUpperCase();
  }

  return string;
}

export function matchSearchText (item, searchText, options = { caseSensitive: false }) {
  if (!item) return false;

  const targets = [item.code, item.shortCode, item.name, item.shortName];
  // search text case insensitive
  return targets.find(target => {
    if (options.caseSensitive) {
      return _.includes(target, searchText);
    } else {
      return _.includes(toUpperCase(target), toUpperCase(searchText));
    }
  });
}

export function useSearchedMenu () {
  const { menus, sets, searchText, categories } = useSelector(state => state.menu);

  const matchingMenus = menus.filter(menu => {
    return !menu.step && matchSearchText(menu, searchText);
  });
  const matchingSets = sets.filter(set => {
    return set.setMenus.some(setMenu => matchSearchText(setMenu, searchText)) || matchSearchText(set, searchText);
  });

  const results = _.filter(_.uniqBy([...matchingMenus, ...matchingSets], 'id'), (result) => {
    const category = categories.find(o => o.id === result.categoryId);
    if (category) {
      return [
        !category.takeawayOnly,
        !category.disabled,
        isWithinSpecifiedWeekdays(category.weekdays),
        isWithinSpecifiedTimeRange(category.timerange),
        !result.disabled,
      ].every(Boolean);
    } else {
      for (let i = 0; i < categories.length; i++) {
        const subCategory = categories[i].categories.find(o => o.id === result.categoryId);

        if (subCategory) {
          return [
            !subCategory.takeawayOnly,
            !subCategory.disabled,
            isWithinSpecifiedWeekdays(subCategory.weekdays),
            isWithinSpecifiedTimeRange(subCategory.timerange),
            !result.disabled,
          ].every(Boolean);
        }
      }
    }

    return false;
  });

  return results;
}
