import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import { checkPermission, verifyCredentials } from '@root/src/libs/auth';
import Button from '@/components/Button';
import CenterModal from '@/components/Modals/CenterModal';
import Text from '@/components/Text';
import TextInputBox from '@/components/TextInputBox';
import colors from '@/theme/colors';
import logger from '@/libs/logger';

import { accountPermissions } from './permissions';
import { dialogNames } from '../constants';

const AccountPermissionDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.app.dialogs.accountPermission.open);
  const { requiredPermission, extra } = useSelector(state => state.app.dialogs.accountPermission.data);
  const scopeCredentials = useSelector(state => state.auth.scopeCredentials);
  const merchantUserName = useSelector(state => state.auth.merchantUserName);
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    dispatch(actions.app.toggleDialog(dialogNames.accountPermission, false, {}));
  };

  const proceedToNextStep = () => {
    switch (requiredPermission) {
      case accountPermissions.TRANSFER_ITEM:
        dispatch(actions.orderHistory.selectOrderToTransfer(extra));
        break;

      case accountPermissions.CANCEL_ORDER:
        dispatch(actions.app.toggleDrawer(
          'cancelReason',
          true,
          { orderId: extra, identifier: account },
        ));
        break;

      default:
        logger.log(`[account permission dialog] unknown permission: ${requiredPermission}`);
        break;
    }
  };

  const handleSubmit = async () => {
    try {
      setHasError(false);
      setIsSubmitting(true);

      const user = await verifyCredentials(merchantUserName, account, password);
      const hasPermission = checkPermission(scopeCredentials, user, requiredPermission);

      if (!hasPermission) {
        throw new Error(`do not have permission to ${requiredPermission}`);
      }

      proceedToNextStep();
      handleClose();
    } catch (err) {
      setHasError(true);
      logger.log(`[account permission dialog] ${err.message}`, { err });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setAccount('');
      setPassword('');
      setHasError(false);
    }
  }, [isOpen]);

  return (
    <CenterModal
      visible={isOpen}
      enablePressOutsideClose
      title={t('app.dialogs.accountPermission.title')}
      containerStyle={{ width: '80%' }}
      contentContainerStyle={styles.container}
      onClose={handleClose}
    >
      <TextInputBox
        testID='login-input-username-dialog'
        keyboardType='default'
        style={styles.textInputBox}
        placeholder={t('app.page.login.form.account')}
        placeholderTextColor={colors.text.placeholder}
        autoCapitalize='none'
        autoCorrect={false}
        value={account}
        onChangeText={(text) => {
          setAccount(text);
        }}
      />
      <TextInputBox
        testID='login-input-password-dialog'
        keyboardType='default'
        secureTextEntry
        style={styles.textInputBox}
        placeholder={t('app.page.login.form.password')}
        placeholderTextColor={colors.text.placeholder}
        autoCapitalize='none'
        autoCorrect={false}
        value={password}
        onChangeText={(text) => {
          setPassword(text);
        }}
      />

      {hasError && (
        <Text style={styles.errorText}>
          {t('app.dialogs.accountPermission.loginFailed')}
        </Text>
      )}

      <View style={{ width: '100%' }}>
        <Button
          fontSize={20}
          style={styles.button}
          textStyle={styles.buttonTxt}
          title={t('app.page.login.form.signIn')}
          onPress={handleSubmit}
          disabled={!account || !password}
          loading={isSubmitting}
        />
      </View>
    </CenterModal>
  );
};

const styles = StyleSheet.create({
  container: {
    minWidth: '80%',
    maxWidth: 450,
  },
  textInputBox: {
    marginVertical: 15,
    borderWidth: 1,
  },
  errorText: {
    color: colors.text.error,
    fontWeight: 'bold',
    marginBottom: 12,
  },
  button: {
    backgroundColor: colors.primary.main,
  },
  buttonTxt: {
    color: colors.white,
    fontWeight: 'bold',
  },
});

export default AccountPermissionDialog;
