import { useTranslation } from 'react-i18next';
import React from 'react';

import { actions, selectors, useDispatch, useSelector } from '@/redux';
import { getSelectedItemCountInStep, mergeSetItemIntoItem } from '@/libs/order';
import ActionBar from '@/components/ActionBar';
import Stepper from '@/components/Stepper';

const QuantityBar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const workingSetItem = useSelector(state => state.order.workingSetItem);
  const isSetItem = useSelector(selectors.order.selectIsSetItem);
  const workingSingleItem = useSelector(state => state.order.workingItem);
  const { quantity } = useSelector(selectors.order.selectCurrentItem);
  const menu = useSelector(selectors.order.selectMenu);
  const menuSetStep = useSelector(selectors.order.selectMenuSetStep);

  const selectedItemCountInStep = isSetItem
    ? getSelectedItemCountInStep(
      mergeSetItemIntoItem(workingSingleItem, workingSetItem),
      menuSetStep,
    )
    : 0;

  const getQuantityText = () => {
    if (isSetItem) {
      if (menu.min > 0 && menu.max === 0) {
        return `( ${t('app.page.order.options.minChoose')} ${menu.min} )`;
      }
      if (menu.max > 0 && menu.min === 0 && menu.max <= menuSetStep.max) {
        return `( ${t('app.page.order.options.maxChoose')} ${menu.max} )`;
      }
      if (menu.min > 0 && menu.max > 0) {
        return `( ${t('app.page.order.options.must')} ${menu.min} - ${menu.max} )`;
      }
      if (menuSetStep.max > 0) {
        return `( ${t('app.page.order.step.maxChoose')} ${menuSetStep.max} )`;
      }
    }

    return '';
  };

  const isIncrementDisabled = isSetItem && (
    (menuSetStep.max > 0 && selectedItemCountInStep >= menuSetStep.max) ||
    (menu.max > 0 && quantity >= menu.max)
  );

  const isDecrementDisabled = quantity === 0;

  const handleIncrement = () => {
    if (isSetItem) {
      dispatch(actions.order.updateSetItemQuantity(quantity + 1));
    } else {
      dispatch(actions.order.updateItemQuantity(quantity + 1));
    }
  };

  const handleDecrement = () => {
    if (isSetItem) {
      dispatch(actions.order.updateSetItemQuantity(quantity - 1));
    } else {
      dispatch(actions.order.updateItemQuantity(quantity - 1));
    }
  };

  return (
    <ActionBar
      style={{ marginBottom: 16 }}
      title={`${t('app.page.order.options.qty')} ${getQuantityText()}`}
    >
      <Stepper
        value={quantity.toString()}
        onPressIncrement={handleIncrement}
        onPressDecrement={handleDecrement}
        minusDisabled={isDecrementDisabled}
        plusDisabled={isIncrementDisabled}
      />
    </ActionBar>
  );
};

export default QuantityBar;
