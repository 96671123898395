import { View } from 'react-native';
import React from 'react';

import { actions, useDispatch } from '@/redux';
import { useHistory } from '@/libs/reactRouter';

import Button, { variants } from './Buttons';

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <View style={{ alignItems: 'flex-end', marginTop: 24, marginBottom: 12, marginHorizontal: 12 }}>
      <Button
        variant={variants.ADD}
        onPress={async () => {
          await dispatch(actions.order.createLocalOrder('table'));
        }}
      />
    </View>
  );
};

export default Header;
