import { ActivityIndicator, Platform, StyleSheet, Text, View } from 'react-native';
import { useSelector } from '../redux';
import React from 'react';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.grey['600'],
  },
});

export default function Loader (props) {
  const { loadingActions, isCodePushing } = useSelector(state => state.app);
  const loading = loadingActions?.length > 0;

  // already in loading
  if (loading) return null;

  return (
    <View style={styles.container}>
      {!isCodePushing && <ActivityIndicator color={colors.primary.main} size={Platform.OS === 'android' ? 60 : 'large'} />}
      {isCodePushing && <Text style={styles.text}>檢查更新中...</Text>}
    </View>
  );
}
