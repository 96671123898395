import betaConfig from './beta';
import defaultConfig from './default';
import productionConfig from './production';
import testConfig from './test';

const configs = {
  default: defaultConfig,
  development: testConfig,
  test: testConfig,
  beta: betaConfig,
  prod: productionConfig,
};

export default configs;
