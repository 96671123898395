import { FlatList, StyleSheet, View } from 'react-native';
import { actions, useDispatch, useSelector } from '@/redux';
import { useHistory } from '@/libs/reactRouter';
import { useTranslation } from 'react-i18next';
import FooterDoneButton from '@/components/FooterDoneButton';
import Header from './Header';
import NavBar from '@/components/NavBar';
import React, { useState } from 'react';

import Text from '@/components/Text';
import colors from '@/theme/colors';
import shortCode from '@/libs/shortCode';

import { padToMatchMultiple } from '@root/src/libs/array';
import TagButton from './TagButton';
import itemStatus from '@/constants/itemStatus';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  titleContainer: {
    marginHorizontal: 10,
    marginBottom: 12,
  },
  title: {
    fontWeight: 'bold',
    color: colors.black + '60',
  },
});

const numColumns = 3;

export default function CustomTag(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { prefix, suffix } = useSelector(state => state.merchant.data.customTag);
  const { tags } = useSelector(state => state.order.workingItem);
  const customTags = tags.filter(o => o.id !== itemStatus.SERVE_LATER);

  const [selectedPrefix, setSelectedPrefix] = useState('');
  const [selectedSuffix, setSelectedSuffix] = useState('');

  const filteredPrefix = prefix.filter((o) => o.enabled);
  const filteredSuffix = suffix.filter((o) => o.enabled);

  const renderPrefixItem = ({ item: tag, index }) => {
    return (
      <TagButton
        tag={tag}
        selected={tag.id === selectedPrefix?.id}
        onPress={() => {
          if (tag.id === selectedPrefix?.id) {
            setSelectedPrefix('');
          } else {
            setSelectedPrefix(tag);
          }
        }}
      />
    );
  };

  const renderSuffixItem = ({ item: tag, index }) => {
    return (
      <TagButton
        tag={tag}
        onPress={() => {
          setSelectedSuffix(tag);
          dispatch(actions.order.addTag({
            id: shortCode.generate(6),
            name: selectedPrefix.name
              ? selectedPrefix.name + tag.name
              : tag.name,
          }));
          setSelectedSuffix('');
        }}
      />
    );
  };

  return (
    <View style={styles.container}>
      <NavBar
        title={t('app.page.customTag.title')}
        backButton
      />

      <Header tags={customTags} />

      <View style={styles.titleContainer}>
        <Text size={16} style={styles.title}>{t('app.page.customTag.prefix')}</Text>
      </View>

      <View
        style={{
          height: '25%',
          flex: 1,
          alignItems: 'center',
          marginBottom: 10,
          marginHorizontal: 10,
        }}
      >
        <FlatList
          style={{ width: '100%' }}
          data={padToMatchMultiple(filteredPrefix, numColumns)}
          renderItem={renderPrefixItem}
          numColumns={numColumns}
          showsVerticalScrollIndicator={false}
          keyExtractor={(tag) => tag.id}
        />
      </View>

      <View style={styles.titleContainer}>
        <Text size={16} style={styles.title}>{t('app.page.customTag.suffix')}</Text>
      </View>

      <View
        style={{
          height: '38%',
          flex: 1,
          alignItems: 'center',
          marginBottom: 10,
          marginHorizontal: 10,
        }}
      >
        <FlatList
          style={{ width: '100%' }}
          data={padToMatchMultiple(filteredSuffix, numColumns)}
          renderItem={renderSuffixItem}
          numColumns={numColumns}
          showsVerticalScrollIndicator={false}
          keyExtractor={(tag) => tag.id}
        />
      </View>

      <FooterDoneButton onPress={() => { history.goBack(); }} />
    </View>
  );
}
