import { TouchableOpacity } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { actions, useDispatch } from '@/redux';
import { useHistory } from '@/libs/reactRouter';
import Text from '@/components/Text';
import colors from '@/theme/colors';

const SelectOrderButton = ({ orderId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <TouchableOpacity
      style={{
        height: 44,
        borderRadius: 8,
        backgroundColor: colors.button.main,
        marginHorizontal: 12,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 40,
      }}
      onPress={() => {
        dispatch(actions.order.selectOrderById(orderId));
        dispatch(actions.order.createLocalBatch());
        history.replace('/order-categories');
      }}
    >
      <Text size={18} style={{ fontWeight: 'bold' }}>{t('app.page.tableOrders.selectOrder')}</Text>
    </TouchableOpacity>
  );
};

export default SelectOrderButton;
