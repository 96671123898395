export default {
  INIT: 'APP/INIT',
  TOGGLE_DIALOG: 'APP/TOGGLE_DIALOG',
  RESET_DIALOGS: 'APP/RESET_DIALOGS',
  TOGGLE_DRAWER: 'APP/TOGGLE_DRAWER',
  RESET_DRAWERS: 'APP/RESET_DRAWERS',
  UPDATE_LOADING: 'APP/UPDATE_LOADING',
  ENV_UPDATED: 'APP/ENV_UPDATED',
  UPDATE_CODEPUSH_STATUS: 'APP/UPDATE_CODEPUSH_STATUS',
};
