const alphabets = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';

exports.generate = (length) => {
  let code = '';
  for (let i = 0; i < length; i++) {
    const key = Math.floor(Math.random() * alphabets.length);
    code += alphabets[key];
  }
  return code;
};
