import { FlatList, SectionList, StyleSheet, View } from 'react-native';
import React from 'react';
import moment from 'moment';

import { selectors, useSelector } from '@/redux';
import { variantKey } from '@/pages/OrderHistory/variantKey';
import Item from '@/pages/TableOrders/OrderList/OrderCard/Item';
import Set from '@/pages/TableOrders/OrderList/OrderCard/Set';
import Text from '@/components/Text';
import colors from '@/theme/colors';

const ItemList = ({ order, variant }) => {
  const me = useSelector(selectors.auth.selectUserIdentifier);
  const myOrderBatches = order.batches.filter(batch => batch.identifier === me);

  const batches = variant === variantKey.MYORDER ? myOrderBatches : order.batches;

  return (
    <SectionList
      sections={batches.map((batch, idx) => {
        return {
          title: `#${idx + 1}`,
          batchCreatedAt: batch.createdAt,
          data: [batch],
        };
      })}
      renderItem={({ item: batch }) => {
        return (
          <FlatList
            data={batch.items}
            renderItem={({ item, index }) => {
              if (item.isSet) {
                return <Set set={item} divider={index !== batch.items.length - 1} />;
              } else {
                return <Item item={item} divider={index !== batch.items.length - 1} />;
              }
            }}
          />
        );
      }}
      renderSectionHeader={({ section: { title, batchCreatedAt } }) => (
        <View style={styles.header}>
          <Text>{title}</Text>
          <Text>{moment(batchCreatedAt).format('HH:mm')}</Text>
        </View>
      )}
    />
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: colors.grey[300],
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    paddingVertical: 6,
  },
});

export default ItemList;
