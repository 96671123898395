import { useTranslation } from 'react-i18next';
import React from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import ActionBar from '@/components/ActionBar';
import ToggleSwitch from '@/components/ToggleSwitch';
import itemStatus from '@/constants/itemStatus';

const ServeLaterBar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const workingSetItem = useSelector(state => state.order.workingSetItem);
  const isSetItem = Boolean(workingSetItem);
  const workingSingleItem = useSelector(state => state.order.workingItem);
  const workingItem = isSetItem
    ? workingSetItem
    : workingSingleItem;

  const onToggle = () => {
    if (workingItem.tags.find(o => o.id === itemStatus.SERVE_LATER)) {
      dispatch(actions.order.deleteTag({
        id: itemStatus.SERVE_LATER,
        name: t('app.page.order.options.hold'),
      }));
    } else {
      dispatch(actions.order.addTag({
        id: itemStatus.SERVE_LATER,
        name: t('app.page.order.options.hold'),
      }));
    }
  };

  return (
    <ActionBar style={{ marginBottom: 16 }} title={t('app.page.order.options.hold')}>
      <ToggleSwitch
        value={Boolean(workingItem.tags.find(o => o.id === itemStatus.SERVE_LATER))}
        onChangeValue={onToggle}
      />
    </ActionBar>
  );
};

export default ServeLaterBar;
