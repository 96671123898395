import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { actions, selectors, useDispatch, useSelector } from '@/redux';
import { useHistory } from '@/libs/reactRouter';
import Button from '@/components/Button';
import colors from '@/theme/colors';

import Text from '../Text';

const FooterOrderButtons = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const cartInDeleteMode = useSelector(state => state.order.cartInDeleteMode);
  const deleteDisabled = useSelector(state => state.order.workingBatch.items.length === 0 && !cartInDeleteMode);
  const numInvalidItems = useSelector(selectors.order.selectNumberOfInvalidItems);
  const canConfirmOrder = useSelector(state => state.order.workingBatch.items.length > 0 && numInvalidItems === 0);

  return (
    <View style={styles.container}>
      <View style={styles.buttonContainer}>
        <Button
          style={[styles.button, cartInDeleteMode ? styles.confirmButton : styles.cancelButton]}
          disabled={deleteDisabled}
          onPress={() => {
            dispatch(actions.order.setCartInDeleteMode(!cartInDeleteMode));
          }}
        >
          <Text size={16} style={cartInDeleteMode ? styles.confirmText : styles.discardText}>
            {cartInDeleteMode ? t('app.common.confirm') : t('app.page.order.deleteItem')}
          </Text>
        </Button>
      </View>

      <View style={styles.spacer} />

      <View style={styles.buttonContainer}>
        <Button
          style={[styles.button, styles.confirmButton]}
          disabled={!canConfirmOrder || cartInDeleteMode}
          onPress={() => {
            dispatch(actions.order.confirmOrder());
            dispatch(actions.order.clearSelection());
            history.push('/tables');
          }}
        >
          <Text size={16} style={styles.confirmText}>
            {t('app.page.order.confirm')}
          </Text>
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  spacer: {
    width: 10,
  },
  buttonContainer: {
    flex: 1,
  },
  button: {
    height: 50,
    borderRadius: 8,
  },
  cancelButton: {
    borderColor: colors.button.secondary,
    backgroundColor: colors.button.secondary,
  },
  confirmButton: {
    borderColor: colors.button.main,
    backgroundColor: colors.button.main,
  },
  discardText: {
    color: colors.white,
    fontWeight: 'bold',
  },
  confirmText: {
    color: colors.black,
    fontWeight: 'bold',
  },
});

export default FooterOrderButtons;
