import { StyleSheet, View } from 'react-native';
import React, { useState } from 'react';

import { actions, useDispatch } from '@/redux';
import { dialogNames } from '@/components/Dialogs/constants';
import { useHistory } from '@/libs/reactRouter';
import { useTranslation } from 'react-i18next';
import Button from '@/components/Button';
import TextInputBox from '@/components/TextInputBox';
import colors from '@/theme/colors';
import logger, { logId } from '@/libs/logger';

const styles = StyleSheet.create({
  formContainer: {
    paddingHorizontal: 30,
    width: '100%',
    height: '45%',
  },
  textInputBox: {
    marginVertical: 15,
  },
  button: {
    backgroundColor: colors.primary.main,
  },
  buttonContainer: {
    paddingHorizontal: 30,
  },
  buttonTxt: {
    color: colors.white,
    fontWeight: 'bold',
  },
});

export default function LoginForm (props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [merchantUserName, setMerchantUserName] = useState('');
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const getErrorMessage = (error) => {
    let status = '';
    if (error.response) {
      status = error.response.status;
    } else if (error.request) {
      status = error.request.status;
    }

    if (status === 403) {
      return t('app.page.login.error.input');
    }

    if (error.message === '!permission') {
      return t('app.page.login.error.permission');
    }

    return t('app.page.login.error.default');
  };

  const onPress = async () => {
    try {
      setLoading(true);
      await dispatch(actions.auth.userLogin(merchantUserName, account, password));
      await dispatch(actions.auth.updateScopeCredentials());
      setLoading(false);
      history.replace('/tables');
    } catch (error) {
      setLoading(false);
      dispatch(actions.app.toggleDialog(dialogNames.error, true, {
        title: `${t('app.page.login.error.title')} (${logId})`,
        message: getErrorMessage(error),
      }));
      console.log({ error });
      logger.log(`[Login] Error ${error?.response?.data?.error || error?.message}`, { error });
    }
  };

  return (
    <>
      <View style={styles.formContainer}>
        <TextInputBox
          testID='login-input-merchant-name'
          keyboardType='default'
          style={styles.textInputBox}
          placeholder={t('app.page.login.form.merchantUserName')}
          placeholderTextColor={colors.text.placeholder}
          autoCapitalize='none'
          autoCorrect={false}
          value={merchantUserName}
          onChangeText={(text) => {
            setMerchantUserName(text);
          }}
        />
        <TextInputBox
          testID='login-input-username'
          keyboardType='default'
          style={styles.textInputBox}
          placeholder={t('app.page.login.form.account')}
          placeholderTextColor={colors.text.placeholder}
          autoCapitalize='none'
          autoCorrect={false}
          value={account}
          onChangeText={(text) => {
            setAccount(text);
          }}
        />
        <TextInputBox
          testID='login-input-password'
          keyboardType='default'
          secureTextEntry
          style={styles.textInputBox}
          placeholder={t('app.page.login.form.password')}
          placeholderTextColor={colors.text.placeholder}
          autoCapitalize='none'
          autoCorrect={false}
          value={password}
          onChangeText={(text) => {
            setPassword(text);
          }}
          maxLength={6}
        />
      </View>
      <View style={styles.buttonContainer} keyboardShouldPersistTaps='handled'>
        <Button
          fontSize={20}
          style={styles.button}
          textStyle={styles.buttonTxt}
          title={t('app.page.login.form.signIn')}
          onPress={onPress}
          disabled={!(merchantUserName && account && password)}
          loading={loading}
        />
      </View>
    </>
  );
}
