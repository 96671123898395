import { Route, Switch, useLocation } from '@/libs/reactRouter';
import React from 'react';

import AllOrders from './OrderHistory/AllOrders';
import CustomTag from './CustomTag';
import Landing from './Landing';
import Login from './Login';
import MyOrders from './OrderHistory/MyOrders';
import Notifications from './Notifications';
import OrderCart from './OrderCart';
import OrderCategories from './OrderCategories';
import OrderMenuOptions from './OrderMenuOptions';
import OrderMenus from './OrderMenus';
import OrderSetMenus from './OrderSetMenus';
import OrderSets from './OrderSets';
import OrderSubsetSteps from './OrderSubsetSteps';
import OrderSubsets from './OrderSubsets';
import SearchMenu from './SearchMenu';
import Setting from './Setting';
import TableOrders from './TableOrders';
import Tables from './Tables';
import TransferTable from './TransferTable';

export default function AppRouter (props) {
  const location = useLocation();

  console.log('location', location.pathname);

  return (
    <Switch>
      <Route exact path='/' component={Landing} />

      <Route exact path='/login' component={Login} />
      <Route exact path='/tables' component={Tables} />
      <Route exact path='/table-orders' component={TableOrders} />
      <Route exact path='/table-orders/transfertable' component={TransferTable} />
      <Route exact path='/order-categories' component={OrderCategories} />
      <Route exact path='/order/menus' component={OrderMenus} />
      <Route exact path='/order/menus/options' component={OrderMenuOptions} />
      <Route exact path='/order/sets' component={OrderSets} />
      <Route exact path='/order/sets/subsets' component={OrderSubsets} />
      <Route exact path='/order/sets/subsets/steps' component={OrderSubsetSteps} />
      <Route exact path='/order/sets/menus' component={OrderSetMenus} />
      <Route exact path='/order/ordercart' component={OrderCart} />
      <Route exact path='/order/customtag' component={CustomTag} />
      <Route exact path='/order/searchmenu' component={SearchMenu} />
      <Route exact path='/setting' component={Setting} />
      <Route exact path='/orderhistory/myorders' component={MyOrders} />
      <Route exact path='/orderhistory/allorders' component={AllOrders} />
      <Route exact path='/notifications' component={Notifications} />
    </Switch>
  );
}
