import colors from './colors';

export default {
  default: {
    elevation: 8,
    shadowColor: colors.black,
    shadowOpacity: 0.16,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowRadius: 6,
  },
  slight: {
    elevation: 8,
    shadowColor: colors.grey[500],
    shadowOpacity: 0.1,
    shadowOffset: {
      width: 1,
      height: 1,
    },
    shadowRadius: 1,
  },
  top: {
    elevation: 8,
    shadowColor: colors.black,
    shadowOpacity: 0.16,
    shadowOffset: {
      width: 2,
      height: -1,
    },
    shadowRadius: 6,
  },
  heavy: {
    elevation: 8,
    shadowColor: colors.black,
    shadowOpacity: 0.6,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowRadius: 10,
  },
};
