import { SafeAreaView } from 'react-native-safe-area-context';
import React from 'react';

import { useLongRunningTimers, useSafeAreaViewEdges, useWebSocket } from '@/hooks';
import Dialogs from '@/components/Dialogs';
import Drawers from '@/components/Drawers';
import colors from '@/theme/colors';
import '@/libs/e2eConfig';

import AppRouter from './AppRouter';
import Loading from '../components/Loading';

export default function Main() {
  const edges = useSafeAreaViewEdges();

  useWebSocket();
  useLongRunningTimers();

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.secondary.main }} edges={edges}>
      <AppRouter />

      <Drawers />
      <Dialogs />
      <Loading />
    </SafeAreaView>
  );
}
