import { TouchableWithoutFeedback } from 'react-native';
import React, { useEffect, useState } from 'react';

const NTapper = ({ resetInSeconds, targetTapCount, children, onTapCountReached }) => {
  const [tapCount, setTapCount] = useState(0);

  const handlePress = () => {
    if (tapCount + 1 === targetTapCount) {
      setTapCount(0);
      onTapCountReached();
    } else {
      setTapCount(prev => prev + 1);
    }
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      setTapCount(0);
    }, resetInSeconds * 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [tapCount, resetInSeconds]);

  return (
    <TouchableWithoutFeedback onPress={handlePress}>
      {children}
    </TouchableWithoutFeedback>
  );
};

export default NTapper;
