import { StyleSheet, View } from 'react-native';
import React from 'react';

import { actions, selectors, useDispatch, useSelector } from '@/redux';
import { useHistory } from '@/libs/reactRouter';
import LinearGradient from '@/components/RNLinearGradient';
import NavBar from '@/components/NavBar';
import colors from '@/theme/colors';

import Header from './Header';
import OrderList from './OrderList';

const TableOrders = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { table: selectedTable } = useSelector(selectors.order.selectOrderSelections);

  return (
    <View style={styles.container}>
      <NavBar
        title={selectedTable}
        orderHistoryButton
        backButton
        onBack={() => {
          dispatch(actions.order.removeSelection());
          history.goBack();
        }}
      />

      <LinearGradient
        colors={[colors.primary.lighter, colors.secondary.main]}
        start={[0.5, 0]}
        end={[0.5, 1]}
        style={styles.container}
      >
        <Header />
        <OrderList />
      </LinearGradient>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default TableOrders;
