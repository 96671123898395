import { Platform, StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import Constants from 'expo-constants';
import React from 'react';
import * as Device from 'expo-device';

import { Deployments } from '@root/src/configs/codePush';
import { ENV } from '@/configs/env';
import { actions, useDispatch, useSelector } from '@/redux';
import { dialogNames } from '@/components/Dialogs/constants';
import { logId } from '@/libs/logger';
import { settingsDelayLongPress } from '@/constants/app';
import Text from '@/components/Text';
import colors from '@/theme/colors';

import EnterRow from './component/SettingRow/EnterRow';

const packageJson = require('@root/package.json');

const styles = StyleSheet.create({
  titleContainer: {
    flex: 1,
    minHeight: 30,
    justifyContent: 'center',
    paddingLeft: 12,
  },
  title: {
    fontWeight: 'bold',
    color: colors.black + '60',
  },
});

export default function SystemInfo() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deployment = useSelector(state => state.codePush.overwriteDeployment ?? Deployments.Production);
  const env = useSelector(state => state.app.env);

  const packageVersion = `${packageJson.version} (${process.env.REACT_APP_SHORT_SHA ?? ''})`;
  const nativeVersion = Constants ? `${Constants.nativeAppVersion}\nBuild ${Constants.nativeBuildVersion}` : 'not in native';
  const systemInfo = `${Device.modelName} ${Device.osName} ${Device.osVersion}`;
  const envLabel = ENV.find(e => e.value === env)?.label ?? env;

  return (
    <>
      <View style={styles.titleContainer}>
        <Text size={14} style={styles.title}>{t('app.page.setting.systemInfo.title')}</Text>
      </View>

      <EnterRow
        divider
        label={t('app.page.setting.systemInfo.env')}
        value={envLabel}
        onPress={() => {}}
        touchableProps={{
          onLongPress: () => {
            dispatch(actions.app.toggleDialog(
              dialogNames.systemAdmin,
              true,
              { nextDialog: dialogNames.env },
            ));
          },
          delayLongPress: settingsDelayLongPress,
        }}
      />

      <EnterRow
        divider
        label={t('app.page.setting.systemInfo.package')}
        value={packageVersion}
      />

      <EnterRow
        divider
        label={t('app.page.setting.systemInfo.app')}
        value={nativeVersion}
      />

      <EnterRow
        divider
        label={t('app.page.setting.systemInfo.channel')}
        value={Platform.OS === 'web' ? t('app.page.setting.systemInfo.channelUnavailableWeb') : deployment}
        onPress={() => {}}
        touchableProps={{
          onLongPress: () => {
            dispatch(actions.app.toggleDialog(
              dialogNames.systemAdmin,
              true,
              { nextDialog: dialogNames.codePushChannel },
            ));
          },
          delayLongPress: settingsDelayLongPress,
          disabled: Platform.OS === 'web',
        }}
      />

      <EnterRow
        divider
        label={t('app.page.setting.systemInfo.system')}
        value={systemInfo}
      />

      <EnterRow
        divider
        label={t('app.page.setting.systemInfo.gcpLogId')}
        value={logId}
      />
    </>
  );
}
