import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import { getTableName } from '@/libs/order';
import { useHistory } from '@/libs/reactRouter';
import AlertDialog from '@/components/AlertDialog';
import FooterOrderButtons from '@/components/FooterOrderButtons';
import NavBar from '@/components/NavBar';
import themeStyle from '@/theme/themeStyle';

import CategoryList from './CategoryList';
import ListWithBatchCart from '@/components/ListWithBatchCart';

const OrderCategories = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const items = useSelector(state => state.order.workingBatch.items);
  const workingOrder = useSelector(state => state.order.workingOrder);
  const [showDiscardAlert, setShowDiscardAlert] = useState(false);

  const handleDiscardOrder = () => {
    dispatch(actions.order.discardOrder());
    dispatch(actions.order.clearSelection());
    dispatch(actions.order.resetInvalidItems());
    history.push('/tables');
  };

  return (
    <View style={styles.container}>
      <NavBar
        title={getTableName(workingOrder)}
        backButton
        searchButton
        onBack={() => {
          if (items.length > 0) {
            setShowDiscardAlert(true);
          } else {
            handleDiscardOrder();
          }
        }}
      />

      <View style={styles.contentContainer}>
        <ListWithBatchCart>
          <CategoryList />
        </ListWithBatchCart>

        <FooterOrderButtons />
      </View>

      <AlertDialog
        visible={showDiscardAlert}
        title={t('app.page.order.alert.title')}
        message={t('app.page.order.alert.msg')}
        confirmText={t('app.page.order.alert.confirm')}
        onConfirm={handleDiscardOrder}
        onClose={() => setShowDiscardAlert(false)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    paddingHorizontal: themeStyle.spacing.app.horizontal,
    paddingVertical: themeStyle.spacing.app.vertical,
  },
});

export default OrderCategories;
