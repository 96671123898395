import { StyleSheet } from 'react-native';
import { useHistory } from '@/libs/reactRouter';
import IconButton from '@/components/IconButton';
import React from 'react';

const styles = StyleSheet.create({
  icon: {
    height: 32,
    width: 32,
  },
});

export default function OrderHistoryButton (props) {
  const history = useHistory();

  const onPress = () => {
    history.push('/orderhistory/myorders');
  };

  return (
    <IconButton iconSource={require('@icons/navbar/history.png')} iconStyle={styles.icon} onPress={onPress} />
  );
}
