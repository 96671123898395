import { useDispatch } from 'react-redux';
import { useEffect, useRef } from 'react';

import { actions } from '@/redux';

export default function useLongRunningTimers() {
  const dispatch = useDispatch();
  const timerIds = useRef([]);

  useEffect(() => {
    const ids = timerIds.current;

    const notificationTimerId = setInterval(() => {
      dispatch(actions.notifications.prune());
    }, 60000);
    const pollOrdersTimerId = setInterval(() => {
      dispatch(actions.orderHistory.pollForOrders());
    }, 10000);

    ids.push(
      notificationTimerId,
      pollOrdersTimerId,
    );

    return () => {
      ids.forEach(clearInterval);
    };
  }, [dispatch]);
}
