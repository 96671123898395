import { useDispatch as reactReduxUseDispatch, useSelector as reactReduxUseSelect } from 'react-redux';
import _ from 'lodash';

import reduxActions from './actions';
import reduxSelectors from './selectors';

export const actions = reduxActions;
export const selectors = reduxSelectors;
export const useDispatch = reactReduxUseDispatch;
export const useSelector = (selector, equalityFn = _.isEqual) => {
  return reactReduxUseSelect(selector, equalityFn);
};
