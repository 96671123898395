import { FlatList, StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import { getMenuPriceText, isWithinSpecifiedTimeRange, isWithinSpecifiedWeekdays } from '@/libs/order';
import { listNumColumns } from '@/constants/app';
import { padToMatchMultiple } from '@/libs/array';
import { useHistory } from '@/libs/reactRouter';
import FooterSetOrderButtons from '@/components/FooterSetOrderButtons';
import ItemBox from '@root/src/components/ItemBox';
import ListWithBatchCart from '@/components/ListWithBatchCart';
import NavBar from '@/components/NavBar';
import themeStyle from '@/theme/themeStyle';

const OrderSets = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const sets = useSelector(state => state.menu.categories.filter(cat => {
    return [
      cat.type === 'SET',
      !cat.takeawayOnly,
      !cat.disabled,
      isWithinSpecifiedWeekdays(cat.weekdays),
      isWithinSpecifiedTimeRange(cat.timerange),
    ].every(Boolean);
  }));
  const rawSets = useSelector(state => state.menu.sets);

  const handleBack = () => {
    history.push('/order-categories');
  };

  return (
    <View style={{ flex: 1 }}>
      <NavBar
        title={t('app.page.orderSets.set')}
        backButton
        searchButton
        onBack={handleBack}
      />

      <View style={styles.contentContainer}>
        <ListWithBatchCart>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={padToMatchMultiple(sets, listNumColumns)}
            numColumns={listNumColumns}
            initialNumToRender={4}
            maxToRenderPerBatch={1}
            renderItem={({ item: set }) => {
              const subsets = rawSets.filter(s => s.categoryId === set.id);

              if (set.categories) {
                subsets.push(...set.categories);
              }

              const handlePress = () => {
                if (subsets.length === 1) {
                  const subset = subsets[0];

                  dispatch(actions.order.addSelection({
                    setId: set.id,
                    subsetId: subset.id,
                  }));
                  dispatch(actions.order.createLocalItem(subset));
                  history.push('/order/sets/subsets/steps');
                } else {
                  dispatch(actions.order.addSelection({ setId: set.id }));
                  history.push('/order/sets/subsets');
                }
              };

              return (
                <ItemBox
                  key={set.id}
                  height={themeStyle.box.height.normal}
                  item={set}
                  isSoldOut={set.sold}
                  disabled={set.sold}
                  caption={subsets.length === 1 ? getMenuPriceText(subsets[0]) : ''}
                  onPress={handlePress}
                />
              );
            }}
          />
        </ListWithBatchCart>

        <FooterSetOrderButtons doneButtonOnPress={handleBack} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    paddingHorizontal: themeStyle.spacing.app.horizontal,
    paddingVertical: themeStyle.spacing.app.vertical,
  },
});

export default OrderSets;
