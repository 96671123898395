import * as app from './app/actions';
import * as auth from './auth/actions';
import * as codePush from './codePush/actions';
import * as menu from './menu/actions';
import * as merchant from './merchant/actions';
import * as notifications from './notifications/actions';
import * as order from './order/actions';
import * as orderHistory from './orderHistory/actions';
import * as setting from './setting/actions';

export default {
  app,
  menu,
  merchant,
  order,
  orderHistory,
  setting,
  notifications,
  codePush,
  auth,
};
