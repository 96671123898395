import { Image, StyleSheet, TextInput, View } from 'react-native';
import { actions, useDispatch, useSelector } from '@/redux';
import { useTranslation } from 'react-i18next';
import IconButton from '../../IconButton';
import React, { useEffect, useState } from 'react';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  container: {
    height: 36,
    borderRadius: 14,
    backgroundColor: colors.white,
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 7,
    paddingHorizontal: 12,
  },
  icon: {
    width: 20,
    height: 20,
    opacity: 0.4,
  },
});

export default function SearchBar (props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { searchText } = useSelector(state => state.menu);

  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <Image
          style={styles.icon}
          source={require('@icons/navbar/search.png')}
        />
      </View>
      <View style={{ flex: 10 }}>
        <TextInput
          autoCapitalize='none'
          placeholder={t('app.common.search')}
          onChangeText={(text) => {
            dispatch(actions.menu.updateSearchText(text));
          }}
          onSubmitEditing={() => {
            if (searchText) {
              dispatch(actions.menu.updateSearchHistory(searchText));
            }
          }}
          onBlur={() => {
            if (searchText) {
              dispatch(actions.menu.updateSearchHistory(searchText));
            }
          }}
          style={{ backgroundColor: colors.transparent }}
        />
      </View>
    </View>
  );
}
