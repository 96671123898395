import { StyleSheet, Text, View } from 'react-native';
import React from 'react';

import { useTranslation } from 'react-i18next';
import Button from '@/components/Button';
import colors from '@/theme/colors';

const FooterDoneButton = ({ disabled, onPress }) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Button
        disabled={disabled}
        style={[styles.button, styles.doneBtn]}
        onPress={onPress}
      >
        <Text size={18} style={styles.text}>
          {t('app.common.done')}
        </Text>
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 12,
    marginVertical: 8,
  },
  button: {
    height: 50,
    borderRadius: 8,
  },
  doneBtn: {
    backgroundColor: colors.button.main,
  },
  text: {
    fontWeight: 'bold',
  },
});

export default FooterDoneButton;
