import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { actions, useDispatch } from '@/redux';
import OptionBox from '@/components/OptionBox';
import Row from '@/components/Layouts/Row';
import Text from '@/components/Text';
import colors from '@root/src/theme/colors';

const optionTypeKey = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
};

const OptionSection = ({ options, workingOptions }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return options.map(option => {
    const type = option.multiple && option.max > 1 ? optionTypeKey.MULTIPLE : optionTypeKey.SINGLE;
    const quantityText = () => {
      if (option.multiple && option.max > 1) {
        if (option.max > 1) {
          if (option.min > 0) {
            if (option.min === option.max) {
              return `${t('app.page.order.options.must')} ${option.min}`;
            }
            return `${t('app.page.order.options.must')} ${option.min} - ${option.max}`;
          }
          return `${t('app.page.order.options.optional')} ${option.max}`;
        }
        return t('app.page.order.options.optional');
      } else {
        if (option.min > 0) {
          return `${t('app.page.order.options.must')} 1`;
        }
        return `${t('app.page.order.options.optional')} 1`;
      }
    };
    return (
      <View key={option.id}>
        <Row style={styles.titleContainer}>
          <Text style={[
            styles.optionTitle,
            {
              color: option.min > 0
                ? 'red'
                : colors.black + '60',
            },
          ]}
          >
            {`${option.name} [${quantityText()}]`}
          </Text>
          <TouchableOpacity onPress={() => { dispatch(actions.order.resetOptionGroup(option)); }}>
            <Text style={styles.titleButton}>{t('app.common.reset')}</Text>
          </TouchableOpacity>
        </Row>

        <View style={styles.itemGrid}>
          {option.options.filter(opt => !opt.hidden).map(opt => {
            const selected = workingOptions.find(o => o.optionItemId === opt.id);
            const isMultiple = option.multiple && opt.max > 1;
            const newQuantity = selected && isMultiple ? selected.quantity + 1 : 1;
            return (
              <OptionBox
                key={opt.id}
                type={type}
                data={opt}
                selected={selected}
                quantity={selected?.quantity}
                onPress={() => {
                  dispatch(actions.order.updateItemOptions(option, opt, newQuantity));
                }}
                onMinus={() => {
                  if (selected) {
                    dispatch(actions.order.updateItemOptions(option, opt, selected?.quantity - 1));
                  }
                }}
              />
            );
          })}
        </View>
      </View>
    );
  });
};

const styles = StyleSheet.create({
  itemGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: 10,
  },
  optionTitle: {
    flex: 1,
    fontWeight: 'bold',
  },
  titleContainer: {
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
  titleButton: {
    borderColor: colors.primary.dark,
    borderBottomWidth: 1.5,
    fontWeight: 'bold',
    color: colors.primary.dark,
    marginLeft: 20,
  },
});

export default OptionSection;
