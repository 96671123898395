import { Dimensions, StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import CenterModal from '../Modals/CenterModal';
import Modal from '@/components/EnhancedModal';
import React from 'react';
import Text from '../Text';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  title: {
    marginBottom: 22,
    marginTop: 10,
    fontWeight: 'bold',
    maxWidth: '80%',
  },
  msg: {
    color: colors.black + '80',
    marginBottom: 53,
  },
  footerBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  confirmButton: {
    borderColor: colors.button.main,
    borderWidth: 2,
    backgroundColor: colors.button.main,
    height: 41,
    borderRadius: 10,
    width: (Dimensions.get('window').width * 0.8 - 60) / 2,
  },
  cancelButton: {
    borderColor: colors.button.main,
    borderWidth: 2,
    backgroundColor: colors.white,
    width: (Dimensions.get('window').width * 0.8 - 60) / 2,
    height: 41,
    borderRadius: 10,
  },
  buttonText: {
    color: colors.black,
    fontWeight: 'bold',
  },
  contentContainer: {
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default function AlertDialog (props) {
  const { t } = useTranslation();
  const {
    title,
    message,
    children,
    contentContainerStyle,
    hasCancelButton = true,
    onConfirm,
    cancelText = t('app.common.cancel'),
    confirmText = t('app.common.confirm'),
    visible,
    onClose = () => {},
  } = props;

  return (
    <CenterModal
      visible={visible}
      enablePressOutsideClose
      contentContainerStyle={styles.container}
      onClose={onClose}
      noCloseButton
      noTitle
    >
      <View style={[styles.contentContainer, contentContainerStyle]}>
        {title && <Text size={24} style={styles.title}>{title}</Text>}
        {<Text size={18} style={styles.msg}>{message}</Text> || children}
        <View style={styles.footerBtn}>
          {hasCancelButton && (
            <View style={{ marginHorizontal: 5 }}>
              <Button
                style={styles.cancelButton}
                onPress={onClose}
              >
                <Text size={16} style={styles.buttonText}>{cancelText}</Text>
              </Button>
            </View>
          )}
          <View style={{ marginHorizontal: 5 }}>
            <Button
              style={styles.confirmButton}
              onPress={onConfirm || onClose}
            >
              <Text size={16} style={styles.buttonText}>{confirmText}</Text>
            </Button>
          </View>
        </View>
      </View>
    </CenterModal>
  );
}
