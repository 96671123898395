import { StyleSheet, View } from 'react-native';
import React from 'react';

import colors from '@/theme/colors';

const Row = ({ icon, children, divider, style }) => {
  return (
    <View
      style={[
        styles.container,
        { borderBottomColor: colors.divider, borderBottomWidth: divider ? 1 : 0 },
        style,
      ]}
    >
      <View style={{ flex: 1 }}>
        {icon}
      </View>

      <View style={{ flex: 9, flexDirection: 'row' }}>
        {children}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    padding: 12,
  },
});

export default Row;
