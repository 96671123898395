import { StyleSheet, View } from 'react-native';
import React from 'react';

import colors from '@/theme/colors';
import shadows from '@/theme/shadows';

import Header from './Header';
import ItemList from './ItemList';
import SelectOrderButton from '../../Buttons/SelectOrderButton';

const OrderCard = ({ order }) => {
  return (
    <View
      style={{
        marginBottom: 20,
        borderRadius: 8,
        backgroundColor: colors.white,
        paddingTop: 12,
        paddingBottom: 20,
        ...shadows.default,
      }}
      testID='order-card'
    >
      <Header order={order} />
      <ItemList order={order} />
      <SelectOrderButton orderId={order.id} />
    </View>
  );
};

const styles = StyleSheet.create({});

export default OrderCard;
