import { FlatList, StyleSheet, TouchableOpacity, View } from 'react-native';
import { actions, selectors, useDispatch, useSelector } from '@/redux';
import { navMinHeight } from '@/constants/app';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Modal from '@/components/Modals/EnhancedModal';
import React, { useEffect } from 'react';
import Text from '../Text';
import colors from '@/theme/colors';
import shadows from '@/theme/shadows';

const styles = StyleSheet.create({
  centeredView: {
    ...StyleSheet.absoluteFill,
    margin: 0,
    alignItems: 'center',
  },
  contentContainer: {
    padding: 5,
    borderRadius: 10,
    backgroundColor: colors.white,
    position: 'absolute',
    overflow: 'visible',
    ...shadows.slight,
    alignItems: 'center',
  },
  menuButton: {
    height: 50,
    width: 150,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    margin: 5,
  },
  pointer: {
    backgroundColor: colors.white,
    height: 20,
    width: 20,
    position: 'absolute',
    transform: [
      { rotate: '45deg' },
    ],
    ...shadows.slight,
  },
});

export default function TableGroupList ({ visible, onClose, setSelectedTableGroup }) {
  const tableGroups = useSelector(selectors.merchant.selectTableGroups);
  const dispatch = useDispatch();
  const { selectedTableGroupIndex } = useSelector(state => state.merchant);
  const insets = useSafeAreaInsets();
  const navHeight = navMinHeight + insets.top;

  useEffect(() => {
    if (tableGroups.length) {
      setSelectedTableGroup(tableGroups[selectedTableGroupIndex]?.name);
    }
  }, [tableGroups, selectedTableGroupIndex, setSelectedTableGroup]);

  const renderItem = ({ item: tableGroup, index }) => {
    return (
      <TouchableOpacity
        onPress={() => {
          dispatch(actions.merchant.selectTableGroup(index));
          onClose();
        }}
      >
        <View
          style={[
            styles.menuButton,
            {
              backgroundColor: index === selectedTableGroupIndex
                ? colors.primary.main
                : colors.white,
            },
          ]}
        >
          <Text size={16} style={{ fontWeight: 'bold' }}>{tableGroup.name}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <Modal
      transparent
      propagateSwipe
      visible={visible}
      style={styles.centeredView}
      onRequestClose={onClose}
      supportedOrientations={['portrait']}
      onBackdropPress={onClose}
      backdropColor='transparent'
    >
      <View style={[styles.pointer, { top: navHeight + 8 }]} />
      <View style={[
        styles.contentContainer,
        {
          top: navHeight + 18,
          display: tableGroups ? 'flex' : 'none',
          maxHeight: 400,
        },
      ]}
      >
        <FlatList
          showsVerticalScrollIndicator={false}
          data={tableGroups}
          renderItem={renderItem}
          keyExtractor={(_v, index) => index}
        />
      </View>
    </Modal>
  );
}
