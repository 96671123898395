import { FlatList } from 'react-native';
import React from 'react';

import { padToMatchMultiple } from '@/libs/array';

import TableItem from './TableItem';

const numColumns = 3;

export default function TableList ({ tables }) {
  const renderItem = ({ item }) => {
    return (
      <TableItem table={item} />
    );
  };

  if (!tables?.length) {
    return null;
  }

  return (
    <FlatList
      showsVerticalScrollIndicator={false}
      data={padToMatchMultiple(tables, numColumns)}
      renderItem={renderItem}
      numColumns={numColumns}
      keyExtractor={(_v, index) => index}
      bounces={false}
    />
  );
}
