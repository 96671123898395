import { StyleSheet, View } from 'react-native';
import { actions, useDispatch, useSelector } from '@/redux';
import { fontSizeKey, orderModes, settingKey } from '@/constants/app';
import { useTranslation } from 'react-i18next';
import EnterRow from './component/SettingRow/EnterRow';
import React, { useEffect, useState } from 'react';
import SwitchRow from './component/SettingRow/SwitchRow';
import Text from '@/components/Text';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  titleContainer: {
    flex: 1,
    minHeight: 30,
    justifyContent: 'center',
    paddingLeft: 12,
  },
  title: {
    fontWeight: 'bold',
    color: colors.black + '60',
  },
});

export default function MainSettingList (props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { lang, fontSize, menuLang } = useSelector(state => state.setting.systemSettings);
  const { batchLocaleOptions } = useSelector(state => state.merchant.data.setting);
  const { orderMode } = useSelector(state => state.setting);
  const [isQuickMode, setIsQuickMode] = useState(orderMode === orderModes.ITEM_FIRST);

  useEffect(() => {
    if (isQuickMode) {
      dispatch(actions.setting.changeOrderMode(orderModes.ITEM_FIRST));
    } else {
      dispatch(actions.setting.changeOrderMode(orderModes.OPTION_FIRST));
    }
  }, [dispatch, isQuickMode]);

  const deviceLocalesOptions = [
    { key: 'zh-HK', text: '繁體中文' },
    { key: 'en-US', text: 'English' },
  ];

  const fontSizeOptions = [
    { key: fontSizeKey.LARGE, text: t('app.page.setting.mainSetting.large') },
    { key: fontSizeKey.MIDDLE, text: t('app.page.setting.mainSetting.middle') },
    { key: fontSizeKey.SMALL, text: t('app.page.setting.mainSetting.small') },
  ];

  const getLocalesOptionText = (key) => {
    switch (key) {
      case 'kitchen': {
        return t('app.page.setting.mainSetting.kitchenLanguage');
      }

      case 'zh': {
        return '中文';
      }

      case 'en': {
        return 'English';
      }

      case 'korean': {
        return 'Korean';
      }

      case 'thai': {
        return 'Thai';
      }

      // need update when more kitchen locale is available

      default: {
        return key;
      }
    }
  };

  const menuLocalesOptions = batchLocaleOptions.map(option => {
    return {
      key: option,
      text: getLocalesOptionText(option),
    };
  });

  return (
    <>
      <View style={styles.titleContainer}>
        <Text size={14} style={styles.title}>{t('app.page.setting.mainSetting.info')}</Text>
      </View>

      <EnterRow
        divider
        label={t('app.page.setting.mainSetting.systemInfo')}
        iconSrc={require('@icons/common/right-arrow.png')}
        iconStyle={{ height: 20, width: 20 }}
        onPress={() => {
          dispatch(actions.setting.updateSettingPath(settingKey.SYSTEM_INFO));
        }}
      />

      <EnterRow
        divider
        label={t('app.page.setting.mainSetting.userInfo')}
        iconStyle={{ height: 20, width: 20 }}
        iconSrc={require('@icons/common/right-arrow.png')}
        onPress={() => {
          dispatch(actions.setting.updateSettingPath(settingKey.USER_INFO));
        }}
      />

      <View style={styles.titleContainer}>
        <Text size={14} style={styles.title}>{t('app.page.setting.mainSetting.preference')}</Text>
      </View>

      <EnterRow
        divider
        label='語言 / Language'
        value={deviceLocalesOptions.find(o => o.key === lang)?.text || lang}
        onPress={() => {
          dispatch(actions.setting.showSettingOptions({
            onChangeAction: (value) => actions.setting.changeLang(value),
            options: deviceLocalesOptions,
            closeOnChange: true,
          }));
        }}
      />

      <EnterRow
        divider
        label={t('app.page.setting.mainSetting.menuLanguage')}
        value={menuLocalesOptions.find(o => o.key === menuLang)?.text || menuLang}
        onPress={() => {
          dispatch(actions.setting.showSettingOptions({
            onChangeAction: (value) => actions.setting.changeMenuLang(value),
            options: menuLocalesOptions,
            closeOnChange: true,
          }));
        }}
      />

      <EnterRow
        divider
        label={t('app.page.setting.mainSetting.fontSize')}
        value={fontSizeOptions.find(o => o.key === fontSize)?.text || fontSize}
        onPress={() => {
          dispatch(actions.setting.showSettingOptions({
            onChangeAction: (value) => actions.setting.changeFontSize(value),
            options: fontSizeOptions,
            closeOnChange: true,
          }));
        }}
      />

      <SwitchRow
        label={t('app.page.setting.mainSetting.quickMode')}
        desc={t('app.page.setting.mainSetting.quickModeDesc')}
        value={isQuickMode}
        onChangeValue={setIsQuickMode}
      />
    </>
  );
}
