import { Image, KeyboardAvoidingView, Platform, StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import React from 'react';

import Modal from '@/components/Modals/EnhancedModal';
import Text from '@/components/Text';
import colors from '@/theme/colors';
import shadows from '@/theme/shadows';

/**
 * @param {IModalProps} props
 */
export default function CenterModal (props) {
  const {
    title,
    noBackdrop,
    noCloseButton,
    noTitle,
    enablePressOutsideClose,
    visible,
    onClose = () => { },
    onPressOutside = () => { },
    contentContainerStyle,
    children,
    containerStyle,
  } = props;
  return (
    <Modal
      // animationType='fade'
      transparent
      propagateSwipe
      visible={visible}
      style={styles.centeredView}
      onRequestClose={onClose}
      supportedOrientations={['portrait']}
    >
      <TouchableWithoutFeedback
        onPress={!enablePressOutsideClose ? onPressOutside : onClose}
      >
        <View style={[styles.container, !noBackdrop && styles.backdrop]}>
          <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : undefined} style={styles.container}>
            <TouchableWithoutFeedback style={styles.container}>
              <View style={[styles.modalView, containerStyle]}>
                {!noTitle &&
                  <View style={styles.header}>
                    {!noCloseButton && (
                      <TouchableWithoutFeedback onPress={onClose}>
                        <View style={styles.closeButtonView}>
                          <Image style={styles.closeIcon} source={require('@icons/close.png')} />
                        </View>
                      </TouchableWithoutFeedback>
                    )}
                    {title && (
                      <Text size={18} style={[styles.title, noCloseButton && { marginLeft: 14 }]}>{title}</Text>
                    )}
                  </View>}
                <View style={[styles.content, contentContainerStyle]}>
                  {children}
                </View>
              </View>
            </TouchableWithoutFeedback>
          </KeyboardAvoidingView>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    ...StyleSheet.absoluteFill,
    margin: 0,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  backdrop: {
    backgroundColor: colors.black + '80',
  },
  modalView: {
    maxHeight: '100%',
    margin: 15,
    borderRadius: 8,
    backgroundColor: colors.white,
    overflow: 'hidden',
    ...shadows.default,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: colors.primary.main,
    minHeight: 42,
  },
  closeButtonView: {
    padding: 14,
  },
  closeIcon: {
    height: 14,
    width: 14,
  },
  title: {
    fontWeight: 'bold',
    color: colors.white,
  },
  content: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
  },
});
