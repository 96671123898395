import waiterApi from './api/waiter';

/**
 *
 * @param {ScopeCredential[]} scopeCredentials
 * @param {CurrentUser} currentUser
 * @param {string} requiredPermission
 * @returns {boolean}
 */
export const checkPermission = (scopeCredentials, currentUser, requiredPermission) => {
  if (!currentUser) {
    return false;
  }

  const { permissions } = currentUser;

  if (permissions.includes('admin') || permissions.includes(requiredPermission)) {
    return true;
  }

  const noOneHasPermission = !scopeCredentials.find(s => s.scopes.includes(requiredPermission));

  // if no one has the required permission, then everyone has the permission to prevent deadlock
  return noOneHasPermission;
};

/**
 *
 * @param {string} merchantName - e.g. phd
 * @param {string} username - e.g. waiter
 * @param {string} password - e.g. 111111
 * @returns {Promise<CurrentUser>}
 */
export const verifyCredentials = (merchantName, username, password) => {
  return waiterApi.scopeLoginWithMerchantAccount(username, password, merchantName);
};
