import { StyleSheet, TouchableOpacity } from 'react-native';
import React from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import { getItemAttributes } from '@/libs/order';
import { useHistory, useLocation } from '@/libs/reactRouter';
import colors from '@/theme/colors';

import Attributes from './Sections/Attributes';
import Body from './Sections/Body';
import Icon from './Sections/Icon';
import Price from './Sections/Price';
import Quantity from './Sections/Quantity';
import Row from './Layout/Row';

const Item = ({ item, editable, isItemInCurrentBatch }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const hasError = useSelector(state => Boolean(state.order.invalidItemIdMap[item.id]?.length));

  const itemAttributes = getItemAttributes(item);

  const onEdit = () => {
    dispatch(actions.order.stash());
    dispatch(actions.order.addSelection({
      categoryId: item.categoryId,
      menuId: item.menuId,
    }));
    dispatch(actions.order.updateWorkingItem(item));
    dispatch(actions.order.enterEditMode(location.pathname));
    history.push('/order/menus/options');
  };

  return (
    <TouchableOpacity
      disabled={!editable}
      style={{
        borderBottomColor: colors.divider,
        borderBottomWidth: 1,
        backgroundColor: hasError
          ? colors.batch.inComplete
          : colors.white,
      }}
      onPress={onEdit}
    >
      <Row
        icon={
          <Icon
            item={item}
            isItemInCurrentBatch={isItemInCurrentBatch}
            onDelete={() => {
              dispatch(actions.order.deleteItemFromBatch(item));
            }}
          />
        }
        divider={Boolean(itemAttributes)}
      >
        <Body item={item} />
        <Quantity item={item} />
        <Price item={item} />
      </Row>

      <Attributes attributes={getItemAttributes(item)} indent={1} muted={item.cancelled} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({});

export default React.memo(Item);
