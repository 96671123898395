import { StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { useEffect, useState } from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import { getTableName, status } from '@/libs/order';
import { useHistory } from '@/libs/reactRouter';
import { useTranslation } from 'react-i18next';
import AlertDialog from '@root/src/components/AlertDialog';
import Text from '@/components/Text';
import colors from '@/theme/colors';

const height = 100;

export default function TableItem (props) {
  const {
    seatTime,
    table,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedOrder = useSelector(state => state.orderHistory.orderToTransfer);
  const pendingOrders = useSelector(state => state.orderHistory.orders.filter(o => o.status === status.PENDING));
  const pendingOrdersInTable = pendingOrders.find(o => o.table === table);
  const batchesInPendingOrders = pendingOrders.find(o => (o.table === table && o.batches.length > 0));

  const [statusColor, setStatusColor] = useState(colors.tableStatus.default);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (!pendingOrdersInTable) {
      setStatusColor(colors.tableStatus.default);
      return;
    }

    if (batchesInPendingOrders) {
      setStatusColor(colors.tableStatus.ordered);
    } else {
      setStatusColor(colors.tableStatus.pending);
    }
  }, [pendingOrdersInTable, batchesInPendingOrders]);

  if (table.isFiller) {
    return (
      <View style={[styles.container, styles.filler]} />
    );
  }

  const onConfirm = () => {
    dispatch(actions.order.transferOrder(selectedOrder, table));
    dispatch(actions.order.removeSelection());
    setShowAlert(false);
    history.push('/tables');
  };

  return (
    <>
      <TouchableOpacity
        style={[
          styles.container,
          {
            backgroundColor: statusColor,
          },
        ]}
        testID='table-item'
        onPress={async () => {
          setShowAlert(true);
        }}
      >
        <View style={styles.bottomInfo}>
          <Text size={18} style={styles.table}>{table}</Text>
        </View>
      </TouchableOpacity>
      <AlertDialog
        visible={showAlert}
        title={t('app.page.transferTable.alert.title')}
        message={
          t('app.page.transferTable.alert.msg',
            {
              fromTable: getTableName(selectedOrder),
              toTable: table,
            },
          )
        }
        confirmText={t('app.common.confirm')}
        onConfirm={onConfirm}
        onClose={() => setShowAlert(false)}
      />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    margin: 4,
    padding: 6,
    height,
    flex: 1,
  },
  filler: {
    backgroundColor: 'transparent',
  },
  extraInfo: {
    justifyContent: 'flex-end',
  },
  timeText: {
    textAlign: 'right',
    fontWeight: 'bold',
    color: colors.white,
  },
  timeUnit: {
    textAlign: 'right',
    fontWeight: 'bold',
    color: colors.white,
  },
  bottomInfo: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  table: {
    color: colors.white,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
