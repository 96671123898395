import moment from 'moment';
import produce from 'immer';

import { status } from '@/libs/order';

import ActionTypes from './ActionTypes';

/** @type {INotificationsState} */
export const initialState = {
  kitchenCompleted: {
    read: {},
    unread: {},
  },
};

const updateKitchenCompleted = (draft, item) => {
  const key = item.key;

  if (!item.kitchenCompletedAt || item.cancelled) {
    delete draft.kitchenCompleted.read[key];
    delete draft.kitchenCompleted.unread[key];
    return;
  }

  if (!draft.kitchenCompleted.read[key]) {
    draft.kitchenCompleted.unread[key] = true;
  }
};

const updateKitchenCompletedByOrder = (draft, payload) => {
  const { order, currentUsername } = payload;

  if (order.status !== status.PENDING) {
    return;
  }

  order.batches.forEach((b) => {
    if (b.identifier !== currentUsername) {
      return;
    }

    b.items.forEach(item => {
      if (item.isSet) {
        if (item.cancelled) {
          return;
        }

        item.setItems.forEach(setItem => {
          updateKitchenCompleted(draft, setItem);
        });
      } else {
        updateKitchenCompleted(draft, item);
      }
    });
  });
};

export default produce(
  /**
   * @param {INotificationsState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action.type) {
      case ActionTypes.ORDER_RECEIVED: {
        updateKitchenCompletedByOrder(draft, action.payload);
        break;
      }

      case ActionTypes.INITIAL_ORDERS_RECEIVED: {
        const { orders, currentUsername } = action.payload;

        orders.forEach(order => {
          updateKitchenCompletedByOrder(draft, { order, currentUsername });
        });

        break;
      }

      case ActionTypes.READ_KITCHEN_COMPLETED_MESSAGES: {
        const { item, readAt } = action.payload;

        draft.kitchenCompleted.read[item.id] = readAt;
        delete draft.kitchenCompleted.unread[item.id];

        break;
      }

      case ActionTypes.PRUNE: {
        const dayInMs = 60 * 60 * 24 * 1000;
        const now = moment(action.payload.time);
        const orders = action.payload.orders;

        // prune read, remove after 1 day
        Object.keys(draft.kitchenCompleted.read).forEach(id => {
          const readTime = moment(draft.kitchenCompleted.read[id]);

          if (now.diff(readTime) > dayInMs) {
            delete draft.kitchenCompleted.read[id];
          }
        });

        // prune unread, remove if the order is not pending, or the item is cancelled
        const itemIdMap = orders
          .filter(o => o.status === status.PENDING)
          .reduce((acc, o) => {
            const idMap = {};

            o.batches.forEach(b => {
              b.items.forEach(item => {
                if (item.cancelled) {
                  return;
                }

                if (item.isSet) {
                  item.setItems.forEach(setItem => {
                    if (setItem.cancelled) {
                      return;
                    }

                    setItem.itemIds.forEach(id => {
                      idMap[id] = true;
                    });
                  });
                } else {
                  item.itemIds.forEach(id => {
                    idMap[id] = true;
                  });
                }
              });
            });

            return {
              ...acc,
              ...idMap,
            };
          }, {});

        Object.keys(draft.kitchenCompleted.unread).forEach(id => {
          if (!itemIdMap[id]) {
            delete draft.kitchenCompleted.unread[id];
          }
        });

        break;
      }

      default:
        return draft;
    }
  },
  initialState,
);
