import ActionTypes from './ActionTypes';

export const updateMenuList = data => {
  return {
    type: ActionTypes.UPDATE_MENU_LIST,
    payload: {
      categories: data.categories,
      menus: data.menus,
      optionGroupPresets: data.optionGroupPresets,
      sets: data.sets,
    },
  };
};

export const updateSearchHistory = searchText => {
  return {
    type: ActionTypes.UPDATE_SEARCH_HISTORY,
    payload: searchText,
  };
};

export const updateSearchText = searchText => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_SEARCH_TEXT,
      payload: searchText,
    });
  };
};

export const resetSearchText = () => {
  return {
    type: ActionTypes.RESET_SEARCH_TEXT,
  };
};
