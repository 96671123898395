import { TouchableOpacity, View } from 'react-native';
import React from 'react';

import Text from '@/components/Text';
import colors from '@/theme/colors';

const Item = ({
  table,
  title,
  desc,
  quantity,
  time,
  isRead,
  onPress,
}) => {
  const color = isRead ? colors.grey[500] : colors.black;

  return (
    <TouchableOpacity
      style={{
        flexDirection: 'row',
        backgroundColor: colors.grey[200],
        paddingHorizontal: 24,
        paddingVertical: 12,
      }}
      activeOpacity={0.5}
      disabled={isRead}
      onPress={onPress}
    >
      <View style={{ flex: 4 }}>
        <Text
          size={20}
          style={{
            fontWeight: 'bold',
            color,
          }}
        >
          {table}
        </Text>
        <Text size={20} style={{ color }}>
          {title}
        </Text>
        {desc
          ? (
            <Text style={{ color: colors.grey[500] }}>
              {desc}
            </Text>
            )
          : null}
      </View>

      <View style={{ flex: 1, alignItems: 'flex-end', justifyContent: 'space-between' }}>
        <Text style={{ color }}>
          {time}
        </Text>
        <Text size={20} style={{ color }}>
          x{quantity}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default Item;
