import { v4 as uuid } from 'uuid';

/**
 *
 * @param {any[]} arr
 * @param {number} multiple
 * @param {Object} [keys]
 * @param {string} keys.id
 * @param {string} keys.filler
 * @returns {any[]}
 */
const padToMatchMultiple = (
  arr,
  multiple,
  keys = { id: 'id', filler: 'isFiller' },
) => {
  const size = arr.length;
  const padSize = size % multiple === 0
    ? 0
    : multiple - (size % multiple);

  return [
    ...arr,
    ...Array(padSize)
      .fill()
      .map(() => ({
        [keys.id]: uuid(),
        [keys.filler]: true,
      })),
  ];
};

export { padToMatchMultiple };
