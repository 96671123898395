import OrderAppLib from 'dimorder-orderapp-lib';

import configs from '@/configs';
import logger from '@/libs/logger';

const dimorderLib = OrderAppLib.libs;

const env = 'prod';
const { apiUrls } = configs[env];
const dimorderApi = new OrderAppLib.api.Merchant(apiUrls.go, apiUrls.node);
const locale = 'zh';

dimorderApi.setLanguage(locale);
dimorderApi.axiosGo.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    logger.log('[axiosGo interceptor response]', { error });
  },
);

dimorderApi.axiosNode.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    logger.log('[axiosNode interceptor response]', { error });
  },
);

const setUrl = (env) => {
  logger.log('set dimorder api url', { prevEnv: env, env });

  if (!env || !dimorderApi) {
    return;
  }

  let urls = null;

  switch (env) {
    case 'beta': {
      urls = configs.beta.apiUrls;
      break;
    }

    case 'production':
    case 'prod': {
      urls = configs.prod.apiUrls;
      break;
    }

    default: {
      urls = configs.test.apiUrls;
      break;
    }
  }

  if (!urls) {
    return;
  }

  dimorderApi.setUrl(urls.go, urls.node);
};

export {
  dimorderLib,
  dimorderApi,
  setUrl,
};
