import { Image, StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { getItemAttributes, getStatusIconSource } from '@/libs/order';
import { numberWithCommas } from '@/libs/numberWithCommas';
import { selectors, useSelector } from '@/redux';
import Text from '@/components/Text';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  itemContainer: {
    flex: 1,
    paddingHorizontal: 12,
    paddingVertical: 11,
    flexDirection: 'row',
  },
  attributeContainer: {
    flex: 1,
    paddingHorizontal: 12,
    paddingVertical: 8,
    flexDirection: 'row',
  },
  contentContainer: {
    justifyContent: 'center',
  },
  icon: {
    width: 15,
    height: 15,
  },
  text: {
    fontWeight: 'bold',
  },
  attributeText: {
    flex: 9,
    paddingRight: 10,
    marginLeft: 5,
  },
});

const cancelledStyle = { color: colors.cancelled.text };

const ItemName = ({ item }) => {
  const { t } = useTranslation();
  const setName = useSelector(state => {
    return selectors.menu.selectMenuNameByLocale(state, item);
  });

  const textStyle = item.cancelled ? [styles.text, cancelledStyle] : styles.text;

  return (
    <Text style={textStyle} size={16}>
      <Text>{setName}</Text>
      {item.cancelled && <Text style={{ color: colors.cancelled.tag }}> {t('app.constant.menu.cancelled')}</Text>}
    </Text>
  );
};

export default function Item ({ item, divider }) {
  const { t } = useTranslation();

  const itemAttributes = getItemAttributes(item);
  const showDivider = divider || itemAttributes;

  const textStyle = item.cancelled ? [styles.text, cancelledStyle] : styles.text;
  const attrTextStyle = item.cancelled ? [styles.attributeText, cancelledStyle] : styles.attributeText;

  return (
    <View>
      <View
        style={[
          styles.itemContainer,
          {
            borderBottomWidth: showDivider ? 1 : 0,
            borderColor: colors.divider,
          },
        ]}
      >
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Image style={styles.icon} source={getStatusIconSource(item)} />
        </View>
        <View style={{ flex: 6, justifyContent: 'center' }}>
          <ItemName item={item} />
        </View>

        <View style={{ flex: 1, alignItems: 'flex-end', justifyContent: 'center' }}>
          <Text style={textStyle} size={18}>{item.quantity}</Text>
        </View>

        <View style={{ flex: 2, alignItems: 'flex-end', justifyContent: 'center' }}>
          <Text style={textStyle} size={16}>
            {item.priceUndetermined
              ? t('app.constant.menu.priceUndetermined')
              : `$${numberWithCommas(item.total)}`}
          </Text>
        </View>
      </View>

      <View
        style={[
          styles.attributeContainer,
          {
            height: itemAttributes ? null : 0,
            borderBottomWidth: divider && itemAttributes ? 1 : 0,
            borderColor: colors.divider,
          },
        ]}
      >
        <View style={{ flex: 1 }} />
        <Text size={16} style={attrTextStyle}>{itemAttributes}</Text>
      </View>
    </View>
  );
}
