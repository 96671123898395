import { Dimensions, StyleSheet, TouchableOpacity, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { actions, useDispatch, useSelector } from '@/redux';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import Drawer from '../Drawer';
import React, { useState } from 'react';
import Text from '../Text';
import TextInputBox from '../TextInputBox';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  textInputBox: {
    backgroundColor: colors.secondary.main,
  },
  container: {
    width: Dimensions.get('window').width,
    height: '65%',
  },
  text: {
    textAlign: 'left',
  },
  buttonContainer: {
    flex: 1,
  },
  button: {
    height: 58,
    borderRadius: 8,
    width: (Dimensions.get('window').width - 70) / 2,
    marginHorizontal: 10,
    borderWidth: 3,
  },
  cancelButton: {
    borderColor: colors.primary.main,
  },
  confirmButton: {
    borderColor: colors.button.main,
    backgroundColor: colors.button.main,
  },
  listContainer: {
    flex: 1,
    padding: 10,
    width: '100%',
    borderColor: colors.divider,
    alignItems: 'center',
  },
});

const customReasonKey = {
  DINE_IN: 'dineIn',
  TAKEAWAY: 'takeaway',
  ITEM: 'item',
};

const cancelReasonType = {
  invalid: 'invalid',
  trial: 'trial',
  rejected: 'rejected',
  others: 'others',
};

export default function CancelReasonDrawer (props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { open: isOpen, data } = useSelector(state => state.app.drawers.cancelReason);
  const { customReasons } = useSelector(state => state.merchant);
  const filteredCustomReasons = customReasons.map(customReason => {
    if ((!customReason.type || customReason.type === customReasonKey.DINE_IN) && customReason.enable) {
      return {
        value: customReason.name,
        display: customReason.name,
      };
    };
    return null;
  }).filter(o => Boolean(o));

  const cancelReasons = [
    { value: cancelReasonType.invalid, display: t('app.drawer.cancelReason.reason.invalid') },
    { value: cancelReasonType.trial, display: t('app.drawer.cancelReason.reason.trial') },
    { value: cancelReasonType.rejected, display: t('app.drawer.cancelReason.reason.rejected') },
    ...filteredCustomReasons,
    { value: cancelReasonType.others, display: t('app.drawer.cancelReason.reason.others') },
  ];
  const [selectedCancelReason, setSelectedCancelReason] = useState('');
  const [otherReason, setOtherReason] = useState();
  const submitDisabled = selectedCancelReason === cancelReasonType.others && !otherReason;

  const renderItem = ({ item, index }) => {
    return (
      <TouchableOpacity
        onPress={() => { setSelectedCancelReason(item.value); }}
      >
        <View style={[
          styles.listContainer,
          {
            backgroundColor: item.value === selectedCancelReason
              ? colors.primary.main
              : colors.white,
            borderBottomWidth: cancelReasons.length - 1 === index
              ? 0
              : 1,
          },
        ]}
        >
          <Text size={30} style={styles.listText}>{item.display}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  const onClose = () => {
    dispatch(actions.app.toggleDrawer('cancelReason', false, {}));
  };

  const onConfirm = () => {
    const reason = selectedCancelReason === cancelReasonType.others
      ? otherReason
      : selectedCancelReason;

    dispatch(actions.orderHistory.deleteOrder(data.orderId, reason, data.identifier));
    dispatch(actions.app.toggleDrawer('cancelReason', false, {}));
  };

  return (
    <Drawer
      visible={isOpen}
      containerStyle={styles.container}
      onClose={onClose}
      enablePressOutsideClose
    >
      <View style={{ height: '100%' }}>
        <Text size={20}>{t('app.drawer.cancelReason.title')}</Text>
        <View style={{ alignItems: 'center' }}>
          <FlatList
            data={cancelReasons}
            renderItem={renderItem}
            showsVerticalScrollIndicator={false}
            keyExtractor={(_v, index) => index}
            style={{ height: '55%', width: '100%', marginVertical: 20 }}
          />
          {selectedCancelReason === cancelReasonType.others && (
            <TextInputBox
              testID='other-cancel-reason-input'
              keyboardType='default'
              style={styles.textInputBox}
              placeholder={t('app.drawer.cancelReason.reason.othersPlaceholder')}
              placeholderTextColor={colors.text.placeholder}
              autoCapitalize='none'
              autoCorrect={false}
              value={otherReason}
              onChangeText={(text) => {
                setOtherReason(text);
              }}
            />
          )}
        </View>
        <View style={{ flex: 1, justifyContent: 'flex-end', paddingVertical: 20, alignItems: 'center' }}>
          <View style={{ flexDirection: 'row' }}>
            <Button
              style={[styles.button, styles.cancelButton]}
              onPress={onClose}
            >
              <Text size={16} style={styles.buttonText}>
                {t('app.common.cancel')}
              </Text>
            </Button>
            <Button
              style={[styles.button, styles.confirmButton]}
              disabled={submitDisabled}
              onPress={onConfirm}
            >
              <Text size={16} style={styles.buttonText}>
                {t('app.common.confirm')}
              </Text>
            </Button>
          </View>
        </View>
      </View>
    </Drawer>
  );
}
