import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { actions, useDispatch, useSelector } from '@/redux';
import React from 'react';
import Text from '@/components/Text';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingVertical: 12,
  },
  tag: {
    borderRadius: 20,
    borderColor: colors.button.main,
    borderWidth: 2,
    backgroundColor: colors.secondary.main,
    paddingHorizontal: 26,
    paddingVertical: 6,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 16,
    marginBottom: 12,
  },
  tagText: {
    color: colors.black + '50',
  },
});

export default function RecentSearchTags (props) {
  const searchHistory = useSelector(state => state.menu.searchHistory);
  const dispatch = useDispatch();

  const renderItem = (item, index) => {
    return (
      <TouchableOpacity key={index} onPress={() => { dispatch(actions.menu.updateSearchText(item)); }}>
        <View style={styles.tag}>
          <Text size={14} style={styles.tagText}>{item}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <ScrollView showsVerticalScrollIndicator={false} bounces={false}>
      <View style={styles.container}>
        {[...searchHistory].reverse().map((searchItem, index) => {
          return renderItem(searchItem, index);
        })}
      </View>
    </ScrollView>
  );
}
