import { Image, StyleSheet, View } from 'react-native';
import React from 'react';
import Text from '@/components/Text';
import colors from '@/theme/colors';

export default function SettingItemEnter (props) {
  const { style, text, subText, textStyle, subTextStyle, iconSrc, iconStyle } = props;
  return (
    <View style={[styles.container, style]}>
      {!!text && <Text size={16} style={[styles.text, textStyle]}>{text}</Text>}
      {!!subText && <Text size={12} style={[styles.subText, subTextStyle]}>{subText}</Text>}
      {!!iconSrc && <Image source={iconSrc} style={[styles.icon, iconStyle]} />}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  text: {
    color: colors.text.primary,
    lineHeight: 22,
    fontWeight: 'bold',
    textAlign: 'right',
  },
  subText: {
    color: colors.text.primary,
    lineHeight: 22,
    textAlign: 'right',
  },
  icon: {
    height: 24,
    width: 24,
    marginLeft: 10,
    opacity: 0.3,
  },
});
