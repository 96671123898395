import { Dimensions, KeyboardAvoidingView, StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import React from 'react';

import Modal from '@/components/Modals/EnhancedModal';
import colors from '@/theme/colors';
import shadows from '@/theme/shadows';

/**
 * @param {IModalProps} props
 */
export default function Drawer (props) {
  const {
    noBackdrop,
    enablePressOutsideClose,
    visible,
    onClose = () => { },
    onPressOutside = () => { },
    contentContainerStyle,
    children,
    containerStyle,
  } = props;
  return (
    <Modal
      transparent
      propagateSwipe
      visible={visible}
      style={styles.centeredView}
      onRequestClose={onClose}
      supportedOrientations={['portrait']}
    >
      <TouchableWithoutFeedback
        onPress={!enablePressOutsideClose ? onPressOutside : onClose}
      >
        <View style={[styles.container, !noBackdrop && styles.backdrop]}>
          <KeyboardAvoidingView behavior='padding' style={styles.container}>
            <TouchableWithoutFeedback style={[styles.container]}>
              <View style={[styles.modalView, containerStyle]}>
                <View style={[styles.content, contentContainerStyle]}>
                  {children}
                </View>
              </View>
            </TouchableWithoutFeedback>
          </KeyboardAvoidingView>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    ...StyleSheet.absoluteFill,
    margin: 0,
  },
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  backdrop: {
    backgroundColor: colors.black + '80',
  },
  modalView: {
    width: Dimensions.get('window').width,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: colors.white,
    overflow: 'hidden',
    ...shadows.default,
  },
  closeButtonView: {
    padding: 14,
  },
  content: {
    flexDirection: 'column',
    padding: 20,
  },
});
