export default {
  UPDATE_WORKING_ORDER: 'ORDER/UPDATE_WORKING_ORDER',
  UPDATE_WORKING_BATCH: 'ORDER/UPDATE_WORKING_BATCH',
  UPDATE_WORKING_ITEM: 'ORDER/UPDATE_WORKING_ITEM',
  UPDATE_WORKING_SET_ITEM: 'ORDER/UPDATE_WORKING_SET_ITEM',
  ADD_ORDER: 'ORDER/ADD_ORDER',
  ADD_ITEM_TO_BATCH: 'ORDER/ADD_ITEM_TO_BATCH',
  ADD_SET_ITEM_TO_ITEM: 'ORDER/ADD_SET_ITEM_TO_ITEM',
  DELETE_ITEM_FROM_BATCH: 'ORDER/DELETE_ITEM_FROM_BATCH',
  DELETE_SET_ITEM_FROM_BATCH: 'ORDER/DELETE_SET_ITEM_FROM_BATCH',
  RESET: 'ORDER/RESET',
  UPDATE_ITEM_QUANTITY: 'ORDER/UPDATE_ITEM_QUANTITY',
  UPDATE_SET_ITEM_QUANTITY: 'ORDER/UPDATE_SET_ITEM_QUANTITY',
  CONFIRM_ORDER: 'ORDER/CONFIRM_ORDER',
  DISCARD_ORDER: 'ORDER/DISCARD_ORDER',
  SET_CART_EXPANDED: 'ORDER/SET_CART_EXPANDED',
  SET_CART_DELETE_MODE: 'ORDER/SET_CART_DELETE_MODE',
  ADD_SELECTION: 'ORDER/ADD_SELECTION',
  REMOVE_SELECTION: 'ORDER/REMOVE_SELECTION',
  CLEAR_SELECTION: 'ORDER/CLEAR_SELECTION',
  STASH: 'ORDER/STASH',
  RECONCILE_STASH: 'ORDER/RECONCILE_STASH',
  UPDATE_WORKING_ITEM_TAGS: 'ORDER/UPDATE_WORKING_ITEM_TAGS',
  ADD_ITEM_OPTIONS: 'ORDER/ADD_ITEM_OPTIONS',
  DELETE_ITEM_OPTIONS: 'ORDER/DELETE_ITEM_OPTIONS',
  UPDATE_ITEM_OPTIONS: 'ORDER/UPDATE_ITEM_OPTIONS',
  REPLACE_ITEM_OPTIONS: 'ORDER/REPLACE_ITEM_OPTIONS',
  RESET_ITEM_OPTION_GROUP: 'ORDER/RESET_ITEM_OPTION_GROUP',
  ADD_SET_ITEM_OPTIONS: 'ORDER/ADD_SET_ITEM_OPTIONS',
  DELETE_SET_ITEM_OPTIONS: 'ORDER/DELETE_SET_ITEM_OPTIONS',
  UPDATE_SET_ITEM_OPTIONS: 'ORDER/UPDATE_SET_ITEM_OPTIONS',
  REPLACE_SET_ITEM_OPTIONS: 'ORDER/REPLACE_SET_ITEM_OPTIONS',
  RESET_SET_ITEM_OPTION_GROUP: 'ORDER/RESET_SET_ITEM_OPTION_GROUP',
  ADD_ITEM_TAG: 'ORDER/ADD_ITEM_TAG',
  DELETE_ITEM_TAG: 'ORDER/DELETE_ITEM_TAG',
  ADD_SET_ITEM_TAG: 'ORDER/ADD_SET_ITEM_TAG',
  DELETE_SET_ITEM_TAG: 'ORDER/DELETE_SET_ITEM_TAG',
  UPDATE_SET_ITEM_UNDETERMINED_PRICE: 'ORDER/UPDATE_SET_ITEM_UNDETERMINED_PRICE',
  UPDATE_ITEM_UNDETERMINED_PRICE: 'ORDER/UPDATE_ITEM_UNDETERMINED_PRICE',
  RESET_INVALID_ITEMS: 'ORDER/RESET_INVALID_ITEMS',
  ENTER_EDIT_MODE: 'ORDER/ENTER_EDIT_MODE',
  EXIT_EDIT_MODE: 'ORDER/EXIT_EDIT_MODE',
  UPDATE_ITEM_REMARKS: 'ORDER/UPDATE_ITEM_REMARKS',
  ORDER_CANCELLED: 'ORDER/ORDER_CANCELLED',
};
