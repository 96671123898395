import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { useSelector } from '@/redux';
import Text from '@/components/Text';

import ItemName from './ItemName';

const Body = ({ item, muted, indent = 0 }) => {
  const { t } = useTranslation();
  const errors = useSelector(state => {
    return state.order.invalidItemIdMap[item.id];
  });

  return (
    <View style={{ flex: 6, justifyContent: 'center' }}>
      <ItemName item={item} muted={muted} indent={indent} />

      {errors
        ? errors.map((msg, index) => (
          <Text key={index} style={styles.errorText} size={14}>{t(msg)}</Text>
        ))
        : null}
    </View>
  );
};

const styles = StyleSheet.create({
  errorText: {
    color: 'red',
  },
});

export default Body;
