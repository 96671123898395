import waiterApi from '@/libs/api/waiter';

import { setMerchantId } from '@root/src/libs/logger';
import ActionTypes from './ActionTypes';

export function init() {
  return async (dispatch, getState) => {
    await dispatch(getMerchant());
    await dispatch(getCustomReason());
    const merchant = getState().merchant.data;
    setMerchantId(merchant.id);
    // await dispatch(fetchSorting());
    // await dispatch(fetchModifier());

    // await AsyncStorage.setItem('merchant', JSON.stringify(getState().merchant))

    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    });
  };
}

export function getMerchant() {
  return async (dispatch) => {
    const merchant = await waiterApi.getMerchant();

    dispatch({
      type: ActionTypes.GET_MERCHANT,
      payload: merchant,
    });
  };
}

export function selectTableGroup(key) {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SELECT_TABLE_GROUP,
      payload: key,
    });
  };
}

export function getCustomReason() {
  return async (dispatch) => {
    const customReason = await waiterApi.getCustomReason();

    dispatch({
      type: ActionTypes.GET_CUSTOM_REASON,
      payload: customReason,
    });
  };
}
