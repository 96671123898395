import _ from 'lodash';
import moment from 'moment';

import { accountPermissions } from '@/components/Dialogs/AccountPermissionDialog/permissions';
import { checkPermission } from '@root/src/libs/auth';
import { dialogNames } from '@/components/Dialogs/constants';
import { dimorderApi } from '@/libs/api/dimorder';
import { history } from '@/libs/history';

import ActionTypes from './ActionTypes';
import logger from '@root/src/libs/logger';
import * as appActions from '../app/actions';
import * as authSelectors from '../auth/selectors';
import * as notificationActions from '../notifications/actions';

/**
 * @returns {ThunkFunction}
 */
export function init() {
  return async (dispatch) => {
    await dispatch(getOrders());
  };
}

/**
 * @param {IAppOrder} order
 * @returns {ThunkFunction}
 */
export function updateOrder(order) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_ORDER,
      payload: order,
    });
  };
}

/**
 *
 * @param {string} timeUnit - 'd' for days, 'm' for minutes
 * @param {string} timeProp - 'createdAt' | 'updatedAt'
 * @returns {ThunkFunction}
 */
export function getLatestOrders(timeUnit = 'd', timeProp = 'createdAt') {
  return async (dispatch, getState) => {
    const ordersHistory = getState().orderHistory;
    const now = moment();
    const oneDayAgo = moment().subtract(1, timeUnit);
    const local24Orders = ordersHistory.orders.filter(order => {
      const t = moment(order[timeProp]);
      return t.isBetween(oneDayAgo, now);
    });
    const ordersFromServer = await dimorderApi.order.getOrders({
      from: moment().add(-1, timeUnit).toDate(),
      timeProp: timeProp.toLowerCase(),
    });
    const lastestOrders = _.map(local24Orders, local24Order => {
      const order = ordersFromServer.find(o => o.id === local24Order.id);
      return (order?.version > local24Order?.version
        ? {
            ...order,
            [timeProp]: moment(order[timeProp]).toISOString(),
          }
        : {
            ...local24Order,
            [timeProp]: moment(local24Order[timeProp]).toISOString(),
          });
    });
    const orders = _.uniqBy(lastestOrders.concat(ordersFromServer), 'id');

    logger.log('[getLatestOrders]', { 'orders.length': orders.length });

    dispatch({
      type: ActionTypes.UPDATE_ORDERS,
      payload: orders,
    });
    dispatch(notificationActions.receiveInitialOrders(orders));
  };
}

/**
 * @returns {ThunkFunction}
 */
export function getOrders() {
  return async (dispatch) => {
    dispatch(getLatestOrders('d', 'createdAt'));
  };
}

/**
 * @returns {ThunkFunction}
 */
export function pollForOrders() {
  return async (dispatch, getState) => {
    const isLoggedIn = !!getState().auth.currentUser;

    if (isLoggedIn) {
      logger.log('[pollForOrders]');
      dispatch(getLatestOrders('m', 'updatedAt'));
    }
  };
}

/**
 * @param {string} orderId
 * @param {string} cancelReason
 * @param {string} identifier
 * @returns {ThunkFunction}
 */
export function deleteOrder(orderId, cancelReason, identifier) {
  return async (dispatch, getState) => {
    const userIdentifier = authSelectors.selectUserIdentifier(getState());

    await dimorderApi.order.cancelOrder(orderId, cancelReason, identifier ?? userIdentifier);

    dispatch({
      type: ActionTypes.DELETE_ORDER,
      payload: orderId,
    });
  };
}

// TODO: fix circular dependency
/**
 * @param {string} orderId
 * @returns {ThunkFunction}
 */
export function deleteOrderWithPermissionCheck(orderId) {
  return (dispatch, getState) => {
    const { scopeCredentials, currentUser } = getState().auth;
    const hasPermission = checkPermission(
      scopeCredentials,
      currentUser,
      accountPermissions.CANCEL_ORDER,
    );

    if (hasPermission) {
      dispatch(appActions.toggleDrawer('cancelReason', true, { orderId }));
    } else {
      dispatch(appActions.toggleDialog(
        dialogNames.accountPermission,
        true,
        { requiredPermission: accountPermissions.CANCEL_ORDER, extra: orderId }),
      );
    }
  };
}

export function selectTransferTableGroup(key) {
  return {
    type: ActionTypes.SELECT_TRANSFER_TABLE_GROUP,
    payload: key,
  };
}

export function selectOrderToTransfer(order) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SELECT_ORDER_TO_TRANSFER,
      payload: order,
    });
    history.push('/table-orders/transfertable');
  };
}

// TODO: fix circular dependency
export function selectOrderToTransferWithPermissionCheck(order) {
  return (dispatch, getState) => {
    const { scopeCredentials, currentUser } = getState().auth;
    const hasPermission = checkPermission(
      scopeCredentials,
      currentUser,
      accountPermissions.TRANSFER_ITEM,
    );

    if (hasPermission) {
      dispatch(selectOrderToTransfer(order));
    } else {
      dispatch(appActions.toggleDialog(
        dialogNames.accountPermission,
        true,
        { requiredPermission: accountPermissions.TRANSFER_ITEM, extra: order }),
      );
    }
  };
}
