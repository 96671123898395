import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import enUS from '@/i18n/locales/en-US';
import zhHK from '@/i18n/locales/zh-HK';

let i18nextLng = 'zh-HK';
const LANGUAGE_KEY = 'LANGUAGE';

// restore from local storage
if (typeof window !== 'undefined' && window.name) {
  console.log('...', typeof { window });
  i18nextLng = localStorage.getItem(LANGUAGE_KEY);
  if (!i18nextLng) {
    // if local storage not config
    i18nextLng = 'zh-HK';
    localStorage.setItem(LANGUAGE_KEY, i18nextLng);
  }
}

const resources = {
  'en-US': {
    translation: enUS,
  },
  'zh-HK': {
    translation: zhHK,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: i18nextLng, // 預設語言
    fallbackLng: i18nextLng, // 如果當前切換的語言沒有對應的翻譯則使用這個語言，
    interpolation: {
      escapeValue: false, // 如果語系檔中有巢狀的結構，則 escapeValue 要設為 false，這樣就可以透過物件的方式來取得巢狀內的翻譯
    },
  });

export default i18n;
