import { StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import React from 'react';

import { navMinHeight } from '@/constants/app';
import { useRouteMatch } from '@/libs/reactRouter';
import colors from '@/theme/colors';

import BackButton from './buttons/BackButton';
import LinearGradient from '../RNLinearGradient';
import NotificationButton from './buttons/NotificationButton';
import OrderHistoryButton from './buttons/OrderHistoryButton';
import SearchBar from './components/SearchBar';
import SearchButton from './buttons/SearchButton';
import SettingButton from './buttons/SettingButton';
import TableGroupSelector from '../TableGroupSelector';
import Text from '../Text';

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  background: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    paddingHorizontal: 14,
    zIndex: 999,
  },
  title: {
    fontWeight: 'bold',
  },
  navStart: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  navCenter: {
    flex: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  navEnd: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
});

export default function NavBar (props) {
  const insets = useSafeAreaInsets();
  const isNotificationPage = useRouteMatch('/notifications');

  const {
    title,
    settingButton,
    backButton,
    orderHistoryButton,
    tableGroupSelector,
    onBack,
    searchBar,
    searchButton,
  } = props;

  return (
    <View style={{ height: navMinHeight }}>
      <View style={styles.container}>
        <LinearGradient
          colors={[colors.primary.main, colors.primary.light]}
          start={[0, 1]}
          end={[1, 0]}
          style={[
            styles.background,
            {
              height: navMinHeight + insets.top,
              paddingTop: insets.top,
              position: 'absolute',
              top: -insets.top,
            },
          ]}
        >
          {!searchBar && (
            <>
              <View style={styles.navStart}>
                {backButton && <BackButton onPress={onBack} />}
                {settingButton && <SettingButton />}
              </View>
              <View style={styles.navCenter}>
                {title && (
                  <Text size={18} style={styles.title}>{title}</Text>
                )}
                {tableGroupSelector && <TableGroupSelector />}
              </View>
              <View style={styles.navEnd}>
                {orderHistoryButton && <OrderHistoryButton />}
                {searchButton && <SearchButton />}
                {!isNotificationPage && <NotificationButton />}
              </View>
            </>
          )}
          {
            searchBar && (
              <>
                <View style={[styles.navStart, { flex: 1.5 }]}>
                  {backButton && <BackButton onPress={onBack} />}
                </View>

                <View style={[styles.navCenter, { flex: 9 }]}>
                  <SearchBar />
                </View>
              </>
            )
          }
        </LinearGradient>
      </View>
    </View>
  );
}
