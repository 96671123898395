import { v4 as uuid } from 'uuid';
import _ from 'lodash';

import { dialogNames } from '@/components/Dialogs/constants';
import { getNextSubTableIndex, localOrderToAppOrder, status } from '@/libs/order';
import { history } from '@/libs/history';
import { loadingKey } from '@/constants/app';
import i18n from '@/i18n';
import logger, { logId } from '@/libs/logger';
import waiterApi from '@/libs/api/waiter';

import { initialBatch, initialOrder } from './reducer';
import ActionTypes from './ActionTypes';
import produce from 'immer';
import * as appActions from '../app/actions';
import * as authSelectors from '../auth/selectors';
import * as orderHistoryActions from '../orderHistory/actions';

/**
 * @param {TDeliveryType} deliveryType
 * @returns {ThunkFunction}
 */
export function createLocalOrder(deliveryType = 'takeaway') {
  return async (dispatch, getState) => {
    const isDineIn = deliveryType === 'table';
    const surcharge = getState().merchant.data.surcharge;
    const table = isDineIn ? getState().order.selectionStack[0].table : '';
    const orderId = uuid();
    const now = new Date().toISOString();

    const order = {
      ...initialOrder,
      id: orderId,
      createdAt: now,
      updatedAt: now,
      subTable: getNextSubTableIndex(getState().orderHistory.orders, table),
      deliveryType,
      surcharge,
      table,
    };

    if (isDineIn) {
      // 防止短時間內快速多次開桌
      const loadingActions = getState().app.loadingActions;
      const isLoadingOpenTable = loadingActions.filter(o => o.key === loadingKey.OPEN_TABLE).length > 0;
      if (isLoadingOpenTable) {
        return;
      }

      // load when calling api
      dispatch(appActions.openLoading(loadingKey.OPEN_TABLE));
      try {
        // TODO: update when supporting customer count is needed
        const apiOrder = await waiterApi.addTable({
          adults: 1,
          children: 0,
          create: true,
          deliveryType: 'table',
          from: 'MERCHANT',
          orderID: orderId,
          table,
        });

        console.log({ apiOrder });

        // TODO: phase 2, generate serial locally
        order.serial = apiOrder.serial;
        order.orderSerial = apiOrder.orderSerial;

        dispatch(updateWorkingOrder(order, true));
        dispatch(orderHistoryActions.updateOrder(order));
        dispatch(createLocalBatch());
        history.push('/order-categories');
      } catch (error) {
        console.log(error);
        logger.log(`[Create Order] Error ${error?.message}`, { error });
        dispatch(removeSelection());
        dispatch(appActions.toggleDialog(dialogNames.error, true, {
          title: `${i18n.t('app.dialogs.error.general.title')}（${logId}）`,
          message: i18n.t('app.dialogs.error.general.message'),
        }));
      }
      dispatch(appActions.closeLoading(loadingKey.OPEN_TABLE));
    }
  };
}

export function updateCustomerCount(orderId, adults, children) {
  return async (dispatch, getState) => {
    dispatch(appActions.openLoading(loadingKey.UPDATE_CUSTOMER_COUNT));
    try {
      const apiOrder = await waiterApi.updateCustomerCount(orderId, adults, children);

      const reduxOrder = getState().orderHistory.orders.find(o => o.id === apiOrder.id);

      // create editable object
      const order = { ...reduxOrder };

      if (order) {
        order.customers = apiOrder.customers;
        order.adults = apiOrder.adults;
        order.children = apiOrder.children;
        dispatch(orderHistoryActions.updateOrder(order));
      } else {
        throw new Error('cannot find order');
      }
    } catch (error) {
      console.log(error);
      logger.log(`[Update Customer] Error ${error?.message} `, { error });
      dispatch(appActions.toggleDialog(dialogNames.error, true, {
        title: `${i18n.t('app.dialogs.error.general.title')}（${logId}）`,
        message: i18n.t('app.dialogs.error.general.message'),
      }));
    }
    dispatch(appActions.closeLoading(loadingKey.UPDATE_CUSTOMER_COUNT));
  };
}

export function transferOrder (targetOrder, targetTableKey) {
  return async (dispatch, getState) => {
    dispatch(appActions.openLoading(loadingKey.TRANSFER_ORDER));
    try {
      const apiOrder = await waiterApi.transferOrder(targetOrder, targetTableKey);

      const reduxOrder = getState().orderHistory.orders.find(o => o.id === apiOrder.id);

      // create editable object
      const order = { ...reduxOrder };

      if (order) {
        order.table = targetTableKey;
        order.subTable = getNextSubTableIndex(getState().orderHistory.orders, targetTableKey);
        dispatch(orderHistoryActions.updateOrder(order));
      } else {
        throw new Error('cannot find order');
      }
    } catch (error) {
      console.log(error);
      logger.log(`[Transfer Order] Error ${error?.message}`, { error });
      dispatch(appActions.toggleDialog(dialogNames.error, true, {
        title: `${i18n.t('app.dialogs.error.general.title')}（${logId}）`,
        message: i18n.t('app.dialogs.error.general.message'),
      }));
    }
    dispatch(appActions.closeLoading(loadingKey.TRANSFER_ORDER));
  };
}

/**
 * @returns {ThunkFunction}
 */
export function createLocalBatch() {
  return (dispatch, getState) => {
    const order = getState().order.workingOrder;
    const userIdentifier = authSelectors.selectUserIdentifier(getState());
    const batchId = uuid();
    const now = new Date();

    const batch = {
      ...initialBatch,
      id: batchId,
      identifier: userIdentifier,
      orderSerial: order.orderSerial,
      index: 0,
      status: 'submitted',
      createdAt: now.toISOString(),
      updatedAt: now.toISOString(),
      orderId: order.id,
      table: order.table,
      batchId,
    };

    dispatch(updateWorkingBatch(batch));
  };
}

/**
 * @returns {ThunkFunction}
 */
export function createLocalItem(menuItem) {
  return (dispatch) => {
    const key = uuid();
    const workingItem = {
      id: key,
      menuId: menuItem.id,
      categoryId: menuItem.categoryId,
      name: menuItem.name,
      desc: menuItem.desc,
      quantity: 1,
      price: menuItem.price,
      priceUndetermined: menuItem.priceUndetermined,
      discount: menuItem.discount,
      options: [],
      tags: [],
      remarks: [],
      setItems: [],
      modifiers: [],
      image: menuItem.image,
      excludedDiscount: menuItem.excludedDiscount,
      excludedSurcharge: menuItem.excludedSurcharge,
      key,
    };

    if (menuItem.setMenus?.length) {
      workingItem.isSet = true;
      workingItem.setId = menuItem.id;
    }

    dispatch(updateWorkingItem(workingItem));
  };
}

/**
 * @returns {ThunkFunction}
 */
export function createLocalSetItem(menuItem) {
  return (dispatch) => {
    const key = uuid();

    dispatch(
      updateWorkingSetItem({
        id: key,
        menuItemId: menuItem.id,
        setId: menuItem.setId,
        menuId: menuItem.menuId,
        categoryId: menuItem.menuCategoryId,
        name: menuItem.name,
        desc: menuItem.desc,
        quantity: 1,
        price: menuItem.price,
        priceUndetermined: menuItem.priceUndetermined,
        discount: menuItem.discount,
        options: [],
        tags: [],
        remarks: [],
        setItems: [],
        modifiers: [],
        image: menuItem.image,
        excludedDiscount: menuItem.excludedDiscount,
        excludedSurcharge: menuItem.excludedSurcharge,
        key,
      }),
    );
  };
}

/**
 * @param {IAppOrder} order
 * @param {boolean} isCreate
 * @returns {ThunkFunction}
 */
export function updateWorkingOrder(order, isCreate) {
  return (dispatch, getState) => {
    const workingOrder = getState().order.workingOrder;

    if (!workingOrder && isCreate) {
      dispatch({
        type: ActionTypes.UPDATE_WORKING_ORDER,
        payload: order,
      });
      return;
    }

    if (!workingOrder || workingOrder.id !== order.id) {
      return;
    }

    if (order.status === status.CANCELLED) {
      dispatch(cancelOrder());
    } else {
      dispatch({
        type: ActionTypes.UPDATE_WORKING_ORDER,
        payload: order,
      });
    }
  };
}

export function cancelOrder() {
  return (dispatch) => {
    history.push('/tables');
    dispatch({
      type: ActionTypes.ORDER_CANCELLED,
    });
    dispatch(appActions.toggleDialog(dialogNames.error, true, {
      title: i18n.t('app.dialogs.error.orderCancelled.title'),
      message: i18n.t('app.dialogs.error.orderCancelled.message'),
    }));
  };
}

/**
 * @param {IAppOrderBatch} batch
 * @returns {ThunkFunction}
 */
export function updateWorkingBatch(batch) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_WORKING_BATCH,
      payload: batch,
    });
  };
}

/**
 * @param {IAppBatchItem} item
 * @returns {ThunkFunction}
 */
export function updateWorkingItem(item) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_WORKING_ITEM,
      payload: item,
    });
  };
}

/**
 * @param {IAppBatchItem} item
 * @returns {ThunkFunction}
 */
export function updateWorkingSetItem(item) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_WORKING_SET_ITEM,
      payload: item,
    });
  };
}

/**
 * @param {IAppBatchItem} item
 * @returns {ThunkFunction}
 */
export function updateWorkingItemTags(tags) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_WORKING_ITEM_TAGS,
      payload: tags,
    });
  };
}

/**
 * @param {string} orderId
 * @returns {ThunkFunction}
 */
export function selectOrderById(orderId) {
  return (dispatch, getState) => {
    const order = getState().orderHistory.orders.find(o => o.id === orderId);

    if (order) {
      dispatch({
        type: ActionTypes.UPDATE_WORKING_ORDER,
        payload: order,
      });
    }
  };
}

/**
 * @returns {ThunkFunction}
 */
export function addItemToBatch() {
  return (dispatch, getState) => {
    const workingItem = getState().order.workingItem;
    const menus = workingItem.isSet
      ? getState().menu.sets
      : getState().menu.menus;
    const menu = menus.find(m => m.id === workingItem.menuId);

    dispatch({
      type: ActionTypes.ADD_ITEM_TO_BATCH,
      payload: {
        item: {
          ...workingItem,
          menuId: workingItem.menuId,
        },
        menu,
      },
    });
  };
}

/**
 * @param {string} [stepId]
 * @returns {ThunkFunction}
 */
export function addSetItemToWorkingItem(stepId) {
  return (dispatch, getState) => {
    const { workingSetItem, selectionStack, workingItem } = getState().order;
    const step = stepId || selectionStack.find(selection => selection.subsetStepId).subsetStepId;
    const menu = getState().menu.sets.find(m => m.id === workingItem.menuId);

    dispatch({
      type: ActionTypes.ADD_SET_ITEM_TO_ITEM,
      payload: {
        item: {
          ...workingSetItem,
          menuId: workingSetItem.menuId,
          step,
        },
        menu,
      },
    });
  };
}

/**
 * @returns {ThunkFunction}
 */
export function deleteItemFromBatch (item) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.DELETE_ITEM_FROM_BATCH,
      payload: item,
    });
  };
}

/**
 * @returns {ThunkFunction}
 */
export function deleteSetItemfromBatch (setKey, setItemKey, setId) {
  return (dispatch, getState) => {
    const menu = getState().menu.sets.find(m => m.id === setId);

    dispatch({
      type: ActionTypes.DELETE_SET_ITEM_FROM_BATCH,
      payload: {
        setKey,
        setItemKey,
        menu,
      },
    });
  };
}

/**
 * @param {string} priceText
 * @returns {ThunkFunction}
 */
export function updateItemUndeterminedPrice(priceText) {
  return (dispatch, getState) => {
    const isSetItem = Boolean(getState().order.workingSetItem);

    dispatch({
      type: isSetItem
        ? ActionTypes.UPDATE_SET_ITEM_UNDETERMINED_PRICE
        : ActionTypes.UPDATE_ITEM_UNDETERMINED_PRICE,
      payload: priceText,
    });
  };
}

/**
 * @returns {ThunkFunction}
 */
export function updateItemQuantity(quantity) {
  return (dispatch, getState) => {
    const isSetItem = Boolean(getState().order.workingSetItem);

    dispatch({
      type: isSetItem ? ActionTypes.UPDATE_SET_ITEM_QUANTITY : ActionTypes.UPDATE_ITEM_QUANTITY,
      payload: quantity,
    });
  };
}

/**
 * @returns {ThunkFunction}
 */
export function updateSetItemQuantity(quantity) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_SET_ITEM_QUANTITY,
      payload: quantity,
    });
  };
}

/**
 * @returns {ThunkFunction}
 */
export function updateItemOptions(optionGroup, optionItem, quantity = 1) {
  return (dispatch, getState) => {
    const isSetItem = Boolean(getState().order.workingSetItem);

    const workingItem = isSetItem
      ? getState().order.workingSetItem
      : getState().order.workingItem;

    const originalOptionGroup = workingItem.options.filter(o => o.optionGroupId === optionGroup.id);
    const originOptionItem = workingItem.options.find(o => o.optionItemId === optionItem.id);
    const originalOptionGroupQuantity = _.sumBy(originalOptionGroup, 'quantity');
    const isOptionGroupMultiple = optionGroup.multiple && optionGroup.max > 1;
    const isOptionItemMultiple = optionItem.max > 1;
    const updatedQuantity = originalOptionGroupQuantity - originOptionItem?.quantity + quantity;

    let action = null;

    // 選項已被選取
    if (originalOptionGroup.find(o => o.optionItemId === optionItem.id)) {
      // max selection reached
      if ((optionGroup.max > 0 && updatedQuantity > optionGroup.max) || quantity > optionItem.max) {
        return;
      }

      // 選項群可多選
      // 選項最大可選多於一
      if (isOptionGroupMultiple && isOptionItemMultiple) {
        if (quantity > 0) {
          // 更新該選項數量
          if (isSetItem) {
            action = ActionTypes.UPDATE_SET_ITEM_OPTIONS;
          } else {
            action = ActionTypes.UPDATE_ITEM_OPTIONS;
          }
        } else {
          if (isSetItem) {
            action = ActionTypes.DELETE_SET_ITEM_OPTIONS;
          } else {
            action = ActionTypes.DELETE_ITEM_OPTIONS;
          }
        }
      } else {
        // 不可多選，直接刪除
        if (isSetItem) {
          action = ActionTypes.DELETE_SET_ITEM_OPTIONS;
        } else {
          action = ActionTypes.DELETE_ITEM_OPTIONS;
        }
      }
    } else { // 選項未被選取
      if (isOptionGroupMultiple) {
        if (
          (optionGroup.max > 0 && (originalOptionGroupQuantity + quantity) > optionGroup.max) ||
          quantity > optionItem.max
        ) {
          return;
        }
        // 選項群可多選，新增該選項
        if (isSetItem) {
          action = ActionTypes.ADD_SET_ITEM_OPTIONS;
        } else {
          action = ActionTypes.ADD_ITEM_OPTIONS;
        }
      } else {
        // 選項群不可多選，取代同一選項群的舊選項
        if (isSetItem) {
          action = ActionTypes.REPLACE_SET_ITEM_OPTIONS;
        } else {
          action = ActionTypes.REPLACE_ITEM_OPTIONS;
        }
      }
    }

    const newOption = {
      optionGroupId: optionGroup.id,
      optionItemId: optionItem.id,
      optionName: optionGroup.name,
      name: optionItem.name,
      price: optionItem.price,
      quantity: quantity,
    };

    dispatch({
      type: action,
      payload: newOption,
    });
  };
}

/**
 * @returns {ThunkFunction}
 */
export function resetOptionGroup (optionGroup) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.RESET_ITEM_OPTION_GROUP,
      payload: optionGroup,
    });
  };
}

/**
 * @returns {ThunkFunction}
 */
export function addTag (tag) {
  return (dispatch, getState) => {
    let action = null;
    const isSetItem = Boolean(getState().order.workingSetItem);

    if (isSetItem) {
      action = ActionTypes.ADD_SET_ITEM_TAG;
    } else {
      action = ActionTypes.ADD_ITEM_TAG;
    }
    dispatch({
      type: action,
      payload: tag,
    });
  };
}

/**
 * @returns {ThunkFunction}
 */
export function deleteTag (tag) {
  return (dispatch, getState) => {
    let action = null;
    const isSetItem = Boolean(getState().order.workingSetItem);

    if (isSetItem) {
      action = ActionTypes.DELETE_SET_ITEM_TAG;
    } else {
      action = ActionTypes.DELETE_ITEM_TAG;
    }
    dispatch({
      type: action,
      payload: tag,
    });
  };
}

/**
 *
 * Computes the step index map
 *
 */
function computeStepIndexMap(getState) {
  const map = {};
  const sets = getState().menu.sets;

  sets.forEach((set) => {
    set.steps.forEach((step, i) => {
      const stepId = step.id;
      map[stepId] = i;
    });
  });

  return map;
}

/**
 * Adds step index to each item in a batch.
 */
function addStepIndex(batchItem, stepIndexMap) {
  const nextState = produce(batchItem, draftState => {
    draftState.setItems.forEach((setItem, i) => {
      setItem.stepIndex = stepIndexMap[setItem.step];
    });
  });
  return nextState;
}

/**
 * 若 batchItem 內有 setItems 則將 setItems 排序
 */
function sortSetItems (batchItem) {
  if (batchItem.setItems) {
    const sorted = _.orderBy(batchItem.setItems, ['stepIndex', 'menuId'], 'asc');
    const result = { ...batchItem, setItems: sorted };
    return result;
  } else {
    return batchItem;
  }
}

/**
 * @returns {ThunkFunction}
 */
export function confirmOrder() {
  return async (dispatch, getState) => {
    // TODO: validate

    // check sets
    // - check steps requirements
    // - check set item options

    // - check and sort set items
    const workingBatch = getState().order.workingBatch;
    const stepIndexMap = computeStepIndexMap(getState);
    const batchItems = workingBatch.items
      .map(batchItem => addStepIndex(batchItem, stepIndexMap))
      .map(sortSetItems);
    const batch = produce(workingBatch, draftState => {
      draftState.items = batchItems;
    });
    dispatch(updateWorkingBatch(batch));

    // check items
    // - check item options (min/max, multiple, required)

    // price undetermined

    // TODO: check inventory

    dispatch({ type: ActionTypes.CONFIRM_ORDER });

    const order = getState().order.workingOrder;
    const merchant = getState().merchant.data;
    const user = authSelectors.selectUserIdentifier(getState());
    const appOrder = localOrderToAppOrder(merchant, user, order);

    // load when calling api
    dispatch(appActions.openLoading(loadingKey.CONFIRM_ORDER));

    try {
      await waiterApi.syncOrder(appOrder, true);

      dispatch(orderHistoryActions.updateOrder(appOrder));
      dispatch({ type: ActionTypes.RESET });
    } catch (error) {
      console.log(error);
      logger.log(`[Submit Batch] Error ${error?.message} `, { error });
      dispatch(appActions.toggleDialog(dialogNames.error, true, {
        title: `${i18n.t('app.dialogs.error.general.title')}（${logId}）`,
        message: i18n.t('app.dialogs.error.general.message'),
      }));
    }

    dispatch(appActions.closeLoading(loadingKey.CONFIRM_ORDER));
  };
}

/**
 * @param {IOrderSelection} selection
 * @returns {ThunkFunction}
 */
export function addSelection(selection) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ADD_SELECTION,
      payload: selection,
    });
  };
}

/**
 * @param {number} [numToRemove]
 * @returns {ThunkFunction}
 */
export function removeSelection(numToRemove = 1) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.REMOVE_SELECTION,
      payload: numToRemove,
    });
  };
}

/**
 * @returns {ThunkFunction}
 */
export function clearSelection() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CLEAR_SELECTION,
    });
  };
}

/**
 * @returns {ThunkFunction}
 */
export function reconcileStash() {
  return (dispatch, getState) => {
    const { stash, selectionStack } = getState().order;

    if (stash && selectionStack.length === 0) {
      dispatch({
        type: ActionTypes.RECONCILE_STASH,
      });
    }
  };
}

/**
 * @returns {ThunkFunction}
 */
export function stash() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.STASH,
    });
  };
}

/**
 * @returns {ThunkFunction}
 */
export function discardOrder() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.DISCARD_ORDER,
    });
  };
}

/**
 * @param {boolean} cartExpanded
 * @returns {ThunkFunction}
 */
export function setCartExpanded (cartExpanded) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_CART_EXPANDED,
      payload: cartExpanded,
    });
  };
}

/**
 * @param {boolean} cartInDeleteMode
 * @returns {ThunkFunction}
 */
export function setCartInDeleteMode (cartInDeleteMode) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_CART_DELETE_MODE,
      payload: cartInDeleteMode,
    });
  };
}

/**
 * @returns {ThunkFunction}
 */
export function resetInvalidItems() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.RESET_INVALID_ITEMS,
    });
  };
}

/**
 * @param {string} path - location.pathname before entering edit mode
 * @returns {ThunkFunction}
 */
export function enterEditMode(path) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ENTER_EDIT_MODE,
      payload: path,
    });
  };
}

/**
 * @returns {ThunkFunction}
 */
export function exitEditMode() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.EXIT_EDIT_MODE,
    });
  };
}

/**
 * @returns {ThunkFunction}
 */
export function updateItemRemarks(remarks) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_ITEM_REMARKS,
      payload: remarks,
    });
  };
}
