import { StyleSheet, View } from 'react-native';
import React from 'react';
import _ from 'lodash';

import { actions, selectors, useDispatch, useSelector } from '@/redux';
import { useHistory } from '@/libs/reactRouter';
import FooterOrderButtons from '@/components/FooterOrderButtons';
import ListWithBatchCart from '@/components/ListWithBatchCart';
import MenuList from './MenuList';
import NavBar from '@/components/NavBar';
import themeStyle from '@/theme/themeStyle';

const OrderMenus = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const categoryWithMenus = useSelector(selectors.menu.selectCategoryWithMenus);
  const { categoryId: selectedCategoryId } = useSelector(selectors.order.selectOrderSelections);
  const isNestedCategory = useSelector(state => {
    return state.order.selectionStack.filter(s => s.categoryId).length > 1;
  });

  const category = categoryWithMenus.categories.find(cat => cat.id === selectedCategoryId);

  const navTitle = category
    ? category.name
    : categoryWithMenus.categories
      .map(cat => [cat, ...cat.categories])
      .flat()
      .find(cat => cat.id === selectedCategoryId)
      .name;

  return (
    <View style={styles.container}>
      <NavBar
        title={navTitle}
        backButton
        searchButton
        onBack={() => {
          dispatch(actions.order.removeSelection());
          history.push(isNestedCategory ? '/order/menus' : '/order-categories');
        }}
      />

      <View style={styles.contentContainer}>
        <ListWithBatchCart>
          <MenuList />
        </ListWithBatchCart>

        <FooterOrderButtons />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    paddingHorizontal: themeStyle.spacing.app.horizontal,
    paddingVertical: themeStyle.spacing.app.vertical,
  },
});

export default OrderMenus;
