import { StyleSheet } from 'react-native';
import { useHistory } from '@/libs/reactRouter';
import IconButton from '@/components/IconButton';
import React from 'react';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  icon: {
    height: 32,
    width: 32,
  },
});

export default function SettingButton (props) {
  const history = useHistory();

  const onPress = () => {
    history.push('/setting');
  };

  return (
    <IconButton iconSource={require('@icons/navbar/setting.png')} iconStyle={styles.icon} onPress={onPress} />
  );
}
