import { ScrollView } from 'react-native-gesture-handler';
import { StyleSheet, View } from 'react-native';
import React, { useState } from 'react';
import _ from 'lodash';

import { actions, selectors, useDispatch, useSelector } from '@/redux';
import { isItemOptionCompleted } from '@/libs/order';
import { useHistory } from '@/libs/reactRouter';
import CustomTag from '@/components/CustomTag';
import FooterDoneButton from '@/components/FooterDoneButton';
import NavBar from '@/components/NavBar';

import OptionSection from './OptionSection';
import PriceUndeterminedBar from './ActionBars/PriceUndeterminedBar';
import QuantityBar from './ActionBars/QuantityBar';
import Remarks from './Remarks';
import ServeLaterBar from './ActionBars/ServeLaterBar'; ;

const OrderMenuOptions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentItem = useSelector(selectors.order.selectCurrentItem);
  const { prefix, suffix } = useSelector(state => state.merchant.data.customTag);
  const menu = useSelector(selectors.order.selectMenu);
  const workingSingleItem = useSelector(state => state.order.workingItem);
  const isSetItem = useSelector(selectors.order.selectIsSetItem);
  const menuName = useSelector(state => {
    return selectors.menu.selectMenuNameByLocale(state, menu);
  });
  const editFromPath = useSelector(state => state.order.editFromPath);
  const [price, setPrice] = useState('');

  const { options, quantity } = currentItem;
  const orderedOptions = _.orderBy(menu?.options, 'weight', 'asc');
  const isOptionCompelete = isItemOptionCompleted(menu, currentItem, isSetItem);

  const updateItem = () => {
    if (isSetItem) {
      dispatch(actions.order.updateWorkingSetItem(null));
    } else {
      dispatch(actions.order.updateWorkingItem(null));
    }
  };

  const deleteItem = () => {
    if (isSetItem) {
      dispatch(
        actions.order.deleteSetItemfromBatch(
          workingSingleItem.key,
          currentItem.key,
          workingSingleItem.setId,
        ),
      );
    } else {
      dispatch(actions.order.deleteItemFromBatch(currentItem));
    }
  };

  const addItem = () => {
    if (isSetItem) {
      dispatch(actions.order.addSetItemToWorkingItem());
    } else {
      dispatch(actions.order.addItemToBatch());
    }
  };

  const reconcileOnBack = () => {
    dispatch(actions.order.removeSelection());
    dispatch(actions.order.reconcileStash());

    if (editFromPath) {
      dispatch(actions.order.exitEditMode());
      history.push(editFromPath);
    } else {
      history.push(isSetItem ? '/order/sets/menus' : '/order/menus');
    }
  };

  const handleNavBack = () => {
    updateItem();
    reconcileOnBack();
  };

  return (
    <View style={{ flex: 1 }}>
      <NavBar
        title={menuName}
        backButton
        onBack={handleNavBack}
      />

      <View style={{ paddingHorizontal: 16, paddingTop: 22 }}>
        <ServeLaterBar />
        <QuantityBar />
        <PriceUndeterminedBar
          isPriceUndetermined={currentItem.priceUndetermined}
          price={price}
          onChangePrice={setPrice}
        />
      </View>

      <ScrollView bounces={false} style={{ marginVertical: 8 }}>
        <Remarks
          value={currentItem.remarks.join('')}
          onChange={(text) => {
            dispatch(actions.order.updateItemRemarks(text));
          }}
        />
        <OptionSection options={orderedOptions} workingOptions={options} />

        {(prefix.length || suffix.length)
          ? <CustomTag />
          : null}
      </ScrollView>

      <FooterDoneButton
        disabled={!isOptionCompelete}
        onPress={() => {
          if (quantity === 0) {
            deleteItem();
            reconcileOnBack();
            return;
          }

          if (currentItem.priceUndetermined && price) {
            dispatch(actions.order.updateItemUndeterminedPrice(price));
          }

          addItem();
          reconcileOnBack();
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({});

export default OrderMenuOptions;
