import _ from 'lodash';

import codePush from '@/libs/codePush';

export const Deployments = {
  Test: 'Test',
  Beta: 'Beta',
  Production: 'Production',
  ProductionSE: 'Production-SE',
};

// for ordering
export const codePushChannels = [
  Deployments.Test,
  Deployments.Beta,
  Deployments.Production,
  Deployments.ProductionSE,
];

export const deploymentKeys = {
  [Deployments.Test]: 'tX87vXIt4Df3NxxxmA2u1-dNQyZp548wG13Fl',
  [Deployments.Beta]: 'BRXJlOnZ2aIBW0BAIrrn-oJXGO-JJZQqmq8bw',
  [Deployments.Production]: 'o3xW2dR2B4-kccrFDSA5SS9-6QJ-X0qQ76gkY',
  [Deployments.ProductionSE]: 'snnvCzARPrDb4vbt_3ugv2khXc_xAkf-QArpT',
};

export const deploymentKeyMap = _.invert(deploymentKeys);

export const statusDisplayTextI18nKeys = {
  [codePush?.SyncStatus?.UP_TO_DATE]: 'app.page.setting.localPanel.settingCard.upToDate',
  [codePush?.SyncStatus?.UPDATE_INSTALLED]: 'app.page.setting.localPanel.settingCard.updateInstalled',
  [codePush?.SyncStatus?.UPDATE_IGNORED]: 'app.page.setting.localPanel.settingCard.updateIgnored',
  [codePush?.SyncStatus?.UNKNOWN_ERROR]: 'app.page.setting.localPanel.settingCard.unknownError',
  [codePush?.SyncStatus?.SYNC_IN_PROGRESS]: 'app.page.setting.localPanel.settingCard.syncInProgress',
  [codePush?.SyncStatus?.CHECKING_FOR_UPDATE]: 'app.page.setting.localPanel.settingCard.checkingForUpdate',
  [codePush?.SyncStatus?.AWAITING_USER_ACTION]: 'app.page.setting.localPanel.settingCard.awating',
  [codePush?.SyncStatus?.DOWNLOADING_PACKAGE]: 'app.page.setting.localPanel.settingCard.downloading',
  [codePush?.SyncStatus?.INSTALLING_UPDATE]: 'app.page.setting.localPanel.settingCard.installing',
};

export default {
  Deployments,
  deploymentKeys,
  deploymentKeyMap,
  statusDisplayTextI18nKeys,
};
