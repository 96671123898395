import { ImageBackground, StyleSheet, View } from 'react-native';
import React from 'react';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    resizeMode: 'cover',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: colors.black,
    opacity: 0.5,
  },
});

export default function Background () {
  return (
    <>
      <ImageBackground
        source={require('@images/background/login-bg.png')}
        style={styles.container}
      />
    </>
  );
}
