import { FlatList } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { actions, selectors, useDispatch, useSelector } from '@/redux';
import {
  isCategorySet,
  isWithinSpecifiedTimeRange,
  isWithinSpecifiedWeekdays,
} from '@/libs/order';
import { padToMatchMultiple } from '@/libs/array';
import { useHistory } from '@/libs/reactRouter';

import ItemBox from '@/components/ItemBox';
import themeStyle from '@/theme/themeStyle';

const numColumns = 3;

export default function CategoryList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const categoryWithMenus = useSelector(selectors.menu.selectCategoryWithMenus);

  const categories = categoryWithMenus.categories.filter(c => {
    return [
      !isCategorySet(c),
      !c.takeawayOnly,
      !c.disabled,
      isWithinSpecifiedWeekdays(c.weekdays),
      isWithinSpecifiedTimeRange(c.timerange),
    ].every(Boolean);
  });

  const listData = [
    { name: t('app.page.orderSets.set'), isBundle: true, id: 'bundle' },
    ...categories,
  ];

  const renderItem = ({ item }) => {
    const handlePress = () => {
      if (item.isBundle) {
        history.push('/order/sets');
      } else {
        dispatch(actions.order.addSelection({ categoryId: item.id }));
        history.push('/order/menus');
      }
    };

    return (
      <ItemBox
        key={item.id}
        height={themeStyle.box.height.long}
        item={item}
        onPress={handlePress}
      />
    );
  };

  return (
    <FlatList
      data={padToMatchMultiple(listData, numColumns)}
      renderItem={renderItem}
      numColumns={numColumns}
      showsVerticalScrollIndicator={false}
    />
  );
}
