import _ from 'lodash';
import produce from 'immer';

import ActionTypes from './ActionTypes';
import ActionTypesAuth from '../auth/ActionTypes';

/** @type {IMenuState} */
export const initialState = {
  categories: [],
  menus: [],
  sets: [],
  optionGroupPresets: [],
  searchText: null,
  searchHistory: [],
};

export default produce(
  /**
   * @param {IMenuState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action.type) {
      case ActionTypesAuth.USER_LOGOUT: {
        return initialState;
      }

      case ActionTypes.UPDATE_MENU_LIST: {
        const { categories, menus, sets, optionGroupPresets } = action.payload;

        draft.categories = categories;
        draft.menus = menus;
        draft.sets = sets;
        draft.optionGroupPresets = optionGroupPresets;
        break;
      }

      case ActionTypes.UPDATE_SEARCH_TEXT: {
        draft.searchText = action.payload;
        break;
      }

      case ActionTypes.UPDATE_SEARCH_HISTORY: {
        draft.searchHistory.push(action.payload);

        // remove duplicated
        draft.searchHistory = _.uniq(draft.searchHistory);

        // only store recent 20 search text
        if (draft.searchHistory.length > 20) {
          draft.searchHistory.splice(0, draft.searchHistory.length - 10);
        }
        break;
      }

      case ActionTypes.RESET_SEARCH_TEXT: {
        draft.searchText = null;
        break;
      }

      default: {
        return draft;
      }
    }
  },
  initialState,
);
