import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';
import moment from 'moment';

import { actions, useDispatch } from '@/redux';
import { dialogNames } from '@/components/Dialogs/constants';
import { getTableName } from '@/libs/order';
import { numberWithCommas } from '@/libs/numberWithCommas';
import Text from '@/components/Text';
import colors from '@/theme/colors';

import Button, { variants } from '../../Buttons';
import CountdownInfo from './CountdownInfo';

const Header = ({ order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <View
      style={{
        paddingHorizontal: 12,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text style={styles.text} size={18} testID='order-serial'>
            {order.serial}
          </Text>
        </View>

        <View style={{ flexDirection: 'row' }}>
          <Button
            variant={variants.SWITCH}
            onPress={() => {
              dispatch(actions.orderHistory.selectOrderToTransferWithPermissionCheck(order));
            }}
          />
          <Button
            style={{ marginLeft: 12 }}
            variant={variants.DELETE}
            onPress={() => {
              dispatch(actions.orderHistory.deleteOrderWithPermissionCheck(order.id));
            }}
          />
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 15,
        }}
      >
        <Text size={30} style={styles.text}>{getTableName(order)}</Text>
        <Button
          variant={variants.CUSTOMER_COUNT}
          onPress={() => {
            dispatch(actions.app.toggleDialog(dialogNames.customerCount, true, {
              adults: order.adults,
              children: order.children,
              orderId: order.id,
            }));
          }}
        >
          <Text>
            <Text size={30} style={styles.text}>{order.customers}</Text>
            <Text size={20} style={styles.text}>{t('app.page.tableOrders.customer')}</Text>
          </Text>
        </Button>
      </View>

      {order.orderCutOffMins
        ? <CountdownInfo
            timeElapsed={moment().diff(order.createdAt, 'minute')}
            timeAllowed={order.orderCutOffMins}
          />
        : null}

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginVertical: 15,
        }}
      >
        <Text size={18} style={styles.subtext}>{moment(order.createdAt).format('HH:mm')}</Text>
        <Text size={18} style={styles.text}>${numberWithCommas(order.total, 0, 1)}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    fontWeight: 'bold',
  },
  subtext: {
    fontWeight: 'bold',
    color: colors.grey[500],
  },
});

export default Header;
