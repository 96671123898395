import { View } from 'react-native';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';

import logger, { logId } from '../libs/logger';

import Text from './Text';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    logger.log(`[error boundary] ${error.message}`, {
      merchantId: this.props.merchantId,
      permissions: this.props.permissions,
      waiterIdentifier: this.props.waiterIdentifier,
      merchantName: this.props.merchantName,
      isLogin: this.props.isLogin,
      stackTrace: error.stack,
      ...error,
    });
  }

  render() {
    const { t } = this.props;

    if (this.state.hasError) {
      return (
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            padding: 32,
          }}
        >
          <Text size={24} style={{ marginVertical: 12, fontWeight: 'bold' }}>
            {t('app.errorBoundary.title')}
          </Text>
          <Text size={20} style={{ marginVertical: 12 }}>
            {t('app.errorBoundary.body')}
          </Text>
          <Text size={28} style={{ marginVertical: 12, fontWeight: 'bold' }}>
            {t('app.errorBoundary.subTitle', { logId })}
          </Text>
        </View>
      );
    }

    return this.props.children;
  }
}

function mapStateToProps({ auth }) {
  return {
    permissions: auth.currentUser?.permissions,
    waiterIdentifier: auth.currentUser?.account,
    merchantId: auth.merchantId,
    merchantName: auth.merchantUserName,
    isLogin: auth.currentUser !== null,
  };
}

export default connect(mapStateToProps)(
  withTranslation()(ErrorBoundary),
);
