import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';
import moment from 'moment';

import Text from '@/components/Text';
import colors from '@/theme/colors';

const Header = ({ isCurrentBatch, batchNumber, createdAt }) => {
  const { t } = useTranslation();

  return (
    <View style={[
      styles.batchHeader,
      {
        backgroundColor: isCurrentBatch
          ? colors.batch.current
          : colors.batch.default,
      },
    ]}
    >
      <View style={{ flex: 1 }}>
        <Text
          size={14}
          style={[
            styles.batchTitle,
            {
              color: isCurrentBatch
                ? colors.white
                : colors.black,
            },
          ]}
        >
          {`${t('app.page.order.order')} #${batchNumber}`}
        </Text>
      </View>
      <Text
        size={14}
        style={[
          styles.batchTime,
          {
            color: isCurrentBatch
              ? colors.white
              : colors.black,
          },
        ]}
      >
        {moment(createdAt).format('HH:mm')}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  batchHeader: {
    flexDirection: 'row',
    height: 36,
    alignItems: 'center',
    paddingHorizontal: 12,
  },
  batchTitle: {
    color: colors.white,
    fontWeight: 'bold',
  },
  batchTime: {
    color: colors.white,
    textAlign: 'right',
    fontWeight: 'bold',
  },
});

export default Header;
