import React from 'react';

import CancelReasonDrawer from './CancelReasonDrawer';

const Drawers = () => {
  return (
    <>
      <CancelReasonDrawer />
    </>
  );
};

export default Drawers;
