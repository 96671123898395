import Constants from 'expo-constants';
import _ from 'lodash';
import moment from 'moment';
import orderappLibs from 'dimorder-orderapp-lib';

import i18n from '@/i18n';
import itemStatus from '@/constants/itemStatus';

const status = {
  PENDING: 'pending',
  CANCELLED: 'cancelled',
};

function convertBatches(user, order) {
  const deviceId = Constants.installationId;

  return order.batches.map((batch) => {
    const items = batch.items.map(item => {
      const modifiedItem = {
        ...item,
        setItems: item.setItems.map((setItem) => {
          return {
            ..._.pick(
              setItem,
              [
                'id',
                'key',
                'name',
                'desc',
                'menuId',
                'remarks',
                'modifiers',
                'options',
                'price',
                'priceUndetermined',
                'categoryId',
                'discount',
                'tags',
                'step',
                'setItems',
                'quantity',
              ],
            ),
            isRequired: false, // TODO:
            setMenuId: setItem.menuItemId,
            setMenuIndex: null,
          };
        }),

        cancelidentifier: null,
        cancelled: false,
        cancelledat: null,
        cancelreason: null,

        completeAt: null,
        printError: false,
        printed: true,
      };

      return modifiedItem;
    });
    const groupBatchItem = orderappLibs.libs.groupBatchItem(items);
    const calculatedBatchItem = groupBatchItem.map((item) => {
      return orderappLibs.libs.formatBatchItemStatus(orderappLibs.libs.calculateLocalBatchItem(item, order));
    });

    return {
      ...batch,
      items: calculatedBatchItem,
      identifier: user,
      submittedat: batch.createdAt,
      deviceId,
    };
  });
}

const localOrderToAppOrder = (merchant, user, order) => {
  const clone = _.cloneDeep(order);
  const now = new Date().toISOString();

  if (!clone.createdAt) {
    clone.createdAt = now;
  }
  clone.updatedAt = now;

  clone.items = [];
  clone.merchantAddress = merchant.address;
  clone.merchantId = merchant.id;
  clone.merchantName = merchant.name;
  clone.rounding = merchant.rounding;
  clone.merchantPortalRoundedTotal = 0;
  clone.displayStatusKey = 'waiting_pay';
  clone.takeawayInfo = {};

  clone.withholding = null;
  clone.valid = true;
  clone.orderCutOffMins = null;
  clone.orderReferId = '';

  clone.summary = orderappLibs.libs.formatSummary(clone, merchant.setting);

  if (!clone.gateway) {
    clone.gateway = '';
  }

  clone.batches = convertBatches(user, clone);

  // ? is this correct?
  clone.merchantPortalRoundedTotal = clone.total;
  clone.originalSubtotal = clone.total;
  clone.paidAmount = clone.total;
  clone.roundedRevenue = clone.total;

  return orderappLibs.libs.calculateLocalOrder(clone);
};

const getTableSuffix = subTableIndex => {
  if (subTableIndex === 0) {
    return '';
  }

  const offsetIdx = subTableIndex - 1;

  // 26 letters A-Z
  const s = offsetIdx.toString(26);
  let str = '';

  for (let i = 0; i < s.length; i++) {
    const codePoint = i === s.length - 1
      ? parseInt(s[i], 26) + 65
      : parseInt(s[i], 26) + 65 - 1; // account for the initial offset

    str += String.fromCodePoint(codePoint);
  }

  return `(${str})`;
};

const getTableName = (order) => {
  return order.table + getTableSuffix(order.subTable);
};

const getNextSubTableIndex = (orders, table) => {
  const ordersInTheSameTable = orders.filter(o => {
    return o.table === table && o.status === status.PENDING;
  });
  const subTableIndexMap = ordersInTheSameTable.reduce((acc, o) => {
    return { ...acc, [o.subTable]: true };
  }, {});

  let index = 0;

  for (let i = 0; i <= ordersInTheSameTable.length; i++) {
    if (!subTableIndexMap[i]) {
      index = i;
      break;
    }
  }

  return index;
};

const isWithinSpecifiedWeekdays = (weekdays) => {
  return !weekdays.length ||
  (weekdays.length && weekdays.includes(moment().day()));
};

const isWithinSpecifiedTimeRange = (timeRange) => {
  if (!timeRange.length) {
    return true;
  }

  const [timeRangeStart, timeRangeEnd] = timeRange;

  return moment().isBetween(
    moment(`${timeRangeStart.hour}:${timeRangeStart.minute}:${timeRangeStart.second}`, 'hh:mm:ss'),
    moment(`${timeRangeEnd.hour}:${timeRangeEnd.minute}:${timeRangeEnd.second}`, 'hh:mm:ss'),
  );
};

/**
 *
 * @param {IAppBatchItem} workingItem
 * @param {ISetStep} currentStep
 * @returns {number}
 */
const getSelectedItemCountInStep = (workingItem, currentStep) => {
  return _.sumBy(
    workingItem.setItems.filter(setItem => setItem.step === currentStep.key),
    'quantity',
  );
};

/**
 *
 * @param {IAppBatchItem} item
 * @param {IAppBatchItem} setItem
 * @returns {IAppBatchItem}
 */
const mergeSetItemIntoItem = (item, setItem) => {
  const itemCopy = _.cloneDeep(item);
  const setItemCopy = _.cloneDeep(setItem);
  const setItemIdx = itemCopy.setItems.findIndex(i => i.key === setItemCopy.key);

  if (setItemIdx > -1) {
    itemCopy.setItems[setItemIdx] = setItemCopy;
  } else {
    itemCopy.setItems.push(setItemCopy);
  }

  return itemCopy;
};

const isCategorySet = (category) => {
  return category.type === 'SET';
};

const getItemAttributes = (item) => {
  return [
    ...item.options.map(o => o.name + (o.quantity > 1 ? ` ( x ${o.quantity} )` : '')),
    ...item.tags.filter(t => t.id !== itemStatus.SERVE_LATER).map(t => t.name),
    ...item.remarks,
  ].join(' / ');
};

const isServeUp = (item) => {
  return item.tags.find(tag => tag.id === itemStatus.SERVE_UP);
};

const isServeLater = item => {
  return item.tags.find(tag => tag.id === itemStatus.SERVE_LATER) && !isServeUp(item);
};

const validateBatchItem = (batchItem, menuItem) => {
  if (!batchItem || !menuItem) {
    return null;
  }
  let invalidItem = null;
  // handle single item && setItem
  if (!batchItem.isSet) {
    // check options
    menuItem.options.forEach(menuOptionGroup => {
      if (menuOptionGroup.min > 0) {
        const optionGroupOptions = batchItem.options.filter(o => o.optionGroupId === menuOptionGroup.id);
        const optionGroupQuantity = _.sumBy(optionGroupOptions, 'quantity');
        if (optionGroupQuantity < menuOptionGroup.min) {
          invalidItem = {
            batchItemId: batchItem.id,
            message: ['app.page.order.validation.option'],
          };
        }
      }
    });
  } else { // handle set
    menuItem.steps.forEach(menuStep => {
      if (menuStep.min > 0) {
        const stepItems = batchItem.setItems.filter(o => o.step === menuStep.key);
        const stepItemsQuatity = _.sumBy(stepItems, 'quantity');
        if (stepItemsQuatity < menuStep.min) {
          invalidItem = {
            batchItemId: batchItem.id,
            message: ['app.page.order.validation.step'],
          };
        }
      }
    });
  }

  if (batchItem.priceUndetermined) {
    if (invalidItem) {
      invalidItem.message.push('app.page.order.validation.priceUndetermined');
    } else {
      invalidItem = {
        batchItemId: batchItem.id,
        message: ['app.page.order.validation.priceUndetermined'],
      };
    }
  }

  return invalidItem;
};

const validateItem = (menu, item) => {
  const errors = [];

  menu.options.forEach(menuOptionGroup => {
    if (menuOptionGroup.min > 0) {
      const optionGroupOptions = item.options.filter(o => o.optionGroupId === menuOptionGroup.id);
      const optionGroupQuantity = _.sumBy(optionGroupOptions, 'quantity');

      if (optionGroupQuantity < menuOptionGroup.min) {
        errors.push({
          id: item.id,
          message: 'app.page.order.validation.option',
        });
      }
    }
  });

  if (item.priceUndetermined) {
    errors.push({
      id: item.id,
      message: 'app.page.order.validation.priceUndetermined',
    });
  }

  return errors;
};

const validateSet = (set, setToValidate) => {
  if (!setToValidate) {
    return [];
  }

  const errors = [];
  const { setItems, id } = setToValidate;
  const setItemMenuItemIds = setItems.map(item => item.menuItemId);

  set.steps
    // filter steps with dependencies
    .filter(step => {
      const dependsOn = step.dependsOnSetMenuItems ?? [];

      if (!dependsOn.length) {
        return true;
      }

      return dependsOn.some(itemId => {
        return setItemMenuItemIds.includes(itemId);
      });
    })
    .forEach(menuStep => {
      if (menuStep.min > 0) {
        const stepItems = setItems.filter(o => o.step === menuStep.key);
        const stepItemsQuatity = _.sumBy(stepItems, 'quantity');

        if (stepItemsQuatity < menuStep.min) {
          errors.push({
            id,
            message: 'app.page.order.validation.step',
          });
        }
      }
    });

  setItems.forEach(item => {
    const setMenu = set.setMenus.find(sm => sm.menuId === item.menuId);

    if (setMenu) {
      errors.push(...validateItem(setMenu, item));
    }
  });

  return errors;
};

/**
 *
 * @param {IMenu} menu
 * @param {IAppBatchItem} item workingItem or workingSetItem
 * @param {boolean} isSetItem
 */
const isItemOptionCompleted = (menu, item, isSetItem) => {
  if (menu.options.length === 0) {
    return true;
  }

  const { options, quantity } = item;

  if (isSetItem && menu.min > 0 && quantity < menu.min) {
    return false;
  }

  for (let i = 0; i < menu.options.length; i++) {
    const menuOptionGroup = menu.options[i];

    if (menuOptionGroup.min > 0) {
      const optionGroupOptions = options.filter(o => o.optionGroupId === menuOptionGroup.id);
      const optionGroupQuantity = _.sumBy(optionGroupOptions, 'quantity');

      if (optionGroupQuantity < menuOptionGroup.min) {
        return false;
      }
    }
  }

  return true;
};

/**
 *
 * @param {IAppBatchItem} item
 * @returns {string}
 */
const getMenuPriceText = (item) => {
  if (item.priceUndetermined) {
    return i18n.t('app.constant.menu.priceUndetermined');
  }

  const price = item.price - item.discount;

  return Number.isNaN(price)
    ? ''
    : `$${item.price - item.discount}`;
};

const getStatusIconSource = (item) => {
  if (isServeLater(item)) {
    return require('@icons/order/pause.png');
  }

  if (isServeUp(item)) {
    return require('@icons/order/play.png');
  }

  return null;
};

export {
  status,
  localOrderToAppOrder,
  getTableSuffix,
  getTableName,
  getNextSubTableIndex,
  isWithinSpecifiedWeekdays,
  isWithinSpecifiedTimeRange,
  getSelectedItemCountInStep,
  mergeSetItemIntoItem,
  isCategorySet,
  getItemAttributes,
  isServeUp,
  isServeLater,
  validateBatchItem,
  isItemOptionCompleted,
  validateItem,
  validateSet,
  getMenuPriceText,
  getStatusIconSource,
};
