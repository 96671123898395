import _ from 'lodash';
import produce from 'immer';

import { loadingKey } from '@/constants/app';

import ActionTypes from './ActionTypes';
import ActionTypesAuth from '../auth/ActionTypes';

/** @type {IModal} */
const initialModal = {
  open: false,
  data: {},
};

/** @type {IModal} */
const initialDrawer = {
  open: false,
  data: {},
};

/** @type {IDialog} */
const initalDialogs = {
  systemAdmin: initialModal,
  env: initialModal,
  codePushChannel: initialModal,
  codePushProgress: initialModal,
  customerCount: initialModal,
  error: initialModal,
  accountPermission: initialModal,
};

/** @type {IDrawers} */
const initalDrawers = {
  cancelReason: initialDrawer,
};

/** @type {IAppState} */
export const initialState = {
  isInit: false,
  dialogs: initalDialogs,
  drawers: initalDrawers,
  loadingActions: [],
  env: '',
  isCodePushing: false,
};

export default produce(
  /**
   * @param {IAppState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypesAuth.USER_LOGOUT: {
        return { ...initialState, isInit: true };
      }

      case ActionTypes.INIT: {
        draft.isInit = true;
        break;
      }

      case ActionTypes.UPDATE_CODEPUSH_STATUS: {
        draft.isCodePushing = action.payload;
        break;
      }

      case ActionTypes.RESET_DIALOGS: {
        draft.dialogs = initalDialogs;
        break;
      }

      case ActionTypes.RESET_DRAWERS: {
        draft.drawers = initalDrawers;
        break;
      }

      case ActionTypes.TOGGLE_DIALOG: {
        const { dialog, open, data } = action.payload;
        if (_.isUndefined(open) && _.isUndefined(data)) {
          draft.dialogs[dialog].open = !draft.dialogs[dialog].open;
          draft.dialogs[dialog].data = {};
          break;
        }
        if (_.isBoolean(open)) {
          draft.dialogs[dialog].open = open;
        }
        if (!_.isNil(data)) {
          draft.dialogs[dialog].data = data;
        }
        break;
      }

      case ActionTypes.TOGGLE_DRAWER: {
        const { drawer, open, data } = action.payload;
        if (_.isUndefined(open) && _.isUndefined(data)) {
          draft.drawers[drawer].open = !draft.dialogs[drawer].open;
          draft.drawers[drawer].data = {};
          break;
        }
        if (_.isBoolean(open)) {
          draft.drawers[drawer].open = open;
        }
        if (!_.isNil(data)) {
          draft.drawers[drawer].data = data;
        }
        break;
      }

      case ActionTypes.UPDATE_LOADING: {
        const { key, remove } = action.payload;
        if (remove) {
          draft.loadingActions = draft.loadingActions.filter(
            (loading) => loading.key !== key,
          );
        } else {
          draft.loadingActions.push({
            key,
          });
        }
        break;
      }

      case ActionTypes.ENV_UPDATED: {
        draft.env = action.payload;
        break;
      }

      default:
        return draft;
    }
  },
  initialState,
);
