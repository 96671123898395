export default {
  UPDATE_DEPLOYMENT: 'CODEPUSH/UPDATE_DEPLOYMENT',
  UPDATE_OVERWROTE_DEPLOYMENT: 'CODEPUSH/UPDATE_OVERWROTE_DEPLOYMENT',
  UPDATE_PACKAGE_META: 'CODEPUSH/UPDATE_PACKAGE_META',
  UPDATE_DIALOG_OPEN: 'CODEPUSH/UPDATE_DIALOG_OPEN',
  UPDATE_STATUS: 'CODEPUSH/UPDATE_STATUS',
  UPDATE_PROGRESS: 'CODEPUSH/UPDATE_PROGRESS',
  UPDATE_ERROR: 'CODEPUSH/UPDATE_ERROR',
  UPDATE_CHECK_UPDATE_AT: 'CODEPUSH/UPDATE_CHECK_UPDATE_AT',
};
