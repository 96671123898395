import { StyleSheet, View } from 'react-native';
import React from 'react';
import Row from './Layouts/Row';
import Text from './Text';
import colors from '../theme/colors';
import shadows from '../theme/shadows';

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    paddingHorizontal: 16,
    borderRadius: 8,
    height: 55,
    width: '100%',
    justifyContent: 'center',
    ...shadows.slight,
  },
});

export default function ActionBar ({ title, children, style }) {
  return (
    <Row style={{ ...styles.container, ...style }}>
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <Text style={{ fontWeight: 'bold' }}>{title}</Text>
      </View>
      <View style={{ alignItems: 'flex-end', justifyContent: 'center' }}>
        {children}
      </View>
    </Row>
  );
}
