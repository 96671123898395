import { View } from 'react-native';
import React, { useEffect } from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import { getEnv } from '@/libs/env';
import { loadingKey } from '@/constants/app';
import { setUrl } from '@/libs/api/dimorder';
import Loading from '@/components/Loading';
import colors from '@/theme/colors';

const ConfigGate = ({ children }) => {
  const dispatch = useDispatch();
  const env = useSelector(state => state.app.env);

  useEffect(() => {
    const initialize = async () => {
      dispatch(actions.app.openLoading(loadingKey.CONFIG));

      const environment = await getEnv();

      setUrl(environment);
      dispatch(actions.app.updateEnv(environment));
      dispatch(actions.app.closeLoading(loadingKey.CONFIG));
    };

    if (!env) {
      initialize();
    }
  }, [dispatch, env]);

  if (!env) {
    return (
      <View style={{ flex: 1 }}>
        <Loading overlayStyle={{ backgroundColor: colors.secondary.main }} />
      </View>
    );
  }

  return children;
};

export default ConfigGate;
