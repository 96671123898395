import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { useHistory } from '@/libs/reactRouter';
import LinearGradient from '@/components/RNLinearGradient';
import NavBar from '@/components/NavBar';
import NavTab from '@/components/NavTab';
import colors from '@/theme/colors';

import { variantKey } from '../variantKey';
import OrderList from '../OrderList';

const styles = StyleSheet.create({
  navBar: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-end',
    height: 43,
  },
});

export default function MyOrders (props) {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <View style={{ flex: 1 }}>
      <NavBar
        title={t('app.page.orderHistory.title')}
        backButton
      />

      <View style={{ width: '100%', height: 43 }}>
        <LinearGradient
          colors={[colors.primary.main, colors.primary.light]}
          start={[0, 1]}
          end={[1, 0]}
          style={[
            styles.navBar,
          ]}
        >
          <NavTab
            isActive
            title={t('app.page.orderHistory.myOrder')}
            style={{ zIndex: 1 }}
          />

          <NavTab
            title={t('app.page.orderHistory.allOrder')}
            onPress={() => { history.replace('/orderhistory/allorders'); }}
            style={{ zIndex: 0 }}
          />
        </LinearGradient>
      </View>

      <View style={{ flex: 1, backgroundColor: colors.secondary.light }}>
        <OrderList variant={variantKey.MYORDER} />
      </View>
    </View>
  );
}
