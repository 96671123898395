import { Dimensions, StyleSheet, TouchableOpacity, View } from 'react-native';
import React from 'react';
import Text from '@/components/Text';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  tagContainer: {
    flex: 1,
    width: (Dimensions.get('window').width - 44) / 3,
    height: 110,
    minHeight: 110,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 4,
  },
  text: {
    fontWeight: 'bold',
  },
});

export default function TagButton (props) {
  const { onPress, text, selected } = props;

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={[
        styles.tagContainer,
        {
          backgroundColor: selected
            ? colors.primary.main
            : colors.white,
        },
      ]}
      >
        <Text size={18} style={styles.text}>{text}</Text>
      </View>
    </TouchableOpacity>
  );
}
