import { StyleSheet, View } from 'react-native';
import React from 'react';

export default function SettingItemRightAction (props) {
  const { style, children } = props;
  return (
    <View style={[styles.container, style]}>
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 6,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});
