import { StyleSheet } from 'react-native';
import React from 'react';

import { useSelector } from '@/redux';

import Batch from '../Batch';

const LatestBatch = () => {
  const batchItems = useSelector(state => [...state.order.workingBatch.items].reverse());

  return (
    <Batch
      testID='latest-batch'
      batchItems={batchItems}
      editable
      isItemInCurrentBatch
      autoScroll
    />
  );
};

export default LatestBatch;

const styles = StyleSheet.create({});
