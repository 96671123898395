import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { getItemAttributes } from '@/libs/order';
import { numberWithCommas } from '@/libs/numberWithCommas';
import { selectors, useSelector } from '@/redux';
import Text from '@/components/Text';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  itemContainer: {
    flex: 1,
    paddingHorizontal: 12,
    paddingVertical: 4,
    flexDirection: 'row',
  },
  attributeContainer: {
    flex: 1,
    paddingHorizontal: 12,
    paddingVertical: 2,
    flexDirection: 'row',
  },
  contentContainer: {
    justifyContent: 'center',
  },
  icon: {
    width: 15,
    height: 15,
  },
  text: {
    fontWeight: 'bold',
  },
  attributeText: {
    flex: 9,
    paddingRight: 10,
    marginLeft: 5,
  },
  nameText: {
    fontWeight: 'bold',
    marginLeft: 5,
  },
});

const cancelledStyle = { color: colors.cancelled.text };

const SetItemName = ({ setItem }) => {
  const { t } = useTranslation();
  const setItemName = useSelector(state => {
    return selectors.menu.selectMenuNameByLocale(state, setItem);
  });

  const nameTextStyle = setItem.cancelled ? [styles.nameText, cancelledStyle] : styles.nameText;

  return (
    <Text style={nameTextStyle} size={16}>
      <Text>{setItemName}</Text>
      {setItem.cancelled && <Text style={{ color: colors.cancelled.tag }}> {t('app.constant.menu.cancelled')}</Text>}
    </Text>
  );
};

export default function SetItem ({ setItem }) {
  const { t } = useTranslation();

  const priceUndeterminedText = t('app.constant.menu.priceUndetermined');

  const setItemAttributes = getItemAttributes(setItem);

  const textStyle = setItem.cancelled ? [styles.text, cancelledStyle] : styles.text;
  const attrTextStyle = setItem.cancelled ? [styles.attributeText, cancelledStyle] : styles.attributeText;

  return (
    <View>
      <View style={styles.itemContainer}>
        <View style={{ flex: 1 }} />

        <View style={{ flex: 6, justifyContent: 'center' }}>
          <SetItemName setItem={setItem} />
        </View>

        <View style={{ flex: 1, alignItems: 'flex-end', justifyContent: 'center' }}>
          <Text style={textStyle} size={16}>{setItem.quantity}</Text>
        </View>

        <View style={{ flex: 2, alignItems: 'flex-end', justifyContent: 'center' }}>
          <Text style={textStyle} size={16}>{setItem.priceUndetermined ? priceUndeterminedText : `$${numberWithCommas(setItem.total)}`}</Text>
        </View>
      </View>

      <View style={[styles.attributeContainer, { height: setItemAttributes ? null : 0 }]}>
        <View style={{ flex: 1 }} />
        <Text size={16} style={attrTextStyle}>{setItemAttributes}</Text>
      </View>
    </View>
  );
}
