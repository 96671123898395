import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';
import TableGroupSelector from './TableGroupSelector';
import Text from '@/components/Text';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  container: {
    height: 80,
    width: '100%',
    paddingHorizontal: 15,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default function Header (props) {
  const { t } = useTranslation();
  return (
    <View style={styles.container}>
      <Text size={20} style={{ fontWeight: 'bold', flex: 1 }}>{t('app.page.transferTable.selectTableGroup')}</Text>
      <View style={{ flex: 1, alignItems: 'flex-end' }}>
        <TableGroupSelector />
      </View>
    </View>
  );
}
