import { Animated, TouchableWithoutFeedback } from 'react-native';
import React, { useEffect, useRef } from 'react';
import colors from '@/theme/colors';
import shadows from '@/theme/shadows';

const RATIO = 1.8; // 長(1)寬(2)比
const PADDING = 1.8;

export default function ToggleSwitch ({ value, onChangeValue, size = 30 }) {
  const animation = useRef(new Animated.Value(0)).current;

  const trackStyles = {
    height: size,
    width: size * RATIO,
    borderRadius: size / 2,
    justifyContent: 'center',
    padding: PADDING,
    backgroundColor: animation.interpolate({
      inputRange: [0, 1],
      outputRange: [colors.toggle.off, colors.toggle.on], // 動畫顏色改變
    }),
  };

  const thumbStyles = {
    height: size - PADDING * 2,
    width: size - PADDING * 2,
    backgroundColor: colors.white,
    borderRadius: (size - PADDING * 2) / 2,
    ...shadows.default,
    transform: [
      {
        translateX: animation.interpolate({
          inputRange: [0, 1],
          outputRange: [0, size * (RATIO - 1)], // 動畫水平滑動
        }),
      },
    ],
  };

  useEffect(() => {
    Animated.timing(
      animation,
      {
        toValue: value ? 1 : 0,
        duration: 250,
        useNativeDriver: false,
      },
    ).start();
  }, [value, animation]);

  return (
    <TouchableWithoutFeedback
      onPress={onChangeValue}
    >
      <Animated.View style={trackStyles}>
        <Animated.View style={thumbStyles} />
      </Animated.View>
    </TouchableWithoutFeedback>
  );
}
