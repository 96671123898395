import { StyleSheet, TouchableOpacity, View } from 'react-native';
import React from 'react';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    minHeight: 60,
    justifyContent: 'center',
    alignItems: 'stretch',
    backgroundColor: colors.white,
    paddingHorizontal: 15,
    paddingVertical: 5,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 60,
  },
  divider: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 1,
    borderBottomWidth: 1.5,
    borderColor: colors.divider,
  },
});

export default function SettingItem({
  divider,
  onPress,
  disabled,
  children,
  touchableProps = {},
}) {
  return (
    <View style={
      styles.container
    }
    >
      <TouchableOpacity
        onPress={onPress}
        disabled={disabled || !onPress}
        {...touchableProps}
      >
        <View style={styles.contentContainer}>
          {children}
        </View>
      </TouchableOpacity>
      {divider && <View style={styles.divider} />}
    </View>
  );
}
