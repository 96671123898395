import produce from 'immer';

import { fontSizeKey, orderModes, settingKey } from '@/constants/app';

import ActionTypes from './ActionTypes';
import ActionTypesAuth from '../auth/ActionTypes';

/** @type {ISettingState} */
export const initialState = {
  isInit: false,
  path: settingKey.MAIN,
  optionConfig: { // 子設定選擇頁面
    visible: false,
    onChangeAction: null,
    options: null,
  },
  systemSettings: {
    lang: 'zh-HK',
    menuLang: 'kitchen',
    fontSize: fontSizeKey.MIDDLE,
  },
  orderMode: orderModes.ITEM_FIRST,
};

export default produce(
  /**
   * @param {ISettingState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypesAuth.USER_LOGOUT: {
        return initialState;
      }
      case ActionTypes.INIT: {
        draft.isInit = true;
        break;
      }
      case ActionTypes.UPDATE_SETTING_PATH: {
        const { key } = action.payload;
        draft.path = key;
        break;
      }
      case ActionTypes.SHOW_SETTING_OPTIONS: {
        draft.optionConfig = { visible: true, ...action.payload.config };
        break;
      }
      case ActionTypes.CLOSE_SETTING_OPTIONS: {
        draft.optionConfig = initialState.optionConfig;
        break;
      }
      case ActionTypes.UPDATE_LANGUAGE: {
        const { lang } = action.payload;
        draft.systemSettings.lang = lang;
        break;
      }
      case ActionTypes.CHANGE_FONT_SIZE: {
        const { size } = action.payload;
        draft.systemSettings.fontSize = size;
        break;
      }
      case ActionTypes.CHANGE_ORDER_MODE: {
        draft.orderMode = action.payload;
        break;
      }
      case ActionTypes.UPDATE_MENU_LANGUAGE: {
        draft.systemSettings.menuLang = action.payload;
        break;
      }
    }
  },
  initialState,
);
