/** @param {IRootState} state */
export const selectTableGroups = (state) => {
  const tableGroups = state.merchant.data.tableGroups ?? {};

  return Object.entries(tableGroups)
    .map(([name, tables]) => {
      return { name, tables };
    })
    .filter(g => g.tables.length > 0);
};
