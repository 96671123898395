import { Dimensions, StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import CenterModal from '@/components/Modals/CenterModal';
import Row from '@/components/Layouts/Row';
import colors from '@/theme/colors';

import { dialogNames } from '../constants';
import Button from '../../Button';
import CustomerCountBar from './CustomerCountBar';
import Text from '../../Text';

const CustomerCountDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { open, data } = useSelector(state => state.app.dialogs.customerCount);

  const [adults, setAdults] = useState(String(data.adults));
  const [children, setChildren] = useState(String(data.children));

  useEffect(() => {
    setAdults(String(data.adults));
    setChildren(String(data.children));
  }, [data]);

  const handleClose = () => {
    dispatch(actions.app.toggleDialog(dialogNames.customerCount, false));
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleConfirm = () => {
    dispatch(actions.order.updateCustomerCount(data.orderId, Number(adults), Number(children)));
    dispatch(actions.app.toggleDialog(dialogNames.customerCount, false));
  };

  return (
    <CenterModal
      visible={open}
      enablePressOutsideClose
      title={t('app.dialogs.customerCount.title')}
      contentContainerStyle={styles.container}
      onClose={handleClose}
    >
      <Row style={{ marginTop: 10 }}>
        <CustomerCountBar
          customerCount={String(adults)}
          onChangeCustomerCount={setAdults}
          title={t('app.dialogs.customerCount.adult')}
          autoFocus
        />
      </Row>
      <Row style={{ marginVertical: 10 }}>
        <CustomerCountBar
          customerCount={String(children)}
          onChangeCustomerCount={setChildren}
          title={t('app.dialogs.customerCount.children')}
        />
      </Row>
      <Row style={styles.footerBtn}>
        <View style={{ marginHorizontal: 5 }}>
          <Button
            style={styles.cancelButton}
            onPress={handleCancel}
          >
            <Text size={16} style={styles.buttonText}>{t('app.common.cancel')}</Text>
          </Button>
        </View>
        <View style={{ marginHorizontal: 5 }}>
          <Button
            style={styles.confirmButton}
            onPress={handleConfirm}
          >
            <Text size={16} style={styles.buttonText}>{t('app.common.confirm')}</Text>
          </Button>
        </View>
      </Row>
    </CenterModal>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  confirmButton: {
    borderColor: colors.button.main,
    borderWidth: 2,
    backgroundColor: colors.button.main,
    height: 41,
    borderRadius: 10,
    width: (Dimensions.get('window').width * 0.8 - 60) / 2,
  },
  cancelButton: {
    borderColor: colors.button.main,
    borderWidth: 2,
    backgroundColor: colors.white,
    width: (Dimensions.get('window').width * 0.8 - 60) / 2,
    height: 41,
    borderRadius: 10,
  },
  buttonText: {
    color: colors.black,
    fontWeight: 'bold',
  },
});

export default CustomerCountDialog;
