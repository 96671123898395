import { Vibration } from 'react-native';
import moment from 'moment';

import { status } from '@/libs/order';

import ActionTypes from './ActionTypes';

/**
 *
 * @param {IAppOrder[]} order
 * @returns {ThunkFunction}
 */
export const receiveInitialOrders = (orders) => {
  return (dispatch, getState) => {
    const currentUsername = getState().auth.currentUser?.account;

    dispatch({
      type: ActionTypes.INITIAL_ORDERS_RECEIVED,
      payload: { orders, currentUsername },
    });
  };
};

/**
 *
 * @param {IAppOrder} order
 * @returns {ThunkFunction}
 */
export const orderReceived = (order) => {
  return (dispatch, getState) => {
    if (order.status !== status.PENDING) {
      return;
    }

    const { read, unread } = getState().notifications.kitchenCompleted;
    const currentUsername = getState().auth.currentUser?.account;
    const now = new Date();
    let shouldVibrate = false;

    order.batches.forEach((b) => {
      if (b.identifier !== currentUsername) {
        return;
      }

      b.items.forEach(item => {
        if (!item.kitchenCompletedAt) {
          return;
        }

        if (
          !(read[item.key] || unread[item.key]) ||
          moment(item.kitchenCompletedAt).isAfter(now)
        ) {
          shouldVibrate = true;
        }
      });
    });

    if (shouldVibrate) {
      Vibration.vibrate(1000);
    }

    dispatch({
      type: ActionTypes.ORDER_RECEIVED,
      payload: { order, currentUsername },
    });
  };
};

/**
 *
 * @param {IAppBatchItem} item
 * @returns {IAction}
 */
export const readKitchenCompletedMessages = (item) => {
  return {
    type: ActionTypes.READ_KITCHEN_COMPLETED_MESSAGES,
    payload: {
      readAt: new Date().toISOString(),
      item,
    },
  };
};

/**
 * @return {ThunkFunction}
 */
export const prune = () => {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.PRUNE,
      payload: {
        time: new Date().toISOString(),
        orders: getState().orderHistory.orders,
      },
    });
  };
};
