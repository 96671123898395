import { Platform } from 'react-native';
import Constants from 'expo-constants';
import Logger from 'dimorder-orderapp-lib/dist/libs/Logger';
import * as Device from 'expo-device';

import packageJson from '@root/package.json';
import shortCode from '@/libs/shortCode';

export const logId = shortCode.generate(4);

const gcpLogger = new Logger('https://orderapp-logger-ssj7hdeukq-de.a.run.app/log', {
  buildNumber: Platform.OS !== 'web' ? `${Constants.nativeAppVersion} (${Constants.nativeBuildVersion})` : packageJson.version,
  appVersion: packageJson.version,
  platform: `${Device.osName} ${Device.osVersion}`,
  model: `${Device.manufacturer} ${Device.modelName}`,
  client: 'DIMPOS_WAITER',
  deviceId: Constants.installationId,
  environment: process.env.NODE_ENV,
  logId,
});

const nativeSupportLogLevel = [
  'debug',
  'error',
  'info',
  'log',
  'trace',
  'warn',
];

const logger = {};

nativeSupportLogLevel.forEach(level => {
  logger[level] = (...args) => {
    const [message, data] = args;
    console.log(...args);

    gcpLogger.log(level, {
      message: message,
      ...data,
    });
  };
});

export function setEnv (env) {
  gcpLogger.labels.environment = env;
}

export function setMerchantId (merchantId) {
  gcpLogger.labels.merchantId = merchantId;
}

/**
 * @param {string} deployment - 目前安裝的 codepush update 的 label
 */
export function setCodePushLabel (codePushLabel) {
  gcpLogger.labels.codePushLabel = codePushLabel;
}

/**
 * @param {string} deployment - 目前安裝的 codepush update 的 deployment
 */
export function setCodePushDeployment (deployment) {
  gcpLogger.labels.deployment = deployment;
}

/**
 * @param {string} deployment - 已設定的 overwrite deployment
 */
export function setCodePushOverwriteDeployment (deployment) {
  gcpLogger.labels.overwriteDeployment = deployment;
}

export default logger;
