import { ActivityIndicator, Platform, StyleSheet, View } from 'react-native';
import { useSelector } from '../redux';
import React from 'react';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: colors.black,
    opacity: 0.5,
  },
});

export default function Loading ({ overlayStyle }) {
  const loadingActions = useSelector(state => state.app.loadingActions);
  const visible = loadingActions?.length > 0;

  if (!visible) return null;

  return (
    <View style={styles.container}>
      <View style={[styles.overlay, overlayStyle]} />
      <ActivityIndicator color={colors.primary.main} size={Platform.OS === 'android' ? 60 : 'large'} />
    </View>
  );
}
