import _ from 'lodash';
import produce from 'immer';

import ActionTypes from './ActionTypes';
import ActionTypesAuth from '../auth/ActionTypes';

/** @type {IOrderHistoryState} */
export const initialState = {
  orders: [],
  selectedTransferTableGroupIndex: 0,
  orderToTransfer: null,
};

export default produce(
  /**
   * @param {IOrderHistoryState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action.type) {
      case ActionTypesAuth.USER_LOGOUT: {
        return initialState;
      }

      case ActionTypes.UPDATE_ORDER: {
        const idx = draft.orders.findIndex(o => o.id === action.payload.id);

        if (idx > -1) {
          draft.orders[idx] = action.payload;
        } else {
          draft.orders.push(action.payload);
        }

        break;
      }

      case ActionTypes.UPDATE_ORDERS: {
        const orders = action.payload;

        draft.orders = _.uniqBy([
          ...orders,
          ...draft.orders,
        ], 'id');

        break;
      }

      case ActionTypes.DELETE_ORDER: {
        draft.orders = draft.orders.filter(o => o.id !== action.payload);
        break;
      }

      case ActionTypes.SELECT_TRANSFER_TABLE_GROUP: {
        draft.selectedTransferTableGroupIndex = action.payload;
        break;
      }

      case ActionTypes.SELECT_ORDER_TO_TRANSFER: {
        draft.orderToTransfer = action.payload;
        break;
      }

      default: {
        return draft;
      }
    }
  },
  initialState,
);
