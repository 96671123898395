import { Dimensions, StyleSheet, TouchableOpacity, View } from 'react-native';
import React from 'react';
import Text from '@/components/Text';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  activeContainer: {
    width: Dimensions.get('window').width / 2,
    height: 43,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    backgroundColor: colors.secondary.light,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
  },
  container: {
    width: Dimensions.get('window').width / 2,
    height: 38,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    backgroundColor: colors.tab.light,
    justifyContent: 'center',
    alignItems: 'center',
  },
  activeText: {
    fontWeight: 'bold',
    color: colors.black,
  },
  text: {
    fontWeight: 'bold',
    color: colors.black + '65',
  },
  activeEdge: {
    width: (Dimensions.get('window').width / 2) + 16 * 2,
    height: 16,
    backgroundColor: colors.secondary.light,
    position: 'absolute',
    bottom: -0.5,
    left: -16,
  },
  mask: {
    height: 16,
    width: 16,
    backgroundColor: colors.tab.light,
    position: 'absolute',
  },
});

export default function NavTab({
  isActive = false,
  onPress,
  title,
  style,
}) {
  return (
    <TouchableOpacity activeOpacity={1} onPress={isActive ? () => {} : onPress} style={style}>
      <View style={isActive ? styles.activeContainer : styles.container}>
        <Text size={isActive ? 18 : 16} style={isActive ? styles.activeText : styles.text}>
          {title}
        </Text>
      </View>

      {/* 邊緣圓角 */}
      {isActive &&
        <View style={styles.activeEdge}>
          <View style={[styles.mask, { borderBottomLeftRadius: 16, right: 0 }]} />
          <View style={[styles.mask, { borderBottomRightRadius: 16, left: 0 }]} />
        </View>}
    </TouchableOpacity>
  );
}
