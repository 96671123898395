import { View } from 'react-native';
import React from 'react';

const Row = ({ children, style, ...rest }) => {
  return (
    <View style={{ flexDirection: 'row', ...style }} {...rest}>
      {children}
    </View>
  );
};

export default Row;
