import { FlatList } from 'react-native';
import React from 'react';
import _ from 'lodash';

import { actions, selectors, useDispatch, useSelector } from '@/redux';
import { getMenuPriceText } from '@/libs/order';
import { listNumColumns, orderModes } from '@/constants/app';
import { padToMatchMultiple } from '@/libs/array';
import { useHistory } from '@/libs/reactRouter';
import ItemBox from '@/components/ItemBox';
import themeStyle from '@/theme/themeStyle';

export default function MenuList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const categoryWithMenus = useSelector(selectors.menu.selectCategoryWithMenus);
  const { categoryId: selectedCategoryId } = useSelector(selectors.order.selectOrderSelections);
  const orderMode = useSelector(state => state.setting.orderMode);

  const category = categoryWithMenus.categories.find(cat => cat.id === selectedCategoryId);
  const menus = categoryWithMenus.menus.filter(menu => menu.categoryId === selectedCategoryId && !menu.deleted);

  const listData = category
    ? [
        ...category.categories
          .filter(cat => !cat.disabled)
          .map(cat => ({ ...cat, isMenuItem: false })),
        ...menus.map(cat => ({ ...cat, isMenuItem: true })),
      ]
    : menus
      .map(cat => ({ ...cat, isMenuItem: true }));

  const renderItem = ({ item }) => {
    const handleLongPress = () => {
      if (orderMode === orderModes.ITEM_FIRST) {
        handlePress(null, orderModes.OPTION_FIRST);
      } else if (orderMode === orderModes.OPTION_FIRST) {
        handlePress(null, orderModes.ITEM_FIRST);
      }
    };
    const handlePress = (e, mode) => {
      if (!item.isMenuItem) {
        dispatch(actions.order.addSelection({ categoryId: item.id }));
        history.push('/order/menus');
        return;
      }

      if (!mode) {
        mode = orderMode;
      }

      if (mode === orderModes.ITEM_FIRST) {
        dispatch(actions.order.createLocalItem(item));
        dispatch(actions.order.addItemToBatch());
      } else {
        dispatch(actions.order.addSelection({ menuId: item.id }));
        dispatch(actions.order.createLocalItem(item));
        history.push('/order/menus/options');
      }
    };

    return (
      <ItemBox
        key={item.id}
        item={item}
        isSoldOut={item.sold}
        disabled={item.sold}
        height={themeStyle.box.height.normal}
        caption={getMenuPriceText(item)}
        onPress={handlePress}
        onLongPress={handleLongPress}
      />
    );
  };

  return (
    <FlatList
      testID='order-menus-list'
      data={padToMatchMultiple(listData, listNumColumns)}
      renderItem={renderItem}
      numColumns={listNumColumns}
      showsVerticalScrollIndicator={false}
      initialNumToRender={4}
      maxToRenderPerBatch={1}
    />
  );
}
