import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { ENV } from '@/configs/env';
import { actions, useDispatch, useSelector } from '@/redux';
import { overwriteEnv } from '@/libs/app';
import CenterModal from '@/components/Modals/CenterModal';

import { dialogNames } from './constants';

const EnvDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.app.dialogs.env.open);

  const handleClose = () => {
    dispatch(actions.app.toggleDialog(dialogNames.env, false));
  };

  return (
    <CenterModal
      visible={isOpen}
      enablePressOutsideClose
      title={t('app.dialogs.env.title')}
      containerStyle={{ width: '80%' }}
      contentContainerStyle={styles.container}
      onClose={handleClose}
    >
      {ENV.map(env => {
        return (
          <TouchableOpacity
            key={env.value}
            style={styles.button}
            onPress={() => {
              overwriteEnv(env.value);
              handleClose();
            }}
          >
            <Text style={{ fontSize: 20 }}>{env.label}</Text>
          </TouchableOpacity>
        );
      })}
    </CenterModal>
  );
};

const styles = StyleSheet.create({
  container: {
    minWidth: '80%',
    maxWidth: 450,
  },
  buttonContainer: {
    flexDirection: 'row',
  },
  button: {
    margin: 12,
    width: '100%',
    paddingVertical: 4,
    alignItems: 'center',
  },
  input: {
    borderWidth: 1,
    padding: 12,
    margin: 20,
    width: '100%',
  },
});

export default EnvDialog;
