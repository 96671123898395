import { FlatList, StyleSheet } from 'react-native';
import React from 'react';

import { selectors, useSelector } from '@/redux';
import { status } from '@/libs/order';

import OrderCard from './OrderCard';

const OrderList = () => {
  const { orders } = useSelector(state => state.orderHistory);
  const { table: selectedTable } = useSelector(selectors.order.selectOrderSelections);

  const tableOrders = orders
    .filter(o => o.table === selectedTable && o.status === status.PENDING)
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  return (
    <FlatList
      showsVerticalScrollIndicator={false}
      style={styles.list}
      contentContainerStyle={styles.contentContainer}
      data={tableOrders}
      renderItem={({ item: order }) => {
        return (
          <OrderCard key={order.id} order={order} />
        );
      }}
    />
  );
};

const styles = StyleSheet.create({
  list: {
    flex: 1,
  },
  contentContainer: {
    paddingTop: 4,
    paddingBottom: 32,
    paddingHorizontal: 12,
  },
});

export default OrderList;
