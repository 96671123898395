export default {
  INIT: 'SETTING/INIT',
  UPDATE_SETTING_PATH: 'SETTING/UPDATE_SETTING_PATH',
  SHOW_SETTING_OPTIONS: 'SETTING/SHOW_SETTING_OPTIONS',
  CLOSE_SETTING_OPTIONS: 'SETTING/CLOSE_SETTING_OPTIONS',
  UPDATE_LANGUAGE: 'SETTING/UPDATE_LANGUAGE',
  CHANGE_FONT_SIZE: 'SETTING//CHANGE_FONT_SIZE',
  CHANGE_ORDER_MODE: 'SETTING/CHANGE_ORDER_MODE',
  UPDATE_MENU_LANGUAGE: 'SETTING/UPDATE_MENU_LANGUAGE',
};
