import * as auth from './auth/selectors';
import * as menu from './menu/selectors';
import * as merchant from './merchant/selectors';
import * as notifications from './notifications/selectors';
import * as order from './order/selectors';

export default {
  merchant,
  menu,
  order,
  notifications,
  auth,
};
