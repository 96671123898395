import { StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import CenterModal from '@/components/Modals/CenterModal';
import Row from '@/components/Layouts/Row';
import colors from '@/theme/colors';

import { dialogNames } from './constants';
import Button from '../Button';
import Text from '../Text';

const ErrorDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { open, data } = useSelector(state => state.app.dialogs.error);

  const handleClose = () => {
    dispatch(actions.app.toggleDialog(dialogNames.error, false));
  };

  const handleConfirm = () => {
    handleClose();
  };

  return (
    <CenterModal
      visible={open}
      enablePressOutsideClose
      title={data.title}
      contentContainerStyle={styles.container}
      onClose={handleClose}
    >
      <Row style={{ padding: 20 }}>
        <Text size={18}>{data.message}</Text>
      </Row>
      <Row style={{ alignItems: 'center', paddingTop: 20 }}>
        <Button
          style={styles.confirmButton}
          onPress={handleConfirm}
        >
          <Text size={16} style={styles.buttonText}>{t('app.common.confirm')}</Text>
        </Button>
      </Row>
    </CenterModal>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: '95%',
    minHeight: 80,
    flexDirection: 'column',
    alignSelf: 'center',
  },
  confirmButton: {
    borderColor: colors.button.main,
    borderWidth: 2,
    backgroundColor: colors.button.main,
    height: 41,
    borderRadius: 10,
    width: 200,
  },
  buttonText: {
    color: colors.black,
    fontWeight: 'bold',
  },
});

export default ErrorDialog;
