import { Image, StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { getStatusIconSource } from '@/libs/order';
import { numberWithCommas } from '@/libs/numberWithCommas';
import { selectors, useSelector } from '@/redux';
import Text from '@/components/Text';
import colors from '@/theme/colors';

import SetItem from './SetItem';

const styles = StyleSheet.create({
  itemContainer: {
    flex: 1,
    paddingHorizontal: 12,
    paddingVertical: 11,
    flexDirection: 'row',
  },
  contentContainer: {
    justifyContent: 'center',
  },
  icon: {
    width: 15,
    height: 15,
  },
  text: {
    fontWeight: 'bold',
  },
});

const cancelledStyle = { color: colors.cancelled.text };

const SetName = ({ set }) => {
  const { t } = useTranslation();
  const setName = useSelector(state => {
    return selectors.menu.selectMenuNameByLocale(state, set);
  });

  const textStyle = set.cancelled ? [styles.text, cancelledStyle] : styles.text;

  return (
    <Text style={textStyle} size={16}>
      <Text>{setName}</Text>
      {set.cancelled && <Text style={{ color: colors.cancelled.tag }}> {t('app.constant.menu.cancelled')}</Text>}
    </Text>
  );
};

export default function Set({ set, divider }) {
  const { t } = useTranslation();

  const priceUndeterminedText = t('app.constant.menu.priceUndetermined');
  const showDivider = divider || set.setItems.length > 0;

  const textStyle = set.cancelled ? [styles.text, cancelledStyle] : styles.text;

  return (
    <View>
      <View
        style={[
          styles.itemContainer,
          {
            borderBottomWidth: showDivider ? 1 : 0,
            borderColor: colors.divider,
          },
        ]}
      >
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Image style={styles.icon} source={getStatusIconSource(set)} />
        </View>
        <View style={{ flex: 6, justifyContent: 'center' }}>
          <SetName set={set} />
        </View>

        <View style={{ flex: 1, alignItems: 'flex-end', justifyContent: 'center' }}>
          <Text style={textStyle} size={18}>{set.quantity}</Text>
        </View>

        <View style={{ flex: 2, alignItems: 'flex-end', justifyContent: 'center' }}>
          <Text style={textStyle} size={16}>{set.priceUndetermined ? priceUndeterminedText : `$${numberWithCommas(set.total)}`}</Text>
        </View>
      </View>

      {
        set.setItems.length > 0 && (
          <View
            style={{
              borderBottomWidth: divider ? 1 : 0,
              borderColor: colors.divider,
            }}
          >
            {set.setItems.map(setItem => (
              <SetItem key={setItem.key} setItem={setItem} />
            ))}
          </View>
        )
      }
    </View>
  );
}
