import { Image, StyleSheet, View } from 'react-native';
import React from 'react';

import { isServeLater, isServeUp } from '@/libs/order';
import { useSelector } from '@/redux';

import { INDENT_PADDING_ICON } from './styles';
import IconButton from '../../../../IconButton';

const Icon = ({ item, indent = 0, onDelete, isItemInCurrentBatch }) => {
  const cartInDeleteMode = useSelector(state => state.order.cartInDeleteMode);
  const canItemDelete = cartInDeleteMode && isItemInCurrentBatch;

  const getStatusIconSource = () => {
    if (isServeLater(item)) {
      return require('@icons/order/pause.png');
    }

    if (isServeUp(item)) {
      return require('@icons/order/play.png');
    }

    return null;
  };

  return (
    <View style={{ flex: 1, justifyContent: 'center', paddingLeft: indent * INDENT_PADDING_ICON }}>
      {!canItemDelete && <Image style={styles.icon} source={getStatusIconSource()} />}
      {canItemDelete && (
        <IconButton
          testID={`batch-item-delete-btn-${item.name}`}
          iconStyle={{ height: 30, width: 30 }}
          iconSource={require('@icons/order/delete-item.png')}
          onPress={onDelete}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  icon: {
    width: 15,
    height: 15,
  },
});

export default Icon;
