export default {
  transparent: 'transparent',
  white: '#ffffff',
  black: '#000000',
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
  },
  divider: '#eaebe9',
  disabled: {
    main: '#e0e0e0',
    contrastText: '#9e9e9e',
  },
  primary: {
    main: '#ffa344',
    light: '#febb51',
    lighter: '#f9e2ad',
    dark: '#ff6400',
  },
  secondary: {
    main: '#eaebe9',
    dark: '#c5c5c5',
    light: '#F6F6F6',
  },
  text: {
    placeholder: '#A0ABB4',
    primary: '#999999',
    error: '#ed631f',
  },
  button: {
    main: '#ffc42b',
    secondary: '#ff3535',
  },
  tableStatus: {
    default: '#474747',
    pending: '#f39c12',
    ordered: '#ef4836',
  },
  tag: {
    primary: '#ea0000',
  },
  toggle: {
    on: '#30d158',
    off: '#eaebe9',
  },
  setStepStatus: {
    pending: '#ffffff',
    fulfilled: '#f39c12',
  },
  batch: {
    current: '#22a7f0',
    default: '#d6d6d6',
    inComplete: '#FFD5D5',
  },
  customTag: {
    main: '#fff0e6',
    border: '#ed631f',
    disabled: {
      main: '#d8d8d8',
      border: '#9c9c9c',
    },
  },
  tab: {
    light: '#ffe4c5',
  },
  notification: {
    unread: '#ef4836',
  },
  cancelled: {
    tag: 'red',
    text: '#9e9e9e',
  },
};
