import { StyleSheet, View } from 'react-native';
import { actions, useDispatch, useSelector } from '@/redux';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import React, { useState } from 'react';
import TagButton from './TagButton';
import Text from '@/components/Text';
import colors from '@/theme/colors';
import itemStatus from '@/constants/itemStatus';
import shortCode from '@/libs/shortCode';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  titleContainer: {
    marginHorizontal: 10,
    marginBottom: 12,
  },
  title: {
    fontWeight: 'bold',
    color: colors.black + '60',
  },
});

export default function CustomTag() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { prefix, suffix } = useSelector(state => state.merchant.data.customTag);
  const workingSetItem = useSelector(state => state.order.workingSetItem);
  const isSetItem = Boolean(workingSetItem);
  const workingSingleItem = useSelector(state => state.order.workingItem);
  const tags = isSetItem ? workingSetItem.tags : workingSingleItem.tags;
  const customTags = tags.filter(o => o.id !== itemStatus.SERVE_LATER);

  const [selectedPrefix, setSelectedPrefix] = useState('');

  const filteredPrefix = prefix.filter((o) => o.enabled);
  const filteredSuffix = suffix.filter((o) => o.enabled);

  const renderPrefixItem = (tag, index) => {
    return (
      <TagButton
        key={tag.id}
        text={tag.name}
        selected={tag.id === selectedPrefix?.id}
        onPress={() => {
          if (tag.id === selectedPrefix?.id) {
            setSelectedPrefix('');
          } else {
            setSelectedPrefix(tag);
          }
        }}
      />
    );
  };

  const renderSuffixItem = (tag, index) => {
    return (
      <TagButton
        key={tag.id}
        text={tag.name}
        onPress={() => {
          dispatch(actions.order.addTag({
            id: shortCode.generate(6),
            name: selectedPrefix.name
              ? selectedPrefix.name + tag.name
              : tag.name,
          }));
        }}
      />
    );
  };

  return (
    <View style={styles.container}>

      <Header tags={customTags} />

      <View style={styles.titleContainer}>
        <Text size={16} style={styles.title}>{t('app.page.customTag.prefix')}</Text>
      </View>

      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginHorizontal: 10,
          marginBottom: 10,
        }}
      >
        {
          filteredPrefix.map((prefix, index) => {
            if (index < 3) {
              return (
                renderPrefixItem(prefix, index)
              );
            }
            return null;
          })
        }
      </View>

      <View style={styles.titleContainer}>
        <Text size={16} style={styles.title}>{t('app.page.customTag.suffix')}</Text>
      </View>

      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginHorizontal: 10,
          marginBottom: 10,
        }}
      >
        {
          filteredSuffix.map((suffix, index) => {
            if (index < 9) {
              return (
                renderSuffixItem(suffix, index)
              );
            }
            return null;
          })
        }
      </View>
    </View>
  );
}
