import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import Text from '@/components/Text';
import colors from '@/theme/colors';

export const variants = {
  ADD: 'add',
  DELETE: 'delete',
  SWITCH: 'switch',
  CUSTOMER_COUNT: 'customerCount',
};

const Button = ({ style, variant, onPress, children }) => {
  const { t } = useTranslation();

  const renderContent = () => {
    switch (variant) {
      case variants.ADD: {
        return (
          <View style={styles.row} testID='table-order-add-btn'>
            <Image style={styles.icon} source={require('@icons/order/plus-square.png')} />
            <Text style={styles.text}>{t('app.page.tableOrders.addTable')}</Text>
          </View>
        );
      }

      case variants.DELETE: {
        return (
          <View style={styles.row} testID='table-order-delete-btn'>
            <Image style={styles.icon} source={require('@icons/order/trash.png')} />
          </View>
        );
      }

      case variants.SWITCH: {
        return (
          <View style={styles.row} testID='table-order-switch-btn'>
            <Image style={styles.icon} source={require('@icons/order/switch-table.png')} />
            <Text style={styles.text}>{t('app.page.tableOrders.switchTable')}</Text>
          </View>
        );
      }

      case variants.CUSTOMER_COUNT: {
        return (
          <View style={styles.row}>
            {children}
          </View>
        );
      }

      default:
        return null;
    }
  };

  return (
    <TouchableOpacity
      style={[styles.button, style]}
      onPress={onPress}
    >
      {renderContent()}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    height: 24,
    width: 24,
  },
  text: {
    marginLeft: 8,
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: colors.white,
    borderColor: colors.primary.main,
    borderWidth: 2,
    paddingVertical: 4,
    paddingHorizontal: 12,
    borderRadius: 4,
  },
});

export default Button;
