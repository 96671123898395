import { StyleSheet, View } from 'react-native';
import React from 'react';
import Text from '@/components/Text';
import colors from '@/theme/colors';

export default function SettingItemText (props) {
  const { style, text, desc, textStyle, descTextStyle } = props;
  return (
    <View style={[styles.container, style]}>
      <Text size={16} style={[styles.text, textStyle]}>{text}</Text>
      {desc && <Text size={12} style={[styles.descText, descTextStyle]}>{desc}</Text>}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 3,
    flexWrap: 'wrap',
  },
  text: {
    color: colors.black,
    fontWeight: 'bold',
  },
  descText: {
    color: colors.black + '60',
    paddingTop: 10,
  },
});
