import { StyleSheet, TouchableOpacity, View } from 'react-native';
import React from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import { status } from '@/libs/order';
import { useHistory } from '@/libs/reactRouter';
import Text from '@/components/Text';
import colors from '@/theme/colors';

const height = 100;

export default function TableItem (props) {
  const {
    seatTime,
    table,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const pendingOrders = useSelector(state => state.orderHistory.orders.filter(o => o.status === status.PENDING));
  const pendingOrdersInTable = pendingOrders.find(o => o.table === table);
  const batchesInPendingOrders = pendingOrders.find(o => (o.table === table && o.batches.length > 0));

  const getStatusColor = () => {
    if (!pendingOrdersInTable) {
      return colors.tableStatus.default;
    }

    return batchesInPendingOrders
      ? colors.tableStatus.ordered
      : colors.tableStatus.pending;
  };

  if (table.isFiller) {
    return (
      <View style={[styles.container, styles.filler]} />
    );
  }

  return (
    <TouchableOpacity
      style={[
        styles.container,
        {
          backgroundColor: getStatusColor(),
        },
      ]}
      testID='table-item'
      onPress={async () => {
        dispatch(actions.order.addSelection({ table }));

        if (pendingOrdersInTable) {
          // select existing order or create new order
          history.push('/table-orders');
        } else {
          await dispatch(actions.order.createLocalOrder('table'));
        }
      }}
    >
      <View style={styles.extraInfo}>
        {seatTime && (
          <>
            <Text size={24} style={styles.timeText}>{String(seatTime)}</Text>
            <Text size={14} style={styles.timeUnit}>mins</Text>
          </>
        )}
      </View>
      <View style={styles.bottomInfo}>
        <Text size={18} style={styles.table}>{table}</Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    margin: 4,
    padding: 6,
    height,
    flex: 1,
  },
  filler: {
    backgroundColor: 'transparent',
  },
  extraInfo: {
    justifyContent: 'flex-end',
  },
  timeText: {
    textAlign: 'right',
    fontWeight: 'bold',
    color: colors.white,
  },
  timeUnit: {
    textAlign: 'right',
    fontWeight: 'bold',
    color: colors.white,
  },
  bottomInfo: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  table: {
    color: colors.white,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
