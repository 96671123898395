/**
 *
 * @param {IRootState} state
 * @returns {IOrderSelection[]}
 */
export const selectOrderSelections = (state) => {
  const mergedSelections = {};

  state.order.selectionStack.forEach(selection => {
    Object.entries(selection).forEach(([key, value]) => {
      mergedSelections[key] = value;
    });
  });

  return mergedSelections;
};

/**
 *
 * @param {IRootState} state
 * @returns {boolean}
 */
export const selectIsSetItem = (state) => {
  const workingSetItem = state.order.workingSetItem;
  const isSetItem = Boolean(workingSetItem);

  return isSetItem;
};

/**
 *
 * @param {IRootState} state
 * @returns {IMenu}
 */
export const selectMenu = (state) => {
  const menus = state.menu.menus;
  const isSetItem = selectIsSetItem(state);

  const {
    menuId: selectedMenuId,
    subsetId: selectedSubsetId,
    subsetStepId: selectedSubsetStepId,
    setMenuId: selectedSetMenuId,
  } = selectOrderSelections(state);

  if (isSetItem) {
    const subset = state.menu.sets.find(c => c.id === selectedSubsetId);
    const setMenus = subset.setMenus.filter((setMenu) => setMenu.step === selectedSubsetStepId);

    return setMenus.find(m => m.id === selectedSetMenuId);
  } else {
    return menus.find(m => m.id === selectedMenuId);
  }
};

/**
 *
 * @param {IRootState} state
 * @returns {IAppSetStep}
 */
export const selectMenuSetStep = (state) => {
  if (!selectIsSetItem(state)) {
    return null;
  }

  const {
    subsetId: selectedSubsetId,
    subsetStepId: selectedSubsetStepId,
  } = selectOrderSelections(state);

  const subset = state.menu.sets.find(c => c.id === selectedSubsetId);
  const setStep = subset.steps.find((step) => step.key === selectedSubsetStepId);

  return setStep;
};

/**
 *
 * @param {IRootState} state
 * @returns {IAppBatchItem}
 */
export const selectCurrentItem = (state) => {
  const workingSetItem = state.order.workingSetItem;
  const workingSingleItem = state.order.workingItem;

  return workingSetItem || workingSingleItem;
};

/**
 *
 * @param {IRootState} state
 * @returns {number}
 */
export const selectNumberOfInvalidItems = (state) => {
  return Object.values(state.order.invalidItemIdMap)
    .filter(arr => arr.length > 0)
    .length;
};

export const selectStep = (state) => {
  const {
    subsetId: selectedSubsetId,
    subsetStepId: selectedSubsetStepId,
  } = selectOrderSelections(state);
  const subset = state.menu.sets.find(c => c.id === selectedSubsetId);

  return subset.steps.find(s => s.key === selectedSubsetStepId);
};

export const selectNextStep = (state) => {
  const {
    subsetId: selectedSubsetId,
  } = selectOrderSelections(state);
  const subset = state.menu.sets.find(c => c.id === selectedSubsetId);
  const workingSetItemMenuItemIds = state.order.workingItem.setItems.map(item => item.menuItemId);
  const steps = subset.steps.filter(step => {
    const dependsOn = step.dependsOnSetMenuItems ?? [];

    if (!dependsOn.length) {
      return true;
    }

    return dependsOn.some(itemId => {
      return workingSetItemMenuItemIds.includes(itemId);
    });
  });

  const step = selectStep(state);
  const nextStep = steps.findIndex(o => o.key === step.key) >= 0
    ? steps.findIndex(o => o.key === step.key) + 1 < steps.length
      ? steps[steps.findIndex(o => o.key === step.key) + 1]
      : null
    : null;

  return nextStep;
};
