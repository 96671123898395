import { StyleSheet, TouchableOpacity, View } from 'react-native';
import React from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import { useHistory, useLocation } from '@/libs/reactRouter';
import colors from '@/theme/colors';

import Body from './Sections/Body';
import Icon from './Sections/Icon';
import Price from './Sections/Price';
import Quantity from './Sections/Quantity';
import Row from './Layout/Row';
import SetItem from './SetItem';

const Set = ({ set, editable, isItemInCurrentBatch }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const hasError = useSelector(state => Boolean(state.order.invalidItemIdMap[set.id]?.length));
  const selectionStack = useSelector(state => state.order.selectionStack);
  const workingItem = useSelector(state => state.order.workingItem);
  const numSelectionToRemove = useSelector(state => state.order.selectionStack.length - 1);

  const onEdit = () => {
    dispatch(actions.order.removeSelection(numSelectionToRemove));
    dispatch(actions.order.addSelection({
      setId: set.categoryId,
      subsetId: set.setId,
    }));
    dispatch(actions.order.updateWorkingItem(set));
    dispatch(actions.order.enterEditMode(location.pathname));
    history.push('/order/sets/subsets/steps');
  };

  return (
    <View>
      <TouchableOpacity
        disabled={!editable}
        style={{
          backgroundColor: hasError
            ? colors.batch.inComplete
            : colors.white,
        }}
        onPress={onEdit}
      >
        <Row
          icon={
            <Icon
              item={set}
              isItemInCurrentBatch={isItemInCurrentBatch}
              onDelete={() => {
                dispatch(actions.order.deleteItemFromBatch(set));

                if (workingItem?.id === set.key) {
                  dispatch(actions.order.updateWorkingItem(null));
                  dispatch(actions.order.removeSelection(selectionStack.length - 1));
                  history.push('/order-categories');
                }
              }}
            />
          }
          divider
        >
          <Body item={set} />
          <Quantity item={set} />
          <Price item={set} />
        </Row>
      </TouchableOpacity>

      <View>
        {set.setItems.map(setItem => {
          return (
            <SetItem
              key={setItem.key}
              set={set}
              item={setItem}
              editable={editable}
              isItemInCurrentBatch={isItemInCurrentBatch}
            />
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    flex: 1,
    paddingHorizontal: 12,
    paddingVertical: 11,
    flexDirection: 'row',
  },
});

export default React.memo(Set);
