import { BackHandler, StyleSheet, View } from 'react-native';
import { batch } from 'react-redux';
import React, { useEffect } from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import { settingKey } from '@/constants/app';
import { useHistory, useLocation } from '@/libs/reactRouter';
import IconButton from '@/components/IconButton';

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 5,
  },
  icon: {
    height: 32,
    width: 32,
  },
});

export default function BackButton (props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const settingPath = useSelector(state => state.setting.path);

  const onPress = () => {
    if (location.pathname === '/setting') {
      if (settingPath !== settingKey.MAIN) {
        dispatch(actions.setting.updateSettingPath(settingKey.MAIN));
        return null;
      }
    }
    history.goBack();
  };

  useEffect(() => {
    const backAction = () => {
      batch(() => {
        if (props.onPress) {
          props.onPress();
        } else {
          onPress();
        }
      });

      return true;
    };

    const backhandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => {
      backhandler.remove();
    };
  });

  return (
    <View testID='back-btn'>
      <IconButton
        style={styles.container}
        iconSource={require('@icons/common/left-arrow.png')}
        iconStyle={styles.icon}
        onPress={props.onPress ? props.onPress : onPress}
      />
    </View>
  );
}
