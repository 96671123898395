import { FlatList, StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import { getMenuPriceText } from '@/libs/order';
import { listNumColumns, orderModes } from '@/constants/app';
import { padToMatchMultiple } from '@/libs/array';
import { useHistory } from '@/libs/reactRouter';
import { useSearchedMenu } from '@/hooks';
import FooterOrderButtons from '@/components/FooterOrderButtons';
import ItemBox from '@root/src/components/ItemBox';
import ListWithBatchCart from '@/components/ListWithBatchCart';
import NavBar from '@/components/NavBar';
import Text from '@/components/Text';
import themeStyle from '@/theme/themeStyle';

import RecentSearchTags from './RecentSearchTags';

const SearchMenu = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filteredMenus = useSearchedMenu();
  const { searchText } = useSelector(state => state.menu);
  const orderMode = useSelector(state => state.setting.orderMode);

  return (
    <View style={styles.container}>
      <NavBar
        backButton
        searchBar
        onBack={() => {
          if (!searchText) {
            history.push('/order-categories');
            dispatch(actions.menu.resetSearchText());
          } else {
            dispatch(actions.menu.resetSearchText());
          }
        }}
      />

      <View style={styles.contentContainer}>
        <ListWithBatchCart>
          <Text size={18} style={styles.title}>
            {searchText
              ? t('app.page.search.result', { searchText, numResult: filteredMenus.length })
              : t('app.page.search.recent')}
          </Text>
          {!searchText && <RecentSearchTags />}
          {searchText
            ? <FlatList
                data={padToMatchMultiple(filteredMenus, listNumColumns)}
                renderItem={({ item }) => {
                  const handlePress = () => {
                    if (orderMode === orderModes.ITEM_FIRST) {
                      dispatch(actions.order.createLocalItem(item));
                      dispatch(actions.order.addItemToBatch());
                    } else {
                      dispatch(actions.order.addSelection({ menuId: item.id }));
                      dispatch(actions.order.createLocalItem(item));
                      history.push('/order/menus/options');
                    }
                  };

                  return (
                    <ItemBox
                      key={item.id}
                      height={themeStyle.box.height.normal}
                      item={item}
                      isSoldOut={item.sold}
                      disabled={item.sold}
                      caption={getMenuPriceText(item)}
                      onPress={handlePress}
                    />
                  );
                }}
                numColumns={listNumColumns}
                showsVerticalScrollIndicator={false}
              />
            : null}
        </ListWithBatchCart>

        <FooterOrderButtons />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    paddingHorizontal: themeStyle.spacing.app.horizontal,
    paddingVertical: themeStyle.spacing.app.vertical,
  },
  title: {
    fontWeight: 'bold',
  },
});

export default SearchMenu;
