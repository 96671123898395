import { FlatList, StyleSheet, View } from 'react-native';
import React, { useEffect } from 'react';

import { actions, selectors, useDispatch, useSelector } from '@/redux';
import { getSelectedItemCountInStep } from '@/libs/order';
import { useHistory } from '@/libs/reactRouter';
import FooterSetOrderButtons from '@/components/FooterSetOrderButtons';
import ListWithBatchCart from '@/components/ListWithBatchCart';
import NavBar from '@/components/NavBar';
import themeStyle from '@/theme/themeStyle';

import StepBar from './StepBar';

const OrderSubsetSteps = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { subsetId: selectedSubsetId, setId: selectedSetId } = useSelector(selectors.order.selectOrderSelections);
  const subset = useSelector(state => {
    return state.menu.sets.find(c => c.id === selectedSubsetId);
  });
  const subsetName = useSelector(state => {
    return selectors.menu.selectMenuNameByLocale(state, subset);
  });
  const workingItem = useSelector(state => state.order.workingItem);
  const isSubsetSelectionSkipped = useSelector(state => {
    return state.menu.sets.filter(s => s.categoryId === selectedSetId).length === 1;
  });
  const editFromPath = useSelector(state => state.order.editFromPath);
  const numSelectionToRemove = useSelector(state => state.order.selectionStack.length - 1);

  // 承接餐單
  const workingSetItems = workingItem.setItems;
  const workingSetItemMenuItemIds = workingSetItems.map(item => item.menuItemId);
  const steps = subset.steps.filter(step => {
    const dependsOn = step.dependsOnSetMenuItems ?? [];

    if (!dependsOn.length) {
      return true;
    }

    return dependsOn.some(itemId => {
      return workingSetItemMenuItemIds.includes(itemId);
    });
  });

  const areAllStepsValid = steps.every(step => {
    const selectedItemCountInStep = getSelectedItemCountInStep(workingItem, step);

    return selectedItemCountInStep >= step.min;
  });

  const hasSelectedSetItems = workingItem.setItems.length;

  const isDoneBtnDisabled = !areAllStepsValid || !hasSelectedSetItems;

  const reconcileOnBack = () => {
    if (editFromPath) {
      dispatch(actions.order.removeSelection());
      dispatch(actions.order.reconcileStash());
      dispatch(actions.order.exitEditMode());
      history.push(editFromPath);

      return;
    }

    dispatch(actions.order.removeSelection());
    dispatch(actions.order.reconcileStash());
    history.push(isSubsetSelectionSkipped ? '/order/sets' : '/order/sets/subsets');
  };

  const reconcileOnComplete = () => {
    if (editFromPath) {
      dispatch(actions.order.removeSelection());
      dispatch(actions.order.reconcileStash());
      dispatch(actions.order.exitEditMode());
      history.push(editFromPath);

      return;
    }

    dispatch(actions.order.removeSelection(numSelectionToRemove));
    dispatch(actions.order.reconcileStash());
    history.push('/order-categories');
  };

  useEffect(() => {
    if (!workingSetItems.length) {
      subset.setMenus.forEach(setMenu => {
        if (setMenu.required && !setMenu.sold) {
          dispatch(actions.order.createLocalSetItem(setMenu));
          dispatch(actions.order.addSetItemToWorkingItem(setMenu.step));
        }
      });
    }
  }, [dispatch, workingSetItems, subset.setMenus]);

  return (
    <View style={{ flex: 1 }}>
      <NavBar
        title={subsetName}
        backButton
        searchButton
        onBack={() => {
          if (workingItem.setItems.length) {
            dispatch(actions.order.addItemToBatch());
          }

          dispatch(actions.order.updateWorkingItem(null));
          reconcileOnBack();
        }}
      />

      <View style={styles.contentContainer}>
        <ListWithBatchCart>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={steps}
            renderItem={({ item: step }) => {
              return <StepBar key={step.id} step={step} />;
            }}
          />
        </ListWithBatchCart>

        <FooterSetOrderButtons
          doneButtonDisabled={isDoneBtnDisabled}
          doneButtonOnPress={() => {
            dispatch(actions.order.addItemToBatch());
            reconcileOnComplete();
          }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    paddingHorizontal: themeStyle.spacing.app.horizontal,
    paddingVertical: themeStyle.spacing.app.vertical,
  },
});

export default OrderSubsetSteps;
