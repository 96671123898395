import { ScrollView, StyleSheet, View } from 'react-native';
import { settingKey } from '@/constants/app';
import { useSelector } from '@/redux';
import { useTranslation } from 'react-i18next';
import MainSettingList from './MainSettingList';
import NavBar from '@/components/NavBar';
import OptionModal from './OptionModal';
import React from 'react';
import SystemInfo from './SystemInfo';
import UserInfo from './UserInfo';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 50,
    marginBottom: 50,
  },
});

export default function Setting (props) {
  const { path } = useSelector(state => state.setting);
  const { t } = useTranslation();
  return (
    <View style={{ flex: 1 }}>
      <NavBar
        title={t('app.page.setting.title')}
        backButton
        orderHistoryButton
      />
      <ScrollView style={styles.container} bounces={false}>

        {path === settingKey.MAIN && <MainSettingList />}
        {path === settingKey.SYSTEM_INFO && <SystemInfo />}
        {path === settingKey.USER_INFO && <UserInfo />}

        <View style={{ height: 50 }} />

      </ScrollView>
      <OptionModal />
    </View>
  );
}
