import React from 'react';

import ToggleSwitch from '@/components/ToggleSwitch';

import { View } from 'react-native';
import SettingItem from '../SettingItem';
import SettingItemRightAction from '../SettingItemRightAction';
import SettingItemText from '../SettingItemText';

export default function SwitchRow (props) {
  const { divider, label, value, onChangeValue, desc } = props;
  return (
    <SettingItem divider={divider}>
      <SettingItemText text={label} desc={desc} />
      <SettingItemRightAction>
        <ToggleSwitch
          value={value}
          onChangeValue={() => onChangeValue(!value)}
          size={31}
        />
      </SettingItemRightAction>
    </SettingItem>
  );
}
