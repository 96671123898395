import { FlatList, StyleSheet } from 'react-native';
import React from 'react';

import { status } from '@/libs/order';
import { useSelector } from '@/redux';

import OrderCard from './OrderCard';

const OrderList = ({ variant }) => {
  const { orders } = useSelector(state => state.orderHistory);

  const tableOrders = orders
    .filter(o => o.status === status.PENDING)
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  return (
    <FlatList
      showsVerticalScrollIndicator={false}
      style={styles.list}
      contentContainerStyle={styles.contentContainer}
      data={tableOrders}
      renderItem={({ item: order }) => {
        return (
          <OrderCard key={order.id} order={order} variant={variant} />
        );
      }}
    />
  );
};

const styles = StyleSheet.create({
  list: {
    flex: 1,
    marginTop: 20,
  },
  contentContainer: {
    paddingBottom: 32,
    paddingHorizontal: 12,
  },
});

export default OrderList;
