import { StyleSheet, TouchableOpacity, View } from 'react-native';
import React from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import { getSelectedItemCountInStep } from '@/libs/order';
import { useHistory } from '@/libs/reactRouter';
import Text from '@/components/Text';
import colors from '@/theme/colors';

const StepBar = ({ step }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const workingItem = useSelector(state => state.order.workingItem);

  const selectedItemCountInStep = getSelectedItemCountInStep(workingItem, step);
  const isFulfilled = selectedItemCountInStep >= step.min && selectedItemCountInStep !== 0;

  const getSelectionRangeText = (step) => {
    let selectedItemCountInStep = 0;
    workingItem.setItems.forEach(setItem => {
      if (setItem.step === step.key) {
        selectedItemCountInStep += setItem.quantity;
      }
    });

    if (selectedItemCountInStep >= step.min && selectedItemCountInStep !== 0) {
      return `已選 (${selectedItemCountInStep})`;
    }

    if (step.min === 0) {
      if (step.max === 0) {
        return '可選可不選';
      }

      return `可選可不選 (${step.max})`;
    }

    return `${step.min} - ${step.max}`;
  };

  return (
    <TouchableOpacity
      key={step.key}
      style={{
        padding: 20,
        backgroundColor: isFulfilled
          ? colors.setStepStatus.fulfilled
          : colors.setStepStatus.pending,
        marginTop: 12,
        borderRadius: 8,
      }}
      onPress={() => {
        dispatch(actions.order.addSelection({ subsetStepId: step.key }));
        history.push('/order/sets/menus');
      }}
    >
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text
          style={{
            color: isFulfilled
              ? colors.white
              : colors.black,
          }}
        >
          {step.name}
        </Text>
        <Text
          style={{
            color: isFulfilled
              ? colors.white
              : colors.black,
          }}
        >
          {getSelectionRangeText(step)}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default StepBar;
