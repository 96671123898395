const configs = {
  wssUrl: 'wss://test-api.dimorder.com',
  apiUrls: {
    go: 'https://test-api.dimorder.com',
    node: 'https://test-node.dimorder.com',
    urlShortener: 'https://s.dimorder.com',
  },
  clientUrls: {
    customerWeb: 'https://test.dimorder.com',
    merchantFrontend: 'https://test-merchant.dimorder.com',
    customerAppWeb: 'https://test-c.dimorder.com',
    customerApp: 'https://app.dimorder.com',
  },
};

export default configs;
