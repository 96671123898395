import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import Button from '@/components/Button';
import colors from '@/theme/colors';

import Text from '../Text';

const FooterSetOrderButtons = ({ doneButtonOnPress, doneButtonDisabled }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cartInDeleteMode = useSelector(state => state.order.cartInDeleteMode);

  return (
    <View style={styles.container}>
      <View style={styles.buttonContainer}>
        <Button
          style={[styles.button, cartInDeleteMode ? styles.confirmButton : styles.cancelButton]}
          onPress={() => {
            dispatch(actions.order.setCartInDeleteMode(!cartInDeleteMode));
          }}
        >
          <Text size={16} style={cartInDeleteMode ? styles.confirmText : styles.discardText}>
            {cartInDeleteMode ? t('app.common.confirm') : t('app.page.order.deleteItem')}
          </Text>
        </Button>
      </View>

      <View style={styles.spacer} />

      <View style={styles.buttonContainer}>
        <Button
          disabled={doneButtonDisabled || cartInDeleteMode}
          style={[styles.button, styles.confirmButton]}
          touchableProps={{
            testID: 'btn-done',
          }}
          onPress={doneButtonOnPress}
        >
          <Text size={16} style={styles.confirmText}>
            {t('app.common.done')}
          </Text>
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  spacer: {
    width: 10,
  },
  buttonContainer: {
    flex: 1,
  },
  button: {
    height: 50,
    borderRadius: 8,
  },
  cancelButton: {
    borderColor: colors.button.secondary,
    backgroundColor: colors.button.secondary,
  },
  confirmButton: {
    borderColor: colors.button.main,
    backgroundColor: colors.button.main,
  },
  discardText: {
    color: colors.white,
    fontWeight: 'bold',
  },
  confirmText: {
    color: colors.black,
    fontWeight: 'bold',
  },
});

export default FooterSetOrderButtons;
