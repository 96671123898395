import produce from 'immer';

import ActionTypes from './ActionTypes';
import ActionTypesAuth from '../auth/ActionTypes';

export const initialState = {
  isInit: false,
  data: {},
  selectedTableGroupIndex: 0,
  customReasons: [],
};

export default produce(
  /**
   * @param {IMerchantState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action.type) {
      case ActionTypesAuth.USER_LOGOUT: {
        return initialState;
      }

      case ActionTypes.INIT: {
        draft.isInit = true;
        break;
      }

      case ActionTypes.GET_MERCHANT: {
        draft.data = action.payload;
        break;
      }

      case ActionTypes.SELECT_TABLE_GROUP: {
        draft.selectedTableGroupIndex = action.payload;
        break;
      }

      case ActionTypes.GET_CUSTOM_REASON: {
        draft.customReasons = action.payload;
        break;
      }

      default:
        return draft;
    }
  },
  initialState,
);
