import { StyleSheet, TouchableOpacity, View } from 'react-native';
import React from 'react';
import Text from '@/components/Text';
import colors from '@/theme/colors';

const height = 110;

const styles = StyleSheet.create({
  tagContainer: {
    flex: 1,
    height,
    margin: 4,
    padding: 6,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontWeight: 'bold',
  },
  filler: {
    backgroundColor: 'transparent',
  },
});

export default function TagButton (props) {
  const { onPress, tag, selected } = props;

  if (tag.isFiller) {
    return (
      <View style={[styles.tagContainer, styles.filler]} />
    );
  }

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        styles.tagContainer,
        {
          backgroundColor: selected
            ? colors.primary.main
            : colors.white,
        },
      ]}
    >
      <Text size={18} style={styles.text}>{tag.name}</Text>
    </TouchableOpacity>
  );
}
