import { StyleSheet, View } from 'react-native';
import React, { useEffect } from 'react';

import { actions, selectors, useDispatch, useSelector } from '@/redux';
import { getSelectedItemCountInStep } from '@/libs/order';
import { useHistory } from '@/libs/reactRouter';
import FooterSetOrderButtons from '@/components/FooterSetOrderButtons';
import ListWithBatchCart from '@/components/ListWithBatchCart';
import NavBar from '@/components/NavBar';
import themeStyle from '@/theme/themeStyle';

import SetMenuList from './SetMenuList';

const OrderSetMenus = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const subsetName = useSelector(state => {
    const { subsetId: selectedSubsetId } = selectors.order.selectOrderSelections(state);
    const subset = state.menu.sets.find(c => c.id === selectedSubsetId);

    return selectors.menu.selectMenuNameByLocale(state, subset);
  });
  const step = useSelector(selectors.order.selectStep);
  const nextStep = useSelector(selectors.order.selectNextStep);
  const numSelectionToRemove = useSelector(state => state.order.selectionStack.length - 1);

  const areAllStepsValid = useSelector(state => {
    const {
      subsetId: selectedSubsetId,
    } = selectors.order.selectOrderSelections(state);
    const subset = state.menu.sets.find(c => c.id === selectedSubsetId);
    const workingSetItemMenuItemIds = state.order.workingItem.setItems.map(item => item.menuItemId);
    const steps = subset.steps.filter(step => {
      const dependsOn = step.dependsOnSetMenuItems ?? [];

      if (!dependsOn.length) {
        return true;
      }

      return dependsOn.some(itemId => {
        return workingSetItemMenuItemIds.includes(itemId);
      });
    });

    return steps.every(step => {
      const selectedItemCountInStep = getSelectedItemCountInStep(state.order.workingItem, step);

      return selectedItemCountInStep >= step.min;
    });
  });

  const isDoneBtnDisabled = useSelector(state => {
    const selectedItemCountInStep = getSelectedItemCountInStep(state.order.workingItem, step);
    return selectedItemCountInStep < step.min;
  });

  const isMaxReached = useSelector(state => {
    const selectedItemCountInStep = getSelectedItemCountInStep(state.order.workingItem, step);
    return step.max > 0 && selectedItemCountInStep >= step.max;
  });

  const reconcileOnBack = () => {
    dispatch(actions.order.removeSelection());
    dispatch(actions.order.reconcileStash());
    history.push('/order/sets/subsets/steps');
  };

  const reconcileOnComplete = () => {
    if (areAllStepsValid) {
      dispatch(actions.order.removeSelection(numSelectionToRemove));
      dispatch(actions.order.reconcileStash());
      history.push('/order-categories');
      return;
    }
    dispatch(actions.order.removeSelection());
    dispatch(actions.order.reconcileStash());
    history.push('/order/sets/subsets/steps');
  };

  useEffect(() => {
    if (nextStep && isMaxReached) {
      dispatch(actions.order.removeSelection());
      dispatch(actions.order.reconcileStash());
      dispatch(actions.order.addSelection({ subsetStepId: nextStep.key }));
      history.push('/order/sets/menus');
    }
  }, [dispatch, history, nextStep, isMaxReached]);

  return (
    <View style={{ flex: 1 }}>
      <NavBar
        title={`${subsetName}: ${step.name}`}
        backButton
        searchButton
        onBack={reconcileOnBack}
      />

      <View style={styles.contentContainer}>
        <ListWithBatchCart>
          <SetMenuList />
        </ListWithBatchCart>

        <FooterSetOrderButtons
          doneButtonDisabled={isDoneBtnDisabled}
          doneButtonOnPress={reconcileOnComplete}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    paddingHorizontal: themeStyle.spacing.app.horizontal,
    paddingVertical: themeStyle.spacing.app.vertical,
  },
});

export default OrderSetMenus;
