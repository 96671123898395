import { FlatList, StyleSheet, View } from 'react-native';
import React from 'react';

import { actions, selectors, useDispatch, useSelector } from '@/redux';
import {
  getMenuPriceText,
  isWithinSpecifiedTimeRange,
  isWithinSpecifiedWeekdays,
} from '@/libs/order';
import { listNumColumns } from '@/constants/app';
import { padToMatchMultiple } from '@/libs/array';
import { useHistory } from '@/libs/reactRouter';
import FooterSetOrderButtons from '@/components/FooterSetOrderButtons';
import ItemBox from '@root/src/components/ItemBox';
import ListWithBatchCart from '@/components/ListWithBatchCart';
import NavBar from '@/components/NavBar';
import themeStyle from '@/theme/themeStyle';

const OrderSubsets = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { setId: selectedSetId } = useSelector(selectors.order.selectOrderSelections);
  const set = useSelector(state => {
    const setCategory = state.menu.categories.find(c => c.id === selectedSetId);
    if (setCategory) {
      return setCategory;
    }

    for (let i = 0; i < state.menu.categories.length; i++) {
      const setSubCategory = state.menu.categories[i].categories.find(c => c.id === selectedSetId);

      if (setSubCategory) {
        return setSubCategory;
      }
    }
  });
  const subsets = useSelector(state => {
    return state.menu.sets.filter(s => s.categoryId === selectedSetId);
  });
  const setName = useSelector(state => {
    return selectors.menu.selectMenuNameByLocale(state, set);
  });
  const numSelectionToRemove = useSelector(state => state.order.selectionStack.length - 1);
  const setCategories = set.categories.filter(cat => {
    return [
      !cat.disabled,
      !cat.takeawayOnly,
      isWithinSpecifiedWeekdays(cat.weekdays),
      isWithinSpecifiedTimeRange(cat.timerange),
    ].every(Boolean);
  });
  const rawSets = useSelector(state => state.menu.sets);

  const list = [...setCategories, ...subsets];

  const handleBack = () => {
    dispatch(actions.order.removeSelection(numSelectionToRemove));
    dispatch(actions.order.reconcileStash());
    history.push('/order/sets');
  };

  return (
    <View style={{ flex: 1 }}>
      <NavBar
        title={setName}
        backButton
        searchButton
        onBack={handleBack}
      />

      <View style={styles.container}>
        <ListWithBatchCart>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={padToMatchMultiple(list, listNumColumns)}
            numColumns={listNumColumns}
            initialNumToRender={4}
            maxToRenderPerBatch={1}
            renderItem={({ item: set }) => {
              const handlePress = () => {
                if (set.categories) {
                  const subsets = rawSets.filter(s => s.categoryId === set.id);

                  if (subsets.length > 1) {
                    dispatch(actions.order.addSelection({ setId: set.id }));
                    history.push('/order/sets/subsets');
                  } else {
                    const subset = subsets[0];

                    dispatch(actions.order.addSelection({
                      setId: set.id,
                      subsetId: subset.id,
                    }));
                    dispatch(actions.order.createLocalItem(subset));
                    history.push('/order/sets/subsets/steps');
                  }
                } else {
                  dispatch(actions.order.addSelection({ subsetId: set.id }));
                  dispatch(actions.order.createLocalItem(set));
                  history.push('/order/sets/subsets/steps');
                }
              };

              return (
                <ItemBox
                  key={set.id}
                  height={themeStyle.box.height.normal}
                  item={set}
                  isSoldOut={set.sold}
                  disabled={set.sold}
                  caption={getMenuPriceText(set)}
                  onPress={handlePress}
                />
              );
            }}
          />
        </ListWithBatchCart>

        <FooterSetOrderButtons doneButtonOnPress={handleBack} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: themeStyle.spacing.app.horizontal,
    paddingVertical: themeStyle.spacing.app.vertical,
  },
});

export default OrderSubsets;
