import { StyleSheet, View } from 'react-native';
import IconButton from './IconButton';
import React from 'react';
import Text from './Text';

const styles = StyleSheet.create({

});

export default function Stepper ({ value, onPressDecrement, onPressIncrement, plusDisabled, minusDisabled }) {
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <View testID='quantity-decrement-btn'>
        <IconButton
          iconSource={require('@icons/order/minus.png')}
          iconStyle={{ height: 28, width: 28, opacity: minusDisabled ? 0.3 : 1 }}
          onPress={onPressDecrement}
          disabled={minusDisabled}
        />
      </View>

      <Text size={24} style={{ marginHorizontal: 15, fontWeight: 'bold' }}>{value}</Text>

      <View testID='quantity-increment-btn'>
        <IconButton
          iconSource={require('@icons/order/plus.png')}
          iconStyle={{ height: 28, width: 28, opacity: plusDisabled ? 0.3 : 1 }}
          onPress={onPressIncrement}
          disabled={plusDisabled}
        />
      </View>
    </View>
  );
}
