import { Dimensions, StyleSheet, TouchableOpacity, View } from 'react-native';
import { numberWithCommas } from '@/libs/numberWithCommas';
import IconButton from '@/components/IconButton';
import React from 'react';
import Text from '@/components/Text';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  container: {
    margin: 4,
    padding: 8,
    height: 110,
    width: (Dimensions.get('window').width - 44) / 3,
    flexDirection: 'column',
    backgroundColor: colors.white,
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text: {
    fontWeight: 'bold',
  },
  icon: {
    height: 35,
    width: 35,
  },
  qtyText: {
    color: colors.white,
    fontWeight: 'bold',
  },
  qtyTag: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 33,
    width: 39,
    backgroundColor: colors.primary.main,
    borderRadius: 5,
  },
});

const typeKey = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
};

export default function OptionBox ({ data, type, selected, onPress, quantity, onMinus }) {
  return (
    <>
      {type === typeKey.SINGLE && (
        <TouchableOpacity onPress={onPress}>
          <View
            style={[
              styles.container,
              {
                backgroundColor: selected
                  ? colors.primary.main
                  : colors.white,
              },
            ]}
          >
            <View style={{ flex: 1 }}>
              <Text
                size={18}
                style={[
                  styles.text,
                  {
                    color: selected
                      ? colors.white
                      : colors.black,
                  },
                ]}
              >
                {data?.name}
              </Text>
            </View>

            <View style={{ justifyContent: 'flex-end' }}>
              <Text
                size={18}
                style={[
                  styles.text,
                  {
                    color: selected
                      ? colors.white
                      : colors.black,
                  },
                ]}
              >
                {data?.price ? `$${numberWithCommas(data?.price)}` : ''}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      )}
      {type === typeKey.MULTIPLE && (
        <TouchableOpacity onPress={onPress}>
          <View style={styles.container}>

            <View style={{ flex: 1 }}>
              <Text size={18} style={styles.text}>{data?.name}</Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text size={18} style={styles.text}>{data?.price ? `$${numberWithCommas(data?.price)}` : ''}</Text>
            </View>

            <View style={styles.buttonContainer}>
              <IconButton
                onPress={onMinus}
                iconSource={
                  selected
                    ? require('@icons/order/minus-filled.png')
                    : require('@icons/order/minus-filled-disable.png')
                }
                iconStyle={styles.icon}
              />
              <View
                style={[
                  styles.qtyTag,
                  {
                    backgroundColor: selected
                      ? colors.primary.main
                      : colors.secondary.dark,
                  },
                ]}
              >
                <Text size={18} style={styles.qtyText}>{quantity || 0}</Text>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      )}
    </>
  );
}
