import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import storage from '@/libs/storage';

import app from './app/reducer';
import auth from './auth/reducer';
import codePush from './codePush/reducer';
import menu from './menu/reducer';
import merchant from './merchant/reducer';
import notifications from './notifications/reducer';
import order from './order/reducer';
import orderHistory from './orderHistory/reducer';
import setting from './setting/reducer';

const rootReducer = combineReducers({
  app,
  menu,
  merchant,
  order,
  orderHistory,
  setting,
  notifications,
  codePush,
  auth,
});

const rootPersistConfig = {
  key: 'root',
  whitelist: ['notifications'],
  storage,
};

export default persistReducer(rootPersistConfig, rootReducer);
