import { StyleSheet, View } from 'react-native';
import React from 'react';

import Text from '@/components/Text';
import colors from '@/theme/colors';

const Quantity = ({ item }) => {
  const cancelledStyle = { color: colors.grey['500'] };
  const textStyle = item.cancelled ? [styles.text, cancelledStyle] : styles.text;

  return (
    <View style={{ flex: 1, alignItems: 'flex-end', justifyContent: 'center' }}>
      <Text style={textStyle} size={18}>{item.quantity}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    fontWeight: 'bold',
  },
});

export default Quantity;
