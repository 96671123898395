import React, { useEffect } from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import { useHistory } from '@/libs/reactRouter';

import Loader from '../components/Loader';

export default function Landing() {
  const history = useHistory();
  const dispatch = useDispatch();
  const isInit = useSelector(state => state.app.isInit);
  const isLogin = useSelector(state => state.auth.currentUser !== null);

  useEffect(() => {
    const init = async () => {
      await dispatch(actions.auth.restoreUser());
      dispatch(actions.app.init());
    };

    init();
  }, [dispatch]);

  useEffect(() => {
    const init = async () => {
      if (isLogin) {
        await dispatch(actions.auth.updateScopeCredentials());
      }

      if (isInit) {
        history.replace(isLogin ? '/tables' : '/login');
      }
    };

    init();
  }, [isInit, isLogin, history, dispatch]);

  return <Loader />;
}
