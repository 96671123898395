import { useRouteMatch } from '@/libs/reactRouter';

export default function useSafeAreaViewEdges() {
  const hasNoTopBottomEdges = [
    useRouteMatch('/login'),
  ].some(Boolean);

  const hasTopEdgeOnly = [
    useRouteMatch('/table-orders'),
    useRouteMatch('/orderhistory'),
  ].some(Boolean);

  if (hasNoTopBottomEdges) {
    return ['left', 'right'];
  }

  if (hasTopEdgeOnly) {
    return ['top', 'left', 'right'];
  }

  return ['top', 'bottom', 'left', 'right'];
}
