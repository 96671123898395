import { StyleSheet, TextInput } from 'react-native';
import React from 'react';
import colors from '@/theme/colors';

const styles = StyleSheet.create({
  textInput: {
    height: 53,
    width: '100%',
    textAlign: 'left',
    color: colors.black,
    backgroundColor: colors.white,
    borderRadius: 4,
    fontSize: 16,
    fontWeight: '500',
    paddingHorizontal: 12,
    overflow: 'scroll',
  },
  disabled: {
    backgroundColor: colors.disabled.main,
    color: colors.disabled.contrastText,
  },
});

export default function TextInputBox (props) {
  const { style, disabled } = props;

  return (
    <TextInput
      {...props}
      autoCapitalize='none'
      autoCorrect={false}
      editable={!disabled}
      style={[styles.textInput, disabled && styles.disabled, style]}
    />
  );
}
