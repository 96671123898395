import ActionTypes from './ActionTypes';
import AsyncStorage from '@react-native-async-storage/async-storage';
import i18n from '@/i18n';

// AsyncStorage key
const LANGUAGE_KEY = 'LANGUAGE';
const FONTSIZE_KEY = 'FONTSIZE';
const ORDER_MODE_KEY = 'ORDERMODE';

export function init () {
  return async (dispatch, getState) => {
    await dispatch(restoreLang());
    await dispatch(restoreFontSize());
    await dispatch(restoreOrderMode());

    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    });
  };
}

export function updateSettingPath (key) {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_SETTING_PATH,
      payload: { key: key },
    });
  };
}

export function showSettingOptions (config) {
  return {
    type: ActionTypes.SHOW_SETTING_OPTIONS,
    payload: { config },
  };
}

export function closeSettingOptions () {
  return {
    type: ActionTypes.CLOSE_SETTING_OPTIONS,
  };
}

export function restoreLang () {
  return async (dispatch, getState) => {
    const lang = await AsyncStorage.getItem(LANGUAGE_KEY);

    if (lang) {
      i18n.changeLanguage(lang);

      dispatch({
        type: ActionTypes.UPDATE_LANGUAGE,
        payload: { lang },
      });
    }
  };
};

export function changeLang (lang) {
  return async (dispatch, getState) => {
    await AsyncStorage.setItem(LANGUAGE_KEY, lang);
    i18n.changeLanguage(lang);

    dispatch({
      type: ActionTypes.UPDATE_LANGUAGE,
      payload: { lang },
    });
  };
};

export function restoreFontSize () {
  return async (dispatch, getState) => {
    const size = await AsyncStorage.getItem(FONTSIZE_KEY);

    if (size) {
      dispatch({
        type: ActionTypes.CHANGE_FONT_SIZE,
        payload: { size },
      });
    }
  };
}

export function changeFontSize (size) {
  return async (dispatch, getState) => {
    await AsyncStorage.setItem(FONTSIZE_KEY, size);

    dispatch({
      type: ActionTypes.CHANGE_FONT_SIZE,
      payload: { size },
    });
  };
}

export function restoreOrderMode () {
  return async (dispatch, getState) => {
    const orderMode = await AsyncStorage.getItem(ORDER_MODE_KEY);

    if (orderMode) {
      dispatch({
        type: ActionTypes.CHANGE_ORDER_MODE,
        payload: orderMode,
      });
    }
  };
}

export function changeOrderMode (orderMode) {
  return async (dispatch) => {
    await AsyncStorage.setItem(ORDER_MODE_KEY, orderMode);

    dispatch({
      type: ActionTypes.CHANGE_ORDER_MODE,
      payload: orderMode,
    });
  };
}

/**
 *
 * @param {string} lang
 * @returns
 */
export function changeMenuLang(lang) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_MENU_LANGUAGE,
      payload: lang,
    });
  };
}
