import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import Text from '@/components/Text';
import colors from '@/theme/colors';

const CountdownInfo = ({ timeElapsed, timeAllowed }) => {
  const { t } = useTranslation();

  const isTimeUp = timeElapsed >= timeAllowed;

  return (
    <View style={styles.container}>
      <Text size={28} style={styles.title}>
        {isTimeUp
          ? t('app.page.tableOrders.countDown.titleTimesUp')
          : t('app.page.tableOrders.countDown.title', { minutes: timeElapsed })}
      </Text>
      <Text>{t('app.page.tableOrders.countDown.subtitle', { minutes: timeAllowed })}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { alignItems: 'center', marginTop: 16 },
  title: { color: colors.primary.dark, fontWeight: 'bold', marginBottom: 4 },
});

export default CountdownInfo;
