/** @param {IRootState} state */
export const selectCategories = (state) => {
  return state.menu.categories;
};

/** @param {string} categoryId */
export const selectMenus = (categoryId) => {
  /** @param {IRootState} state */
  return (state) => {
    return state.menu.menus.filter(m => m.categoryId === categoryId);
  };
};

/** @param {IRootState} state */
export const selectCategoryWithMenus = (state) => {
  return {
    menus: state.menu.menus,
    categories: state.menu.categories,
  };
};

/** @param {IRootState} state */
export const selectSetWithMenus = (state) => {
  return {
    sets: state.menu.sets,
  };
};

/**
 *
 * @param {IRootState} state
 */
export const selectTranslations = (state) => {
  const translations = {};

  [
    ...state.menu.menus,
    ...state.menu.categories,
    ...state.menu.sets,
  ].forEach(item => {
    translations[item.id] = item.translations.reduce((trAcc, tr) => {
      return {
        ...trAcc,
        [tr.locale]: tr,
      };
    }, {});
  });

  return translations;
};

/**
 *
 * @param {IRootState} state
 */
export const selectMenuNameByLocale = (state, item) => {
  if (!item) {
    return '';
  }

  const key = item.menuId || item.id;
  const menuLocale = state.setting.systemSettings.menuLang;

  if (item.translations) {
    return item.translations.find(tr => tr.locale === menuLocale)?.name ??
      item.name;
  }

  const menuTranslations = selectTranslations(state)[key];

  return menuTranslations?.[menuLocale]?.name ?? item.name;
};
