import OrderAppLib from 'dimorder-orderapp-lib';
import _ from 'lodash';
import produce from 'immer';

import { validateItem, validateSet } from '@/libs/order';

import ActionTypes from './ActionTypes';
import ActionTypesAuth from '../auth/ActionTypes';

const dimorderLib = OrderAppLib.libs;

/** @type {IOrderState} */
export const initialState = {
  workingOrder: null,
  workingBatch: null,
  workingItem: null,
  workingSetItem: null,
  invalidItemIdMap: {},

  selectionStack: [],
  stash: null,

  cartExpanded: false,
  cartInDeleteMode: false,

  // navigation related
  editFromPath: '',
};

/** @type {IAppOrder} */
export const initialOrder = {
  id: null,
  sync: false,
  apiVersion: '2.0.0',
  serial: '',
  orderSerial: '',
  table: null,
  customers: 1,
  adults: 1,
  children: 0,
  remarks: [],
  customItems: [],
  discount: { percent: 0, amount: 0, overrideItem: false, code: '', offeredBy: null },
  surcharge: { percent: 0, amount: 0 },
  originalTotal: 0,
  discountTotal: 0,
  orderDiscount: 0,
  surchargeTotal: 0,
  total: 0,
  originalShippingFee: 0,
  discountShippingFee: 0,
  paymentTips: null,
  paidAmount: 0,
  extraPayments: [],
  payments: [],
  paying: false,
  status: 'pending',
  paidAt: '',
  cancelReason: '',
  cancelIdentifier: '',
  createdAt: '',
  updatedAt: '',
  cancelledAt: null,
  version: 0,
  takeaway: false,
  delivery: false,
  deliveryStatus: '',
  pickuppOrderNumber: '',
  batches: [],
  deliveryType: 'takeaway',
  shipping: {
    areas: null,
    baseShippingFee: 0,
    cost: 0,
    customerAmount: 0,
    discounts: null,
    enabled: false,
    freightCollect: false,
    isSystem: false,
    key: '',
    lalamove: null,
    longDistanceFee: 0,
    maxDeliveryMinute: 0,
    minAmount: 0,
    minDeliveryMinute: 0,
    name: '',
    smallOrderFee: 0,
    stops: null,
    tunnelFee: 0,
    type: '',
  },
  shippingFee: 0,
  shippingProvider: '',
  needTableware: false,
  creditSales: false,
  remark: '',
  modifiers: [],
  promocode: '',
  tags: [],
  mainOrderId: '',
  subOrderIds: [],
  paidByCustomer: false,
  subTable: 0,
  from: 'MERCHANT',
  roundedTotal: 0,
  subtotal: 0,
  originalSubtotal: 0,
  refunded: false,
  calculatedDiscountTotal: 0,
  ricecoin: 0,
  roundedRevenue: 0,
  summary: [],
  roundedAmount: 0,
  merchantOrderDiscount: 0,
  serviced: false,
  displayStatusKey: 'waiting_order',
};

/** @type {IAppOrderBatch} */
export const initialBatch = {
  orderId: null,
  orderSerial: null,
  batchId: null,
  index: 0,
  table: null,
  items: [],
  remark: '',
  takeout: false, // 外賣自取
  delivery: false, // 是否, pickupp 外送
  storeDelivery: false, // 是否外送訂單
  shop: false, // 是否網店訂單
  takeaway: false, // 是否外賣自取訂單
  status: 'submitted',
  createdAt: '',
  updatedAt: '',
  takeawayStatus: null,
  from: 'MERCHANT',
  identifier: '',
  creatorId: '',
  isWaiter: true,
};

export default produce(
  /**
   * @param {IOrderState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action.type) {
      case ActionTypesAuth.USER_LOGOUT: {
        return initialState;
      }

      case ActionTypes.UPDATE_ITEM_QUANTITY: {
        draft.workingItem.quantity = action.payload;
        draft.workingItem = dimorderLib.calculateLocalBatchItem(draft.workingItem);
        break;
      }

      case ActionTypes.UPDATE_SET_ITEM_QUANTITY: {
        draft.workingSetItem.quantity = action.payload;
        draft.workingSetItem = dimorderLib.calculateLocalBatchItem(draft.workingSetItem);
        break;
      }

      case ActionTypes.ADD_ITEM_OPTIONS: {
        draft.workingItem.options.push(action.payload);
        draft.workingItem = dimorderLib.calculateLocalBatchItem(draft.workingItem);
        break;
      }

      case ActionTypes.DELETE_ITEM_OPTIONS: {
        const option = action.payload;
        const selectedOptionIndex = draft.workingItem.options.findIndex(o => o.optionItemId === option.optionItemId);

        if (selectedOptionIndex >= 0) {
          draft.workingItem.options.splice(selectedOptionIndex, 1);
        }

        draft.workingItem = dimorderLib.calculateLocalBatchItem(draft.workingItem);

        break;
      }

      case ActionTypes.UPDATE_ITEM_OPTIONS: {
        const option = action.payload;
        const selectedOptionIndex = draft.workingItem.options.findIndex(o => o.optionItemId === option.optionItemId);

        if (selectedOptionIndex >= 0) {
          draft.workingItem.options[selectedOptionIndex] = option;
        } else {
          draft.workingItem.options.push(option);
        }

        draft.workingItem = dimorderLib.calculateLocalBatchItem(draft.workingItem);

        break;
      }

      case ActionTypes.REPLACE_ITEM_OPTIONS: {
        const option = action.payload;
        const selectedOptionIndex = draft.workingItem.options.findIndex(o => o.optionGroupId === option.optionGroupId);

        if (selectedOptionIndex >= 0) {
          draft.workingItem.options[selectedOptionIndex] = option;
        } else {
          draft.workingItem.options.push(option);
        }

        draft.workingItem = dimorderLib.calculateLocalBatchItem(draft.workingItem);

        break;
      }

      case ActionTypes.RESET_ITEM_OPTION_GROUP: {
        const optionGroup = action.payload;
        const isSetItem = Boolean(draft.workingSetItem);

        if (isSetItem) {
          draft.workingSetItem.options = draft.workingSetItem.options
            .filter((o) => o.optionGroupId !== optionGroup.id);
          draft.workingSetItem = dimorderLib.calculateLocalBatchItem(draft.workingSetItem);
        } else {
          draft.workingItem.options = draft.workingItem.options
            .filter((o) => o.optionGroupId !== optionGroup.id);
          draft.workingItem = dimorderLib.calculateLocalBatchItem(draft.workingItem);
        }

        break;
      }

      case ActionTypes.ADD_SET_ITEM_OPTIONS: {
        draft.workingSetItem.options.push(action.payload);
        draft.workingSetItem = dimorderLib.calculateLocalBatchItem(draft.workingSetItem);
        break;
      }

      case ActionTypes.DELETE_SET_ITEM_OPTIONS: {
        const option = action.payload;
        const selectedOptionIndex = draft.workingSetItem.options.findIndex(o => o.optionItemId === option.optionItemId);

        if (selectedOptionIndex >= 0) {
          draft.workingSetItem.options.splice(selectedOptionIndex, 1);
        }

        draft.workingSetItem = dimorderLib.calculateLocalBatchItem(draft.workingSetItem);

        break;
      }

      case ActionTypes.UPDATE_SET_ITEM_OPTIONS: {
        const option = action.payload;
        const selectedOptionIndex = draft.workingSetItem.options.findIndex(o => o.optionItemId === option.optionItemId);

        if (selectedOptionIndex >= 0) {
          draft.workingSetItem.options[selectedOptionIndex] = option;
        } else {
          draft.workingSetItem.options.push(option);
        }

        draft.workingSetItem = dimorderLib.calculateLocalBatchItem(draft.workingSetItem);

        break;
      }

      case ActionTypes.REPLACE_SET_ITEM_OPTIONS: {
        const option = action.payload;
        const selectedOptionIndex = draft.workingSetItem.options.findIndex(o => o.optionGroupId === option.optionGroupId);

        if (selectedOptionIndex >= 0) {
          draft.workingSetItem.options[selectedOptionIndex] = option;
        } else {
          draft.workingSetItem.options.push(option);
        }

        draft.workingSetItem = dimorderLib.calculateLocalBatchItem(draft.workingSetItem);

        break;
      }

      case ActionTypes.RESET_SET_ITEM_OPTION_GROUP: {
        const optionGroup = action.payload;
        draft.workingSetItem.options = draft.workingSetItem.options.filter((o) => o.optionGroupId !== optionGroup.id);
        draft.workingSetItem = dimorderLib.calculateLocalBatchItem(draft.workingSetItem);
        break;
      }

      case ActionTypes.ADD_ITEM_TAG: {
        draft.workingItem.tags.push(action.payload);
        break;
      }

      case ActionTypes.DELETE_ITEM_TAG: {
        const tag = action.payload;
        const selectedTagIndex = draft.workingItem.tags.findIndex(o => o.id === tag.id);

        if (selectedTagIndex >= 0) {
          draft.workingItem.tags.splice(selectedTagIndex, 1);
        }
        break;
      }

      case ActionTypes.ADD_SET_ITEM_TAG: {
        draft.workingSetItem.tags.push(action.payload);
        break;
      }

      case ActionTypes.DELETE_SET_ITEM_TAG: {
        const tag = action.payload;
        const selectedTagIndex = draft.workingSetItem.tags.findIndex(o => o.id === tag.id);

        if (selectedTagIndex >= 0) {
          draft.workingSetItem.tags.splice(selectedTagIndex, 1);
        }
        break;
      }

      case ActionTypes.UPDATE_WORKING_ORDER: {
        draft.workingOrder = action.payload;
        break;
      }

      case ActionTypes.UPDATE_WORKING_BATCH: {
        draft.workingBatch = action.payload;
        break;
      }

      case ActionTypes.UPDATE_WORKING_ITEM: {
        draft.workingItem = action.payload;

        if (draft.workingItem !== null) {
          draft.workingItem = dimorderLib.calculateLocalBatchItem(draft.workingItem);
        }

        break;
      }

      case ActionTypes.UPDATE_WORKING_SET_ITEM: {
        draft.workingSetItem = action.payload;

        if (draft.workingSetItem !== null) {
          draft.workingSetItem = dimorderLib.calculateLocalBatchItem(draft.workingSetItem);
        }

        break;
      }

      case ActionTypes.ADD_ITEM_TO_BATCH: {
        const reconciled = draft.workingBatch.items;
        const item = dimorderLib.calculateLocalBatchItem(action.payload.item);
        const idx = reconciled.findIndex(i => i.id === item.id);

        if (idx > -1) {
          reconciled[idx] = item;
        } else {
          reconciled.push(item);
        }

        const errors = action.payload.item.isSet
          ? validateSet(action.payload.menu, item)
          : validateItem(action.payload.menu, item);

        if (errors.length) {
          draft.invalidItemIdMap[action.payload.item.id] = [];
          errors.forEach(({ id }) => {
            draft.invalidItemIdMap[id] = [];
          });
          errors.forEach(({ id, message }) => {
            draft.invalidItemIdMap[id].push(message);
          });
        } else {
          delete draft.invalidItemIdMap[action.payload.item.id];
        }

        draft.workingItem = null;

        break;
      }

      case ActionTypes.RESET_INVALID_ITEMS: {
        draft.invalidItemIdMap = {};
        break;
      }

      case ActionTypes.ADD_SET_ITEM_TO_ITEM: {
        const setItemIdx = draft.workingItem.setItems
          .findIndex(i => i.key === action.payload.item.key);

        if (setItemIdx > -1) {
          draft.workingItem.setItems[setItemIdx] = action.payload.item;
        } else {
          draft.workingItem.setItems.push(action.payload.item);
        }

        draft.workingItem = dimorderLib.calculateLocalBatchItem(draft.workingItem);
        draft.workingSetItem = null;

        if (!draft.workingBatch.items.find(item => item.key === draft.workingItem.key)) {
          draft.workingBatch.items.push(draft.workingItem);
        } else {
          draft.workingBatch.items = draft.workingBatch.items.map(item => {
            if (item.key === draft.workingItem.key) {
              return draft.workingItem;
            }

            return item;
          });
        }

        const errors = validateSet(action.payload.menu, draft.workingItem);

        if (errors.length) {
          draft.invalidItemIdMap[draft.workingItem.id] = [];
          draft.workingItem.setItems.forEach(setItem => {
            draft.invalidItemIdMap[setItem.id] = [];
          });

          errors.forEach(({ id, message }) => {
            draft.invalidItemIdMap[id].push(message);
          });
        } else {
          delete draft.invalidItemIdMap[draft.workingItem.id];
          draft.workingItem.setItems.forEach(setItem => {
            delete draft.invalidItemIdMap[setItem.id];
          });
        }

        break;
      }

      case ActionTypes.ADD_OPTION_TO_ITEM: {
        break;
      }

      case ActionTypes.DELETE_ITEM_FROM_BATCH: {
        const batchItem = action.payload;
        draft.workingBatch.items = draft.workingBatch.items.filter((item) => {
          return item.key !== batchItem.key;
        });

        if (draft.workingItem && draft.workingItem.key === batchItem.key && !draft.workingItem.isSet) {
          draft.workingItem = null;
        }

        delete draft.invalidItemIdMap[batchItem.key];

        if (batchItem.setItems.length) {
          batchItem.setItems.forEach(setItem => {
            delete draft.invalidItemIdMap[setItem.key];
          });
        }

        // auto exit delete mode if there are no more working items in batch
        if (!draft.workingBatch.items.length) {
          draft.cartInDeleteMode = false;
        }

        break;
      }

      case ActionTypes.DELETE_SET_ITEM_FROM_BATCH: {
        const { setKey, setItemKey, menu } = action.payload;

        // delete setItem from batch
        const targetSetIndexInBatch = draft.workingBatch.items.findIndex(o => o.key === setKey);

        if (targetSetIndexInBatch >= 0) {
          const targetSetItemIndex = draft.workingBatch.items[targetSetIndexInBatch].setItems.findIndex(o => o.key === setItemKey);

          draft.workingBatch.items[targetSetIndexInBatch].setItems.splice(targetSetItemIndex, 1);

          // delete whole set from batch if there is no set item
          if (!draft.workingBatch.items[targetSetIndexInBatch].setItems.length) {
            draft.workingBatch.items.splice(targetSetIndexInBatch, 1);

            delete draft.invalidItemIdMap[setKey];
          }
        }

        delete draft.invalidItemIdMap[setItemKey];

        // delete setItem from workingSet
        if (draft.workingItem) {
          const targetSetItemIndex = draft.workingItem.setItems.findIndex(o => o.key === setItemKey);

          if (targetSetItemIndex >= 0) {
            draft.workingItem.setItems.splice(targetSetItemIndex, 1);
          }
        }

        // auto exit delete mode if there are no more working items in batch
        if (!draft.workingBatch.items.length) {
          draft.cartInDeleteMode = false;
        }

        const batchItem = draft.workingBatch.items.find(item => {
          return item.id === setKey;
        });

        if (!batchItem) {
          break;
        }

        const errors = validateSet(menu, batchItem);

        if (errors.length) {
          draft.invalidItemIdMap[batchItem.id] = [];
          batchItem.setItems.forEach(setItem => {
            draft.invalidItemIdMap[setItem.id] = [];
          });

          const uniqueIds = _.uniqBy(errors, 'id');

          // not having set containers only
          if (uniqueIds.length !== 1 || uniqueIds[0].id !== batchItem.id) {
            errors.forEach(({ id, message }) => {
              draft.invalidItemIdMap[id].push(message);
            });
          }
        } else {
          delete draft.invalidItemIdMap[batchItem.id];
          batchItem.setItems.forEach(setItem => {
            delete draft.invalidItemIdMap[setItem.id];
          });
        }

        break;
      }

      case ActionTypes.CONFIRM_ORDER: {
        draft.workingOrder.batches.push(draft.workingBatch);
        break;
      }

      case ActionTypes.DISCARD_ORDER: {
        draft.workingOrder = null;
        draft.workingBatch = null;
        draft.workingItem = null;
        draft.workingSetItem = null;
        break;
      }

      case ActionTypes.SET_CART_EXPANDED: {
        draft.cartExpanded = action.payload;
        break;
      }

      case ActionTypes.SET_CART_DELETE_MODE: {
        draft.cartInDeleteMode = action.payload;
        break;
      }

      case ActionTypes.ADD_SELECTION: {
        draft.selectionStack.push(action.payload);
        break;
      }

      case ActionTypes.REMOVE_SELECTION: {
        for (let i = 0; i < action.payload; i++) {
          draft.selectionStack.pop();
        }

        break;
      }

      case ActionTypes.CLEAR_SELECTION: {
        draft.selectionStack = [];
        break;
      }

      case ActionTypes.STASH: {
        draft.stash = {
          item: draft.workingItem,
          setItem: draft.workingSetItem,
          selectionStack: draft.selectionStack,
        };
        draft.selectionStack = [];
        break;
      }

      case ActionTypes.RECONCILE_STASH: {
        const workingItemSetItems = draft.workingItem?.setItems;

        draft.workingItem = draft.stash.item;

        if (workingItemSetItems && draft.workingItem) {
          draft.workingItem.setItems = workingItemSetItems;
        }

        draft.workingSetItem = draft.stash.setItem;
        draft.selectionStack = draft.stash.selectionStack;
        draft.stash = null;
        break;
      }

      case ActionTypes.UPDATE_WORKING_ITEM_TAGS: {
        draft.workingItem.tags = action.payload;
        break;
      }

      case ActionTypes.UPDATE_SET_ITEM_UNDETERMINED_PRICE: {
        const price = Number(action.payload);

        if (!Number.isNaN(price)) {
          draft.workingSetItem.priceUndetermined = false;
          draft.workingSetItem.price = price;
        }

        break;
      }

      case ActionTypes.UPDATE_ITEM_UNDETERMINED_PRICE: {
        const price = Number(action.payload);

        if (!Number.isNaN(price)) {
          draft.workingItem.priceUndetermined = false;
          draft.workingItem.price = price;
        }

        break;
      }

      case ActionTypes.ENTER_EDIT_MODE: {
        draft.editFromPath = action.payload;
        break;
      }

      case ActionTypes.EXIT_EDIT_MODE: {
        draft.editFromPath = '';
        break;
      }

      case ActionTypes.UPDATE_ITEM_REMARKS: {
        // ignore empty string or spaces
        if (!action.payload.trim()) {
          break;
        }

        if (draft.workingSetItem) {
          draft.workingSetItem.remarks = [action.payload];
        } else if (draft.workingItem) {
          draft.workingItem.remarks = [action.payload];
        }

        break;
      }

      case ActionTypes.ORDER_CANCELLED: {
        return {
          ...initialState,
          selectionStack: draft.selectionStack.slice(0, 1),
        };
      }

      case ActionTypes.RESET: {
        return initialState;
      }

      default: {
        return draft;
      }
    }
  },
  initialState,
);
