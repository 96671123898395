import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { actions, useDispatch, useSelector } from '@/redux';
import Button from '@/components/Button';
import CenterModal from '@/components/Modals/CenterModal';
import colors from '@/theme/colors';

import { dialogNames } from './constants';
import Text from '../Text';

const CodePushProgressDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const progress = useSelector(state => state.codePush.progress);
  const status = useSelector(state => state.codePush.status);
  const isOpen = useSelector(state => state.app.dialogs.codePushProgress.open);

  const getPercentage = () => {
    if (!progress) {
      return '';
    }

    const percentage = Math.round(progress.receivedBytes / progress.totalBytes * 1000) / 10;

    return `${percentage} %`;
  };

  const handleClose = () => {
    dispatch(actions.app.toggleDialog(dialogNames.codePushProgress, false));
  };

  const renderContent = () => {
    if (status === 0) {
      return (
        <View style={{ alignItems: 'center' }}>
          <Text>{t('app.dialogs.codePushProgress.noNewUpdate')}</Text>
          <Button
            touchableProps={{
              style: {
                marginTop: 16,
                width: 120,
                backgroundColor: colors.button.main,
                borderRadius: 6,
              },
            }}
            title={t('app.dialogs.codePushProgress.confirm')}
            onPress={handleClose}
          />
        </View>
      );
    }

    if (progress) {
      return <Text>{getPercentage()}</Text>;
    }

    return <Text>{t('app.dialogs.codePushProgress.checkingForUpdate')}</Text>;
  };

  return (
    <CenterModal
      visible={isOpen}
      title={t('app.dialogs.codePushProgress.title')}
      noCloseButton
      containerStyle={{ width: '80%' }}
      contentContainerStyle={styles.container}
    >
      {renderContent()}
    </CenterModal>
  );
};

const styles = StyleSheet.create({
  container: {
    minWidth: '80%',
    maxWidth: 450,
  },
  buttonContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  button: {
    margin: 12,
    width: '100%',
    paddingVertical: 4,
    alignItems: 'center',
  },
  input: {
    borderWidth: 1,
    padding: 12,
    margin: 20,
    width: '100%',
  },
});

export default CodePushProgressDialog;
