import { TextInput } from 'react-native';
import { useTranslation } from 'react-i18next';
import React from 'react';

import ActionBar from '@/components/ActionBar';
import colors from '@/theme/colors';

const PriceUndeterminedBar = ({ isPriceUndetermined, price, onChangePrice }) => {
  const { t } = useTranslation();

  if (!isPriceUndetermined) {
    return null;
  }

  return (
    <ActionBar
      style={{ marginBottom: 16 }}
      title={`${t('app.page.order.options.price')}`}
    >
      <TextInput
        style={{
          backgroundColor: colors.secondary.main,
          borderRadius: 5,
          paddingVertical: 5,
          paddingHorizontal: 16,
          textAlign: 'center',
        }}
        placeholder={t('app.page.order.options.priceUndetermined')}
        keyboardType='numeric'
        value={price}
        autoFocus
        onChangeText={onChangePrice}
      />
    </ActionBar>
  );
};

export default PriceUndeterminedBar;
