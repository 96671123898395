import { FlatList, View } from 'react-native';
import React from 'react';

import { useSelector } from '@/redux';

import Batch from '../Batch';
import Header from './Header';

const AllBatches = () => {
  const { workingBatch, workingOrder } = useSelector(state => state.order);
  const orderBatches = workingOrder.batches;
  const allBatches = [...orderBatches, workingBatch].reverse();

  return (
    <FlatList
      data={allBatches}
      bounces={false}
      showsVerticalScrollIndicator={false}
      renderItem={({ item: batch, index }) => {
        const isCurrent = batch.id === workingBatch.id;

        return (
          <View key={batch.id}>
            <Header
              batchNumber={allBatches.length - index}
              createdAt={batch.createdAt}
              isCurrentBatch={isCurrent}
            />
            <Batch
              editable={isCurrent}
              batchItems={batch.items}
              isItemInCurrentBatch={isCurrent}
            />
          </View>
        );
      }}
    />
  );
};

export default AllBatches;
