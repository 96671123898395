export function numberWithCommas(
  num,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
) {
  if (num === undefined || !num) {
    num = 0;
  }

  return Number(num).toLocaleString('en-US', {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  });
}
