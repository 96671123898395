
import React from 'react';

import AccountPermissionDialog from './AccountPermissionDialog';
import CodePushChannelDialog from './CodePushChannelDialog';
import CodePushProgressDialog from './CodePushProgressDialog';
import CustomerCountDialog from './CustomerCountDialog';
import EnvDialog from './EnvDialog';
import ErrorDialog from './ErrorDialog';
import SystemAdminDialog from './SystemAdminDialog'; ;

const Dialogs = () => {
  return (
    <>
      <ErrorDialog />
      <SystemAdminDialog />
      <EnvDialog />
      <CustomerCountDialog />
      <CodePushChannelDialog />
      <AccountPermissionDialog />
      <CodePushProgressDialog />
    </>
  );
};

export default Dialogs;
