import _ from 'lodash';

import { dimorderApi, dimorderLib } from '@/libs/api/dimorder';
import logger from '@/libs/logger';

class WaiterApi {
  scopeLoginWithMerchantAccount = (account, password, merchantUserName) => {
    logger.log('[waiterAPI scopeLoginWithMerchantAccount]', { account, password, merchantUserName });
    return dimorderApi.auth.scopeLoginWithMerchantAccount(account, password, merchantUserName);
  };

  setToken = (accessToken, refreshToken) => {
    logger.log('[waiterAPI setToken]', { accessToken, refreshToken });
    dimorderApi.setToken(accessToken, refreshToken);
  };

  getUserInfo = () => {
    logger.log('[waiterAPI getUserInfo]');
    return dimorderApi.auth.getUserInfo();
  };

  getAllMenus = async () => {
    logger.log('[waiterAPI getAllMenus]');

    const cleanseMenus = menus => {
      return menus.map(menu => {
        if (!menu.options) {
          return {
            ...menu,
            options: [],
          };
        }

        return {
          ...menu,
          options: menu.options.map(option => {
            return {
              ...option,
              options: option.options ?? [],
            };
          }),
        };
      });
    };

    const fillTranslations = (data) => {
      return data.map(item => {
        if (!item.translations) {
          return {
            ...item,
            translations: [],
          };
        };

        return item;
      });
    };

    // TODO: can skip cleansing if backend data format is fixed
    const { data } = await dimorderApi.axiosGo.get('/m/menus/listall');

    // menus[].options[].options
    // sets[].setMenus[].options[].options

    return {
      ...data,
      menus: fillTranslations(cleanseMenus(data.menus)),
      categories: fillTranslations(data.categories),
      sets: fillTranslations(data.sets).map(set => {
        return {
          ...set,
          setMenus: cleanseMenus(set.setMenus),
        };
      }),
    };
  };

  getMerchant = () => {
    logger.log('[waiterAPI getMerchant]');
    return dimorderApi.merchant.getMerchant();
  };

  addTable = async (tableOrder) => {
    logger.log('[waiterAPI createOrder]', { tableOrder });
    return dimorderApi.order.createOrder(tableOrder);
  };

  updateCustomerCount = async (orderId, adults, children) => {
    logger.log('[waiterAPI updateCustomerCount]', { orderId, adults, children });
    return dimorderApi.order.updateCustomerCount(orderId, adults, children);
  };

  getCustomReason = async () => {
    logger.log('[waiterAPI getCustomReason]');
    return dimorderApi.customReason.getCustomReason();
  };

  transferOrder = async (order, targetTableKey) => {
    logger.log('[waiterAPI transferOrder]', { order, targetTableKey });
    return await dimorderApi.order.updateTable(order.id, order.table, targetTableKey);
  };

  /**
   *
   * @param {IAppOrder} appOrder
   * @param {boolean} useSubmitAPI - for backward compatibility, true for using /submit, false for using /orderimport
   */
  syncOrder = async (appOrder, useSubmitAPI) => {
    logger.log('[waiterAPI syncOrder]', { appOrder, useSubmitAPI });

    if (!useSubmitAPI) {
      return dimorderApi.order.syncOrder([
        dimorderLib.appOrderToapiOrder(appOrder, useSubmitAPI),
      ]);
    }

    return dimorderApi.order.submitBatch(
      appOrderToAppBatch(appOrder, useSubmitAPI),
    );
  };
}

function appOrderToAppBatch(appOrder, useSubmitAPI) {
  const batch = _.last(appOrder.batches);

  const items = [];
  batch.items.forEach(item => {
    const setItems = [];

    item.setItems.forEach(setItem => {
      for (let i = 0; i < setItem.quantity; i++) {
        if (useSubmitAPI) {
          setItems.push({
            ...setItem,
            quantity: 1,
          });
        } else {
          setItems.push(setItem);
        }
      }
    });

    for (let i = 0; i < item.quantity; i++) {
      if (useSubmitAPI) {
        items.push({
          ...item,
          setItems,
          quantity: 1,
        });
      } else {
        items.push({
          ...item,
          setItems,
        });
      }
    }
  });

  const orderBatch = {
    ...batch,
    items,
  };

  return {
    orderId: appOrder.id,
    orderBatch: orderBatch,
    deliveryType: appOrder.deliveryType,
    shipping: appOrder.shipping,
    takeawayInfo: appOrder.takeawayInfo,
    ignoreUnavailable: appOrder.ignoreUnavailable,
    preSubmit: appOrder.preSubmit,
    needTableware: appOrder.needTableware,
    from: 'MERCHANT',
  };
}

export default new WaiterApi();
