import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { useState } from 'react';

import shadows from '@/theme/shadows';

import TableGroupList from './TableGroupList';
import Text from '../Text';

const styles = StyleSheet.create({
  container: {
    height: 46,
    width: '100%',
  },
  headerContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    fontWeight: 'bold',
  },
  dropdownIcon: {
    height: 14,
    width: 14,
    margin: 5,
  },
  menuButton: {
    height: 50,
    width: 150,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  tooltipContainer: {
    borderRadius: 10,
    ...shadows.slight,
  },
});

export default function TableGroupSelector (props) {
  const [selectedTableGroup, setSelectedTableGroup] = useState();
  const [visible, setVisible] = useState(false);

  return (
    <>
      <TableGroupList visible={visible} onClose={() => setVisible(false)} setSelectedTableGroup={setSelectedTableGroup} />
      <TouchableOpacity style={styles.container} onPress={() => setVisible(true)}>
        <View style={styles.headerContainer}>
          <Text size={24} style={styles.header}>{selectedTableGroup}</Text>
          <Image style={styles.dropdownIcon} source={require('@icons/common/downward-arrow-solid.png')} />
        </View>
      </TouchableOpacity>
    </>
  );
}
